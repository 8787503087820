import { animated, useSpring } from 'react-spring';
import React from 'react';

export const ProgressBar = ({
  value,
  name,
  dataAmount,
}: {
  value: number;
  name: string;
  dataAmount: string;
}) => {
  // Calculate the percentage width based on the value and maxWidth
  const percentageWidth = (value / 100) * 100;

  const { width } = useSpring({
    width: `${percentageWidth}%`,
    from: { width: '0%' },
  });

  return (
    <div className="bg-white bg-opacity-25 rounded-xl relative">
      <div className="flex">
        <animated.div style={{ width }}>
          <div>
            <span className="rounded-xl py-2 text-secondary text-sm font-medium flex justify-between items-center">
              <span>
                {name}
                <span className="ms-2 rounded-xl px-2 text-black text-sm font-medium bg-[#f1f1f1]">
                  {dataAmount}
                </span>
              </span>
              <span className="ms-2">{value !== null || value < 0 ? value : 0}%</span>
            </span>
          </div>

          <div
            className="bg-primary text-white text-start px-3 py-1.5 leading-none rounded-xl whitespace-nowrap"
            style={{
              background: 'linear-gradient(to right, #FFD6DB 0%, #FF748C 50%, #FF748C 100%)',
            }}
          ></div>
        </animated.div>
      </div>
    </div>
  );
};
