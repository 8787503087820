//TODO: Need to be refactored
import { Menu } from '@headlessui/react';
import { Icon } from '@iconify/react';
import { useMsal } from '@azure/msal-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useGetAuthUser } from '../../hooks';
import { ADMIN, DISTRIBUTOR, RETAILER } from '../../constants';
import { getPreviousUrl } from '../../utils/navigationTracker';
import { Button } from '../../components';
import { IconArrowLeft } from '../../assets/icons';
import { useMedia } from 'react-use';
import { CartCountIcon, LocationSelect } from '../../components/shared';
import { useCart } from '../cart/useCart';

type HeaderProps = {
  title?: string;
  isBack?: boolean;
  isCartDisabled?: boolean;
  navigatePath?: string;
};

function Header({ title, isBack, isCartDisabled = false, navigatePath }: HeaderProps) {
  const { instance } = useMsal();
  const { user } = useGetAuthUser();
  const location = useLocation();
  const navigate = useNavigate();
  const isTablet = useMedia('(max-width:1024px)');

  const { selectedLocation, updateSelectedLocation, locations, total } = useCart();

  const handleLogout = () => {
    const userId = user?.userId; // Assuming you have a `user` object containing the userId

    // Retrieve current state values
    const displaySearchValue = localStorage.getItem('displaySearchValue');
    const quantityValue = localStorage.getItem('quantityValue');
    const updatedValue = localStorage.getItem('updatedValue');

    // Save these values with the userId as key
    if (userId) {
      if (displaySearchValue !== null) {
        localStorage.setItem(`${userId}_displaySearchValue`, displaySearchValue);
      }
      if (quantityValue !== null) {
        localStorage.setItem(`${userId}_quantityValue`, quantityValue);
      }
      if (updatedValue !== null) {
        localStorage.setItem(`${userId}_updatedValue`, updatedValue);
      }
    }

    // Clear the temporary storage
    localStorage.removeItem('displaySearchValue');
    localStorage.removeItem('quantityValue');
    localStorage.removeItem('updatedValue');

    // Perform logout
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const handleClick = (url: string) => {
    localStorage.setItem('backURL', location.pathname); // Set your item in localStorage
    navigate(url); // Navigate to the desired path
  };

  return (
    <div
      className={`${
        user?.userType === RETAILER && title === 'Dashboard' ? 'flex-row gap-4' : ''
      } flex items-center justify-between mb-4 bg-white lg:px-[24px] px-[16px] py-[10px] rounded-2xl`}
    >
      {title ? (
        <Button
          variant="text"
          size="medium"
          className="whitespace-nowrap"
          icon={isBack && <IconArrowLeft />}
          onClick={() => {
            if (isBack) {
              const previousUrl = getPreviousUrl();
              navigate(navigatePath || previousUrl || '/');
            }
          }}
        >
          {isTablet ? '' : title}
        </Button>
      ) : (
        <div />
      )}
      <div className="flex gap-8 items-center justify-between md:mt-0">
        {user?.userType === RETAILER && (
          <div className="flex gap-8 items-center">
            <LocationSelect
              isDisabled={isCartDisabled}
              locations={locations}
              selectedLocation={selectedLocation}
              updateSelectedLocation={updateSelectedLocation}
            />
            <Button
              {...(location.pathname !== '/retailers/cart'
                ? { onClick: () => navigate('/retailers/cart') }
                : {})}
              icon={<CartCountIcon count={total?.bottlesCount ?? 0} />}
              variant="grey"
              size="small"
            >
              Cart
            </Button>
          </div>
        )}
        <Menu as="div" className="relative">
          <div>
            <Menu.Button className="relative flex text-primary rounded-lg justify-between items-center">
              <div className="profile flex items-center justify-between md:gap-2">
                <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#b20036] text-white font-bold">
                  {user?.userType !== ADMIN ? (
                    <>{user?.companyName?.charAt(0)?.toUpperCase()}</>
                  ) : (
                    <>{user?.emailId?.charAt(0)?.toUpperCase()}</>
                  )}
                </div>
                {user?.userType !== ADMIN && (
                  <div className="md:flex hidden">{user?.companyName}</div>
                )}
                <Icon icon="prime:chevron-down" className="text-primary" width="24" height="24" />
              </div>
            </Menu.Button>
          </div>
          <Menu.Items className="absolute right-0 z-10 mt-1.5 w-48 origin-top-right rounded-xl bg-white dropshadow-2xl backdrop-blur-md py-2.5 focus:outline-none">
            {user?.userType === ADMIN && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/myProfile"
                      onClick={() => handleClick('myProfile')}
                      className={
                        (active ? 'bg-white' : '') +
                        ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                      }
                    >
                      <Icon icon="gg:profile" className="text-primary" width="20" height="20" />
                      Profile
                    </Link>
                  )}
                </Menu.Item>
              </>
            )}

            {user?.userType === RETAILER && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      onClick={() => handleClick('myProfile')}
                      to={{
                        pathname: '/myProfile',
                      }}
                      className={
                        (active ? 'bg-white' : '') +
                        ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                      }
                    >
                      <Icon icon="gg:profile" className="text-primary" width="20" height="20" />{' '}
                      Profile
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/generatekey"
                      onClick={() => handleClick('generatekey')}
                      className={
                        (active ? 'bg-white' : '') +
                        ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                      }
                    >
                      <Icon
                        icon="basil:exchange-outline"
                        className="text-primary"
                        width="20"
                        height="20"
                      />{' '}
                      Exchange
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/markup"
                      className={
                        (active ? 'bg-white' : '') +
                        ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                      }
                      onClick={() => localStorage.setItem('loc', window.location.pathname)}
                    >
                      <Icon icon="gala:settings" className="text-primary" width="20" height="20" />{' '}
                      Markup
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/payment?tabId=bank"
                      className={
                        (active ? 'bg-white' : '') +
                        ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                      }
                      onClick={() => {
                        if (
                          window.location.pathname !== '/payment/bank' &&
                          window.location.pathname !== '/payment/card' &&
                          window.location.pathname !== '/payment/card-add' &&
                          window.location.pathname !== '/payment/bank-add'
                        ) {
                          localStorage.setItem('loc', window.location.pathname);
                          localStorage?.removeItem('paymentTab');
                        }
                        localStorage?.removeItem('paymentTab');
                      }}
                    >
                      <Icon
                        icon="ri:bank-card-fill"
                        className="text-primary"
                        width="20"
                        height="20"
                      />
                      Payment Method
                    </Link>
                  )}
                </Menu.Item>
              </>
            )}
            {user?.userType === DISTRIBUTOR && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/myProfile"
                      onClick={() => handleClick('myProfile')}
                      className={
                        (active ? 'bg-white' : '') +
                        ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                      }
                    >
                      <Icon icon="gg:profile" className="text-primary" width="20" height="20" />
                      Profile
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/payment?tabId=bank"
                      className={
                        (active ? 'bg-white' : '') +
                        ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                      }
                      onClick={() => {
                        if (
                          window.location.pathname !== '/payment/bank' &&
                          window.location.pathname !== '/payment/card' &&
                          window.location.pathname !== '/payment/card-add' &&
                          window.location.pathname !== '/payment/bank-add'
                        ) {
                          localStorage.setItem('loc', window.location.pathname);
                          localStorage?.removeItem('paymentTab');
                        }
                        localStorage?.removeItem('paymentTab');
                      }}
                    >
                      <Icon
                        icon="ri:bank-card-fill"
                        className="text-primary"
                        width="20"
                        height="20"
                      />
                      Payment Method
                    </Link>
                  )}
                </Menu.Item>
              </>
            )}

            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/change-password"
                  // onClick={() => handleClick('change-password')}
                  className={
                    (active ? 'bg-white' : '') +
                    ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                  }
                >
                  <Icon icon="uil:lock" className="text-primary" width="20" height="20" /> Change
                  Password
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="#"
                  onClick={handleLogout}
                  className={
                    (active ? 'bg-white' : '') +
                    ' flex gap-2 px-3 py-2 text-base text-secondary font-medium items-center'
                  }
                >
                  <Icon
                    icon="solar:logout-outline"
                    className="text-primary"
                    width="20"
                    height="20"
                  />
                  Log out
                </Link>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
}

export default Header;
