import { useRef, useState } from 'react';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import type { SelectOption } from './types';
import { Popover } from 'react-aria-components';
import { SelectDialogContent, SelectButtonContent } from './components';

type SelectProps = {
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  selected?: string;
  onChange: (selectedOption: SelectOption) => void;
  icon?: ReactNode;
  isDisabled?: boolean;
  variant?: 'default' | 'multi-select' | 'search';
};

export const Select = ({
  label,
  placeholder,
  options,
  selected,
  onChange,
  icon,
  isDisabled = false,
  variant = 'default',
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);
  const isDivided = !!options.find((option) => option.description);

  const handleOpen = () => {
    if (!isDisabled) {
      setIsOpen(true);
    }
  };

  const selectedOption = options.find((option) => option.value === selected);

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <span
          className={clsx('text-body-md font-headline', {
            'text-grey-4': isDisabled,
            'text-grey-1': !isDisabled,
          })}
        >
          {label}
        </span>
      )}
      <div
        role="button"
        className={clsx(
          'w-auto bg-white flex items-center outline-none transition-all duration-300 border py-2.5 px-3 rounded-xl h-10 md:!w-80',
          {
            'border-grey-5 hover:border-grey-4 cursor-pointer': !isOpen && !isDisabled,
            'border-grey-4 cursor-pointer': isOpen && !isDisabled,
            'text-grey-4 border-grey-6 !cursor-not-allowed': isDisabled,
          },
        )}
        onClick={handleOpen}
        ref={triggerRef}
      >
        <SelectButtonContent
          placeholder={placeholder}
          selectedOption={selectedOption}
          isOpen={isOpen}
          icon={icon}
          isDisabled={isDisabled}
          variant={variant}
        />
      </div>
      <Popover
        placement="bottom right"
        offset={8}
        triggerRef={triggerRef}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <SelectDialogContent
          options={options}
          selectedOption={selectedOption}
          handleSelect={(option) => {
            onChange(option);
            setIsOpen(false);
          }}
          isDivided={isDivided}
          variant={variant}
        />
      </Popover>
    </div>
  );
};
