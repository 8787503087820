import React, { useEffect, useState } from 'react';
import { DISTRIBUTOR, RETAILER } from '../constants';
import { LoaderComponent, SelectDashboard } from '../components';
import { useGetAuthUser } from '../hooks';
import moment from 'moment';
import { MutationExtraType, OptionType } from '../types';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import {
  GetDailyMoversRequestBody,
  GetDailyMoversRequestData,
} from '../redux/api/dashboardApi/types';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { ApiResponse } from '../redux/api/types';
import noImage from '../assets/images/no-image.webp';

type Props = {
  name: string;
  useMutation: UseMutation<
    MutationDefinition<
      GetDailyMoversRequestBody,
      (
        args: string | FetchArgs,
        api: BaseQueryApi,
        extraOptions: MutationExtraType,
      ) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
      never,
      ApiResponse<GetDailyMoversRequestData[]>
    >
  >;
  mutationExtra?: MutationExtraType;
  userType: string;
  price?: string;
};

const RetailerDailyMovers: React.FC<Props> = ({
  name,
  useMutation,
  mutationExtra,
  userType,
  price,
}) => {
  const [datas, setDatas] = useState<ApiResponse<GetDailyMoversRequestData[]>>();
  const [duration, setDuration] = useState<string>('MONTH');

  const { user } = useGetAuthUser();

  const [getData, { isLoading }] = useMutation();
  const date = new Date();
  const offset = moment.parseZone(date).utcOffset();
  useEffect(() => {
    const fetchData = async () => {
      const response = await getData({
        duration: duration,
        direction: price === 'increase' ? 'UP' : 'DOWN',
        offset: offset,
      });
      if (response?.data) {
        setDatas(response.data);
      }
    };
    fetchData();
  }, [mutationExtra, duration]);

  const handleSelectChange = (selectedOption: OptionType) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setDuration(value as string);
    }
  };

  return (
    <>
      <div className="xl:col-span-6 col-span-12 h-full">
        <div className="bg-white rounded-2xl p-4 text-secondary h-full">
          <div className="flex justify-between items-center mb-6">
            <p className="font-medium">{name}</p>
            {userType === RETAILER ? (
              <SelectDashboard
                duration={duration}
                onChange={handleSelectChange}
                name={'Daily Movers'}
              />
            ) : (
              ''
            )}
          </div>
          {isLoading ? (
            <>
              <LoaderComponent />
            </>
          ) : (
            <div>
              {datas?.data && datas?.data?.length > 0 ? (
                <>
                  <div>
                    {datas?.data?.map((data: GetDailyMoversRequestData) => (
                      <div className="sm:flex justify-between items-center mb-4" key={data?.name}>
                        <div className="flex gap-4 items-center">
                          <div className="w-12 h-12">
                            <img src={noImage} alt="whitewine" className="w-full h-full" />
                          </div>
                          <div>
                            <p className="font-medium text-sm">
                              {user?.userType === DISTRIBUTOR ? (
                                <>{data?.product_name}</>
                              ) : (
                                <>{data?.varietal}</>
                              )}
                            </p>
                            <span className="font-normal text-sm text-gray-300">
                              {user?.userType === DISTRIBUTOR ? (
                                <>{data?.varietal}</>
                              ) : (
                                <> {data?.description}</>
                              )}
                            </span>
                          </div>
                        </div>

                        <div className="text-right mt-4 sm:mt-0">
                          <span className="rounded p-2 bg-transparent text-sm text-slate-600 font-medium">
                            {user?.userType === DISTRIBUTOR ? (
                              <>
                                {data?.quantity} {data?.quantity > 1 ? 'Bottles' : 'Bottle'}
                              </>
                            ) : (
                              <>
                                {duration === 'TODAY'
                                  ? `${data?.priceDeltaDay}%`
                                  : duration === 'WEEK'
                                    ? `${data?.priceDeltaWeek}%`
                                    : duration === 'MONTH'
                                      ? `${data?.priceDeltaMonth}%`
                                      : ''}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm h-80">
                    <h3 className="text-lg font-semibold">No Data Found</h3>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RetailerDailyMovers;
