import type { IconProps } from './types';

export const IconChevronRight = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46967 6.46967C9.76256 6.17678 10.2374 6.17678 10.5303 6.46967L15.5303 11.4697C15.8232 11.7626 15.8232 12.2374 15.5303 12.5303L10.5303 17.5303C10.2374 17.8232 9.76256 17.8232 9.46967 17.5303C9.17678 17.2374 9.17678 16.7626 9.46967 16.4697L13.9393 12L9.46967 7.53033C9.17678 7.23744 9.17678 6.76256 9.46967 6.46967Z"
        fill="currentColor"
      />
    </svg>
  );
};
