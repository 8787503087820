import { IconProps } from './types';

export const IconSearch = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1271 9.07978C15.1421 6.70244 12.6148 5.35202 10.0909 5.85441C7.5671 6.3568 5.74961 8.57207 5.75 11.1454C5.75012 12.5759 6.31856 13.9478 7.33024 14.9591C9.15007 16.7786 12.0018 17.0592 14.1413 15.6292C16.2808 14.1993 17.1122 11.4571 16.1271 9.07978ZM9.7981 4.38328C13.0238 3.74118 16.2539 5.46712 17.5129 8.50558C18.7719 11.544 17.7092 15.0487 14.9748 16.8763C12.2403 18.7039 8.59563 18.3453 6.26973 16.0199C4.97669 14.7273 4.25017 12.974 4.25 11.1457M9.7981 4.38328C6.57243 5.02537 4.24953 7.85671 4.25 11.1457L9.7981 4.38328Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9587 14.9602C15.2516 14.6673 15.7264 14.6673 16.0193 14.9602L19.5303 18.4712C19.8232 18.7641 19.8232 19.239 19.5303 19.5319C19.2375 19.8248 18.7626 19.8248 18.4697 19.5319L14.9587 16.0209C14.6658 15.728 14.6658 15.2531 14.9587 14.9602Z"
        fill="currentColor"
      />
    </svg>
  );
};
