import type { MarkupStatus } from '../../../redux/api/markupProductApi/types';
import { showToastify } from '../../../utils/common';

type ToastAfterMarkupUpdateType = { status?: MarkupStatus; message?: string | undefined };

const warningMessage =
  'The mark-up percentage has been updated. To observe these changes on your Shopify, install the Vinobridge app and complete the activation process. The reflection will occur during the initial population of products.';
export const successMessage =
  'The markup percentage has been updated and it is been taken for processing. The changes will reflect shortly on the website as it takes some time to update.';
const infoMessage =
  'There is an ongoing markup update at the moment. Please wait until it completes to avoid the simultaneous execution of multiple threads and reduce processing time.';
const errorMessage = 'Something want wrong with markup update';

export const toastAfterMarkupUpdate = ({ status, message }: ToastAfterMarkupUpdateType): void => {
  if (status === 'NO_SHOP_CONNECTED' || message === warningMessage) {
    showToastify('warning', warningMessage);
    return;
  }
  if (status === 'MARKUP_UPDATE_STARTED' || message === successMessage) {
    showToastify('success', successMessage);
    return;
  }
  if (status === 'MARKUP_UPDATE_IN_PROGRESS' || message === infoMessage) {
    showToastify('info', infoMessage);
    return;
  }
  if (status === 'UNKNOWN_STATUS') {
    showToastify('error', errorMessage);
    return;
  }

  showToastify('info', message);
};
