import type { IconProps } from './types';

export const IconRecycleBin = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.618 4.25L13.382 4.25C14.0445 4.25035 14.6505 4.62483 14.9467 5.2174L15.4634 6.25H17C17.4142 6.25 17.75 6.58579 17.75 7C17.75 7.41421 17.4142 7.75 17 7.75H7C6.58579 7.75 6.25 7.41421 6.25 7C6.25 6.58579 6.58579 6.25 7 6.25H8.53662L9.0531 5.21775C9.34937 4.62517 9.95546 4.25035 10.618 4.25ZM10.2139 6.25H13.7861L13.6053 5.8886C13.563 5.80396 13.4764 5.75011 13.3817 5.75H10.6183C10.5236 5.75011 10.4372 5.80361 10.3949 5.88825L10.2139 6.25ZM6.25 9.833C6.25 8.95873 6.95873 8.25 7.833 8.25H16.167C17.0413 8.25 17.75 8.95873 17.75 9.833V16.5C17.75 17.362 17.4076 18.1886 16.7981 18.7981C16.1886 19.4076 15.362 19.75 14.5 19.75H9.5C7.70507 19.75 6.25 18.2949 6.25 16.5V9.833ZM7.833 9.75C7.78716 9.75 7.75 9.78716 7.75 9.833V16.5C7.75 17.4665 8.5335 18.25 9.5 18.25H14.5C14.9641 18.25 15.4092 18.0656 15.7374 17.7374C16.0656 17.4092 16.25 16.9641 16.25 16.5V9.833C16.25 9.78716 16.2128 9.75 16.167 9.75H7.833ZM10.333 11.583C10.7472 11.583 11.083 11.9188 11.083 12.333V15.666C11.083 16.0802 10.7472 16.416 10.333 16.416C9.91879 16.416 9.583 16.0802 9.583 15.666V12.333C9.583 11.9188 9.91879 11.583 10.333 11.583ZM13.667 11.583C14.0812 11.583 14.417 11.9188 14.417 12.333V15.666C14.417 16.0802 14.0812 16.416 13.667 16.416C13.2528 16.416 12.917 16.0802 12.917 15.666V12.333C12.917 11.9188 13.2528 11.583 13.667 11.583Z"
        fill="currentColor"
      />
    </svg>
  );
};
