export const ADMIN = 'ADMIN' as const;
export const DISTRIBUTOR = 'DISTRIBUTOR' as const;
export const RETAILER = 'RETAILER' as const;

export type UserType = typeof ADMIN | typeof DISTRIBUTOR | typeof RETAILER;

export const monthList = [
  { id: 1, fullName: 'January', shortName: 'Jan' },
  { id: 2, fullName: 'February', shortName: 'Feb' },
  { id: 3, fullName: 'March', shortName: 'Mar' },
  { id: 4, fullName: 'April', shortName: 'Apr' },
  { id: 5, fullName: 'May', shortName: 'May' },
  { id: 6, fullName: 'June', shortName: 'Jun' },
  { id: 7, fullName: 'July', shortName: 'Jul' },
  { id: 8, fullName: 'August', shortName: 'Aug' },
  { id: 9, fullName: 'September', shortName: 'Sep' },
  { id: 10, fullName: 'October', shortName: 'Oct' },
  { id: 11, fullName: 'November', shortName: 'Nov' },
  { id: 12, fullName: 'December', shortName: 'Dec' },
];

export const emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const buttonStyles: Record<string, string> = {
  INITIATED: 'bg-green-100 px-3 py-[4px] text-success rounded-xl font-semibold',
  'Order Received ON HOLD': 'bg-slate-50 px-3 py-[4px] text-yellow rounded-xl font-semibold',
  'Order sent to warehouse for picking':
    'bg-green-200 px-3 py-[4px] text-indigo-300 rounded-xl font-semibold',
  Cancelled: 'bg-red-100 px-3 py-[4px] text-danger rounded-xl font-semibold',
  Shipped: 'bg-indigo-100 px-3 py-[4px] text-indigo-900 rounded-xl font-semibold',
  'Order Received': 'bg-green-300 px-3 py-[4px] text-indigo-400 rounded-xl font-semibold',
};

export const BUY_PRODUCTS_FILTERS_STORAGE_KEY = 'BPF';
export const MARKUP_PRODUCTS_FILTERS_STORAGE_KEY = 'MPF';
export const BUY_PRODUCTS_CART_STORAGE_KEY = 'Cart';
export const SELECTED_CARD_LOCATION_STORAGE_KEY = 'SCL';
export const CART_FILL_USER_ID_STORAGE_KEY = 'CFUID';
