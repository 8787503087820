import type { IconProps } from './types';

export const IconChevronLeft = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5303 6.46967C15.8232 6.76256 15.8232 7.23744 15.5303 7.53033L11.0607 12L15.5303 16.4697C15.8232 16.7626 15.8232 17.2374 15.5303 17.5303C15.2374 17.8232 14.7626 17.8232 14.4697 17.5303L9.46967 12.5303C9.17678 12.2374 9.17678 11.7626 9.46967 11.4697L14.4697 6.46967C14.7626 6.17678 15.2374 6.17678 15.5303 6.46967Z"
        fill="currentColor"
      />
    </svg>
  );
};
