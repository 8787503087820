import * as Yup from 'yup';

export const cardAddValidationSchema = (value: string) =>
  Yup.object().shape({
    cardHolderName: value
      ? Yup.string().nullable()
      : Yup.string()
          .trim()
          .required('Cardholder name is required')
          .min(4, 'Minimum length is 4 characters')
          .max(24, 'Maximum length is 12 characters'),
    cardNumber: value ? Yup.string().nullable() : Yup.string().required('Card number is required'),
    cardExpiry: value
      ? Yup.string().nullable()
      : Yup.string().required('Card expiry month is required'),
    cardCvc: value ? Yup.string().nullable() : Yup.string().required('CVC is required'),
  });
