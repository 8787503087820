import { createContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { Product, ShopLocation } from '../../redux/api/retailerOrderListApi/types';
import { PriceBottlesTotal } from '../../types';

export type StoredProduct = { product: Product; selectedCount: number };

export type UpdateQuantityData = { product: Product; updatedQuantity: number };

export type CartStorage = Record<string, { data: StoredProduct[]; userId?: string }>;
export type ChangeProductQuantity = (product: Product, count: number) => void;
export type GetProductQuantityInCart = (product: Product) => number;
export type RemoveProductsFromCart = (productIds: string[]) => void;
export type ProductQuantityChangeSubscriber = (arg: {
  updatedData: UpdateQuantityData[];
  cart: CartStorage;
}) => void;

const emptyFn = () => null;

export type CartContextValue = {
  cart: CartStorage | undefined;
  updateCart: Dispatch<SetStateAction<CartStorage | undefined>>;
  selectedLocation: ShopLocation | undefined;
  updateSelectedLocation: (locationId?: string) => void;
  changeProductQuantity: ChangeProductQuantity;
  getProductQuantityInCart: GetProductQuantityInCart;
  resetCart: () => void;
  removeProductsFromCart: RemoveProductsFromCart;
  locations: ShopLocation[];
  total: PriceBottlesTotal | null;
  subscribeToCartProductsQuantityUpdate: (cb: ProductQuantityChangeSubscriber) => {
    unsubscribe: () => void;
  };
};

export const CartContext = createContext<CartContextValue>({
  cart: {},
  updateCart: emptyFn,
  selectedLocation: undefined,
  updateSelectedLocation: emptyFn,
  changeProductQuantity: emptyFn,
  getProductQuantityInCart: () => 0,
  resetCart: emptyFn,
  removeProductsFromCart: emptyFn,
  locations: [],
  total: null,
  subscribeToCartProductsQuantityUpdate: () => ({ unsubscribe: () => {} }),
});
