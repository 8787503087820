import type { IconProps } from '../types';

export const IconInvoice = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10.75H5V9.25H19V10.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 13.75H5.5V12.25H13C13.4142 12.25 13.75 12.5858 13.75 13C13.75 13.4142 13.4142 13.75 13 13.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.75 13C14.75 12.5858 15.0858 12.25 15.5 12.25H16.5C16.9142 12.25 17.25 12.5858 17.25 13C17.25 13.4142 16.9142 13.75 16.5 13.75H15.5C15.0858 13.75 14.75 13.4142 14.75 13Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 8C4.25 6.48122 5.48122 5.25 7 5.25H17C18.5188 5.25 19.75 6.48122 19.75 8V16C19.75 17.5188 18.5188 18.75 17 18.75H7C5.48122 18.75 4.25 17.5188 4.25 16V8ZM7 6.75C6.30964 6.75 5.75 7.30964 5.75 8V16C5.75 16.6904 6.30964 17.25 7 17.25H17C17.6904 17.25 18.25 16.6904 18.25 16V8C18.25 7.30964 17.6904 6.75 17 6.75H7Z"
        fill="currentColor"
      />
    </svg>
  );
};
