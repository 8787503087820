import type { IconProps } from './types';

export const IconInfo = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 11C4.25 7.27208 7.27208 4.25 11 4.25H13C16.7279 4.25 19.75 7.27208 19.75 11V13C19.75 16.7279 16.7279 19.75 13 19.75H11C7.27208 19.75 4.25 16.7279 4.25 13V11ZM11 5.75C8.10051 5.75 5.75 8.10051 5.75 11V13C5.75 15.8995 8.10051 18.25 11 18.25H13C15.8995 18.25 18.25 15.8995 18.25 13V11C18.25 8.10051 15.8995 5.75 13 5.75H11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V9C12.75 9.41421 12.4142 9.75 12 9.75C11.5858 9.75 11.25 9.41421 11.25 9V8C11.25 7.58579 11.5858 7.25 12 7.25ZM10.25 11C10.25 10.5858 10.5858 10.25 11 10.25H12.75V15.25H13C13.4142 15.25 13.75 15.5858 13.75 16C13.75 16.4142 13.4142 16.75 13 16.75H11C10.5858 16.75 10.25 16.4142 10.25 16C10.25 15.5858 10.5858 15.25 11 15.25H11.25V11.75H11C10.5858 11.75 10.25 11.4142 10.25 11Z"
        fill="currentColor"
      />
    </svg>
  );
};
