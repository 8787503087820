import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import {
  GetDistributorUsersRequestBody,
  GetUsersRequestData,
  GetOrderSummaryRequestBody,
  GetPlatformReportRequestBody,
  GetRetailerUsersRequestBody,
  GetTopPerformingUserReportRequestBody,
  GetOrderSummaryRequestData,
  GetPlatformReportRequestData,
  GetTopPerformingUserReportRequestData,
} from './types';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMonthOrderSummary: builder.mutation<
      ApiResponse<GetOrderSummaryRequestData>,
      GetOrderSummaryRequestBody
    >({
      query: (body) => ({
        url: 'api/getOrderSummary',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getDistributor: builder.mutation<
      ApiResponse<GetUsersRequestData>,
      GetDistributorUsersRequestBody
    >({
      query: () => ({
        url: 'api/getUsersByType?userType=DISTRIBUTOR',
        method: 'GET',
      }),
    }),
    getRetailer: builder.mutation<ApiResponse<GetUsersRequestData>, GetRetailerUsersRequestBody>({
      query: () => ({
        url: 'api/getUsersByType?userType=RETAILER',
        method: 'GET',
      }),
    }),
    getPlatformReport: builder.mutation<
      ApiResponse<GetPlatformReportRequestData>,
      GetPlatformReportRequestBody
    >({
      query: (body) => ({
        url: 'api/getPlatformReport',
        method: 'POST',
        body,
      }),
    }),
    getTopPerformingDistributor: builder.mutation<
      ApiResponse<GetTopPerformingUserReportRequestData>,
      GetTopPerformingUserReportRequestBody
    >({
      query: (body) => ({
        url: 'api/getTopPerformingUserReport ',
        method: 'POST',
        body,
      }),
    }),
    getOrderSummary: builder.mutation({
      query: () => ({
        url: 'api/getMappedUser',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetMonthOrderSummaryMutation,
  useGetDistributorMutation,
  useGetRetailerMutation,
  useGetPlatformReportMutation,
  useGetOrderSummaryMutation,
  useGetTopPerformingDistributorMutation,
} = reportApi;
