import type { IconProps } from '../types';

export const IconExchange = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 8.5C4.75 8.08579 5.08579 7.75 5.5 7.75H19C19.4142 7.75 19.75 8.08579 19.75 8.5C19.75 8.91421 19.4142 9.25 19 9.25H5.5C5.08579 9.25 4.75 8.91421 4.75 8.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 15.5C4.25 15.0858 4.58579 14.75 5 14.75H18.5C18.9142 14.75 19.25 15.0858 19.25 15.5C19.25 15.9142 18.9142 16.25 18.5 16.25H5C4.58579 16.25 4.25 15.9142 4.25 15.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.03031 4.46967C9.3232 4.76256 9.3232 5.23744 9.03031 5.53033L6.06064 8.5L9.03031 11.4697C9.3232 11.7626 9.3232 12.2374 9.03031 12.5303C8.73742 12.8232 8.26255 12.8232 7.96965 12.5303L4.61107 9.17175C4.24008 8.80075 4.24007 8.19925 4.61107 7.82825L7.96965 4.46967C8.26255 4.17678 8.73742 4.17678 9.03031 4.46967Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9697 11.4697C14.6768 11.7626 14.6768 12.2374 14.9697 12.5303L17.9393 15.5L14.9697 18.4697C14.6768 18.7626 14.6768 19.2374 14.9697 19.5303C15.2626 19.8232 15.7374 19.8232 16.0303 19.5303L19.3889 16.1718C19.7599 15.8008 19.7599 15.1992 19.3889 14.8282L16.0303 11.4697C15.7374 11.1768 15.2626 11.1768 14.9697 11.4697Z"
        fill="currentColor"
      />
    </svg>
  );
};
