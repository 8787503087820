import type { IconProps } from './types';

export const IconWarning = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V8C11.25 7.58579 11.5858 7.25 12 7.25Z"
        fill="currentColor"
      />
      <path
        d="M12.75 15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75C11.25 15.3358 11.5858 15 12 15C12.4142 15 12.75 15.3358 12.75 15.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 11C4.25 7.27208 7.27208 4.25 11 4.25H13C16.7279 4.25 19.75 7.27208 19.75 11V13C19.75 16.7279 16.7279 19.75 13 19.75H11C7.27208 19.75 4.25 16.7279 4.25 13V11ZM11 5.75C8.10051 5.75 5.75 8.10051 5.75 11V13C5.75 15.8995 8.10051 18.25 11 18.25H13C15.8995 18.25 18.25 15.8995 18.25 13V11C18.25 8.10051 15.8995 5.75 13 5.75H11Z"
        fill="currentColor"
      />
    </svg>
  );
};
