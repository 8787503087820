import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { UpdateSuperAdminEmailRequestBody, UpdateSuperAdminEmailRequestData } from './types';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getEmailUpdate: builder.mutation<
      ApiResponse<UpdateSuperAdminEmailRequestData>,
      UpdateSuperAdminEmailRequestBody
    >({
      query: (body) => ({
        url: 'api/updateSuperAdminEmail',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetEmailUpdateMutation } = profileApi;
