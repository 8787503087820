import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { TextField, ButtonAction, LoadingScreen } from '../../components';
import { FormikProvider, useFormik } from 'formik';
import { useSetPasswordUserMutation } from '../../redux/api/authApi/authApi';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { FetchQueryError } from '../../redux/api/types';
import Logo from '../../assets/images/logo-horizontal.webp';
import { showToastify } from '../../utils/common';
import { newPasswordSetupValidationSchema } from './constants';

const NewPasswordSetup: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isToken, setIsToken] = useState<boolean>(false);
  const [isLoadings, setIsLoadings] = useState<boolean>(false);

  const { instance } = useMsal();

  const [setPasswordApi, data] = useSetPasswordUserMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: newPasswordSetupValidationSchema,
    onSubmit: async (values) => {
      const formData = {
        userId: state?.userId,
        password: values.password,
      };
      await setPasswordApi(formData);
    },
  });

  useEffect(() => {
    if (data?.isSuccess) {
      showToastify('success', data?.data?.message);
      const token = localStorage.getItem('ACCESS_TOKEN');
      if (token) {
        setIsToken(true);
      } else {
        setTimeout(() => {
          navigate('/');
        }, 3000);
      }
    } else if (data?.isError) {
      showToastify('error', (data?.error as FetchQueryError)?.data!.error);
    }
  }, [data]);

  const handleLogout = () => {
    setIsLoadings(true);
    setTimeout(() => {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
      localStorage.clear();
    }, 3000);
  };

  return (
    <div className="Banner">
      <>
        {isLoadings ? (
          <>
            <LoadingScreen />
          </>
        ) : (
          <>
            <div className="lg:px-40 px-4 py-10 w-full h-full flex flex-col justify-center overflow-y-auto">
              <div className="self-center">
                <img src={Logo} alt="logo" className="mx-auto" />
              </div>
              <div className="xl:w-1/3 md:w-2/3 w-full h-fit bg-white rounded-2xl my-8 self-center p-6">
                <h5 className="text-primary font-semibold text-lg mb-6">New Password</h5>
                <FormikProvider value={formik}>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <div className="grid gap-5">
                      <div>
                        <TextField
                          label={'Password'}
                          required={true}
                          type="password"
                          fieldName="password"
                          placeholder={'Password'}
                          props={{
                            handleChange: formik.handleChange,
                            handleBlur: formik.handleBlur,
                            values: formik.values,
                            errors: formik.errors as { [key: string]: string },
                            touched: formik.touched as { [key: string]: boolean },
                          }}
                          value={formik.values.password}
                        />
                      </div>
                      <div>
                        <TextField
                          label={'Confirm Password'}
                          required={true}
                          type="password"
                          fieldName="confirmPassword"
                          placeholder={'Confirm Password'}
                          props={{
                            handleChange: formik.handleChange,
                            handleBlur: formik.handleBlur,
                            values: formik.values,
                            errors: formik.errors as { [key: string]: string },
                            touched: formik.touched as { [key: string]: boolean },
                          }}
                          value={formik.values.confirmPassword}
                        />
                      </div>
                    </div>
                    <div className="mt-6 flex justify-center">
                      <ButtonAction
                        type="submit"
                        className="bg-primary items-center flex justify-center text-white font-medium w-full h-[42px] px-4 py-2 rounded-xl"
                        label={'Submit'}
                        props={{
                          ...formik,
                        }}
                        isLoading={data?.isLoading}
                        spinColor="white"
                        spinHeight="20"
                        spinWidth="20"
                      />
                    </div>
                  </form>
                </FormikProvider>
              </div>
            </div>
            {isToken ? (
              <>
                <div className="fixed inset-0 flex items-center justify-center z-50 p-2 bg-black bg-opacity-25">
                  <div className="bg-white sm:p-1 p-4 rounded-2xl shadow-md 2xl:w-2/6 lg:w-96 md:w-2/3 relative">
                    <div className="flex flex-col items-center justify-center mt-5">
                      <span className="text-lg">
                        <span className="text-primary text-lg"></span>
                      </span>
                      <p className="text-secondary text-center text-md mt-2 px-[30px]">
                        In this window, you are already logged in. If you wish to log in with a
                        different account, please log out first.
                      </p>
                      <br />
                      <div className="flex mb-4">
                        <button
                          className="bg-primary text-white ms-2 w-fit h-[42px] px-[20px] py-[5px] rounded-lg font-medium"
                          onClick={handleLogout}
                        >
                          Log Out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default NewPasswordSetup;
