import type { IconProps } from '../types';

export const IconOrder = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.75C7.86193 5.75 7.75 5.86193 7.75 6V17.8352L8.79788 17.3589C9.07205 17.2343 9.38908 17.2471 9.65235 17.3933L10.9645 18.1223L11.9797 17.4455C12.2827 17.2435 12.6744 17.2325 12.9883 17.4171L14.1751 18.1152L15.1933 17.4364C15.4619 17.2574 15.803 17.227 16.099 17.3557L17.25 17.8561V6C17.25 5.86193 17.1381 5.75 17 5.75H8ZM6.25 6C6.25 5.0335 7.0335 4.25 8 4.25H17C17.9665 4.25 18.75 5.0335 18.75 6V18.695C18.75 19.3791 18.0486 19.839 17.4212 19.5662L15.7759 18.8508L14.7203 19.5545C14.4173 19.7565 14.0256 19.7675 13.7117 19.5829L12.5249 18.8848L11.5175 19.5564C11.2215 19.7537 10.8401 19.7691 10.5292 19.5964L9.16622 18.8392L7.59311 19.5542C6.96412 19.8402 6.25 19.3803 6.25 18.6894V6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0295 7.96967C15.3224 8.26256 15.3224 8.73744 15.0295 9.03033L12.0295 12.0303C11.7366 12.3232 11.2618 12.3232 10.9689 12.0303L9.96887 11.0303C9.67598 10.7374 9.67598 10.2626 9.96887 9.96967C10.2618 9.67678 10.7366 9.67678 11.0295 9.96967L11.4992 10.4393L13.9689 7.96967C14.2618 7.67678 14.7366 7.67678 15.0295 7.96967ZM9.2492 14C9.2492 13.5858 9.58499 13.25 9.9992 13.25H14.9132C15.3274 13.25 15.6632 13.5858 15.6632 14C15.6632 14.4142 15.3274 14.75 14.9132 14.75H9.9992C9.58499 14.75 9.2492 14.4142 9.2492 14Z"
        fill="currentColor"
      />
    </svg>
  );
};
