import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { MappedDistributorRequestBody, MappedDistributorRequestData } from './types';

export const mappedDistributorApi = createApi({
  reducerPath: 'mappedDistributorApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMappedDistributors: builder.mutation<
      ApiResponse<MappedDistributorRequestData>,
      MappedDistributorRequestBody
    >({
      query: (body) => ({
        url: 'api/getMappedData',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          // Handle any errors that might occur during the query
          console.error(error);
        }
      },
    }),
  }),
});

export const { useGetMappedDistributorsMutation } = mappedDistributorApi;
