import { createSlice } from '@reduxjs/toolkit';
import { UserStateType } from '../../types';

const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '') : null,
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = action.payload;
    },
  },
});

export default userSlice.reducer;

export const { setUser } = userSlice.actions;
export const currentUser = (state: UserStateType) => state.userState.user;
