export const handleKeyPress = (
  e: React.KeyboardEvent<HTMLInputElement>,
  maxLength: number,
  ipNumber?: boolean,
) => {
  const validCharacters = ipNumber ? /^[0-9.]+$/ : /^[0-9]+$/;
  const inputValue = e.currentTarget.value + e.key;

  if (!e.key.match(validCharacters) || inputValue.length > maxLength!) {
    e.preventDefault();
  }
};

export const handleDecimalPress = (
  e: React.KeyboardEvent<HTMLInputElement>,
  maxLength: number,
  ipNumber?: boolean,
) => {
  const validCharacters = ipNumber ? /^[0-9.]+$/ : /^[0-9]+$/;
  const inputValue = e.currentTarget.value;

  // Check if the pressed key is not a valid character or the input already contains a dot
  if (
    !e.key.match(validCharacters) ||
    (e.key === '.' && inputValue.includes('.')) ||
    inputValue.length >= maxLength!
  ) {
    e.preventDefault();
  }
};
export const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
    e.preventDefault();
  }
};

export const handleAlphabetPress = (
  e: React.KeyboardEvent<HTMLInputElement>,
  maxLength: number,
) => {
  const validCharacters = /^[A-Za-z\s]*$/; // Regular expression for alphabetic characters and spaces, but not leading or trailing spaces
  const inputValue = e.currentTarget.value + e.key;

  if (!e.key.match(validCharacters) || inputValue.length > maxLength!) {
    e.preventDefault();
  }
};
