import type { FilterChangeArgs, FilterType } from '../types';
import { useState } from 'react';
import { Slider } from '../../Slider';
import { IconChecked, IconSearch } from '../../../assets/icons';
import { Scrollbars } from 'rc-scrollbars';
import { Input } from '../../Input';

type FilterDialogContentProps = {
  id: string;
  type: FilterType;
  options: string[] | number[];
  selectedValues: string[] | number[];
  handleSelect: ({ id, type, newValue }: FilterChangeArgs) => void;
  inputPrefix?: React.ReactNode;
};

export const FilterDialogContent = ({
  id,
  type,
  options,
  selectedValues,
  handleSelect,
  inputPrefix,
}: FilterDialogContentProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [rangeValues, setRangeValues] = useState<number[]>(
    type === 'range'
      ? selectedValues && selectedValues[0] && selectedValues[1]
        ? (selectedValues as number[])
        : (options as number[])
      : [],
  );

  const handleRangeChange = (newValues: number[]) => {
    setRangeValues(newValues);
  };

  const handleRangeChangeEnd = (newValue: number[]) => {
    handleSelect({ id, type, newValue });
  };

  if (type === 'range') {
    const [minValue, maxValue] = options as number[];
    return (
      <>
        <Slider
          minValue={minValue}
          maxValue={maxValue > minValue ? maxValue : maxValue + 1}
          step={1}
          value={rangeValues}
          onChange={handleRangeChange}
          onChangeEnd={handleRangeChangeEnd}
          prefix={inputPrefix}
        />
      </>
    );
  }

  const filteredOptions = (options as string[]).filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="flex flex-col max-h-80">
      {(type === 'multi-select-search' || type === 'single-select-search') && (
        <div className="p-3 bg-white">
          <Input
            variant="field"
            value={searchTerm}
            autoFocus
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
            iconLeft={<IconSearch />}
            type="text"
          />
        </div>
      )}
      <Scrollbars style={{ height: 320 }} className="h-80">
        {filteredOptions.length ? (
          <ul>
            {filteredOptions.map((option) => (
              <li
                key={option}
                className="flex justify-between items-center py-2 px-3 text-grey-1 hover:bg-grey-7 cursor-pointer transition duration-300"
                onClick={() => handleSelect({ id, type, newValue: option })}
              >
                <span className="text-body-md !leading-6">{option}</span>
                {((selectedValues as string[]).includes(option) ||
                  (type === 'single-select' && selectedValues[0] === option)) && <IconChecked />}
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-body-md text-center pt-2 pb-4 text-grey-1">No results found</div>
        )}
      </Scrollbars>
    </div>
  );
};
