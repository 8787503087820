import type { IconProps } from './types';

export const IconError = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84477 8.84374C9.13772 8.5509 9.61259 8.55099 9.90543 8.84394L11.9999 10.9392L14.0935 8.84405C14.3862 8.55104 14.8611 8.55086 15.1541 8.84364C15.4471 9.13642 15.4473 9.6113 15.1545 9.9043L13.0606 11.9999L15.1552 14.0937C15.4482 14.3866 15.4483 14.8615 15.1554 15.1544C14.8626 15.4474 14.3877 15.4474 14.0948 15.1546L12 13.0606L9.90523 15.1546C9.61228 15.4474 9.13741 15.4474 8.84457 15.1544C8.55173 14.8615 8.55182 14.3866 8.84477 14.0937L10.9393 12L8.84457 9.9044C8.55173 9.61145 8.55182 9.13658 8.84477 8.84374Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 11C4.25 7.27208 7.27208 4.25 11 4.25H13C16.7279 4.25 19.75 7.27208 19.75 11V13C19.75 16.7279 16.7279 19.75 13 19.75H11C7.27208 19.75 4.25 16.7279 4.25 13V11ZM11 5.75C8.10051 5.75 5.75 8.10051 5.75 11V13C5.75 15.8995 8.10051 18.25 11 18.25H13C15.8995 18.25 18.25 15.8995 18.25 13V11C18.25 8.10051 15.8995 5.75 13 5.75H11Z"
        fill="currentColor"
      />
    </svg>
  );
};
