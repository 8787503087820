import { useEffect } from 'react';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import type { Row } from '@tanstack/react-table';
import type { ColumnDef } from '@tanstack/react-table';
import { TableHeaderGroups, TableBody } from './components';
import { Scrollbars } from 'rc-scrollbars';
import clsx from 'clsx';

type Props<T extends object> = {
  columns: ColumnDef<T, string | number>[];
  data: T[] | undefined;
  containerClassName?: string;
  scrollbarsClassName?: string;
  isLoading?: boolean;
  pageItemsCount?: number;
  rowClassName?: string | ((row: Row<T>) => string);
};

export const Table = <T extends object>({
  columns,
  data,
  containerClassName,
  scrollbarsClassName,
  isLoading,
  pageItemsCount,
  rowClassName,
}: Props<T>) => {
  const table = useReactTable({
    data: data ?? [],
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    table.getHeaderGroups().forEach((headerGroup) => {
      headerGroup.headers.forEach((header) => {
        if (!header.column.getIsPinned() && header.column.columnDef.enablePinning) {
          header.column.pin('left');
        }
        if (header.column.getIsPinned() && !header.column.columnDef.enablePinning) {
          header.column.pin(false);
        }
      });
    });
  }, [table, columns]);

  return (
    <div
      className={`bg-white flex flex-col w-full h-full rounded-2.5 pb-2 overflow-hidden ${containerClassName}`}
    >
      <Scrollbars
        className="flex flex-1 w-full flex-col"
        autoHide
        renderView={(props) => (
          <div
            {...props}
            className={clsx(
              'flex flex-col overflow-x-auto overflow-y-auto !sticky',
              scrollbarsClassName,
            )}
          />
        )}
      >
        <table className="min-w-full outline-none border-none border-separate border-spacing-0">
          <TableHeaderGroups table={table} />
          <TableBody
            rowClassName={rowClassName}
            hasData={!!data?.length}
            table={table}
            isLoading={isLoading}
            pageItemsCount={pageItemsCount}
          />
        </table>
        {!data?.length && !isLoading && (
          <div className="text-lg text-grey-1 text-center py-6">No results found</div>
        )}
      </Scrollbars>
    </div>
  );
};
