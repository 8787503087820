import React, { useEffect, useState } from 'react';

import { LegacyTable, MarkupModal, PaymentModal } from '../../components';
import { handleDecimalPress, handleKeyDown } from '../../utils/handlers';

import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import {
  useDeletePaymentAccountMutation,
  useGetUserAccountMutation,
  useVerifyBankAccountMutation,
} from '../../redux/api/stripeApi/stripeApi';
import { SidebarWithCurrentTabProps, MutationExtraType, CellType } from '../../types';
import { FetchQueryError } from '../../redux/api/types';
import { showToastify } from '../../utils/common';

type TableRow = {
  status?: number;
  joinedDate?: string;
  companyName?: string;
  isPrimary?: boolean;
  isVerified?: boolean;
  id?: string;
  accountType?: string;
  accountName?: string;
  last4?: number;
};

type CardVerifyType = {
  firstDeposit: string;
  secondDeposit: string;
  firstDepositError: string;
  secondDepositError: string;
};

const PaymentBankList: React.FC<SidebarWithCurrentTabProps> = ({ currentTab }) => {
  const navigate = useNavigate();
  const [mutationExtra, setMutationExtra] = useState<MutationExtraType>({
    sortBy: 'createdAt:DESC',
  });
  const [deleteCardApi, DeleteCardApiData] = useDeletePaymentAccountMutation();
  const [verifyBankDetailsApi, VerifyBankDetailsApiData] = useVerifyBankAccountMutation();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [VerifyModal, setVerifyModal] = useState<boolean>(false);
  const [accountId, setAccountid] = useState<TableRow>({});
  const [cardVerify, setCardVerify] = useState<CardVerifyType>({
    firstDeposit: '',
    secondDeposit: '',
    firstDepositError: '',
    secondDepositError: '',
  });
  const handleConfirmModal = (original: TableRow) => {
    setConfirmModal(true);
    setAccountid(original);
  };
  const handleDeleteApiCall = () => {
    deleteCardApi({ accountId: accountId.id });
  };
  const handleVerifyApiCall = () => {
    setCardVerify({
      ...cardVerify,
      firstDepositError:
        cardVerify.firstDeposit?.length === 0
          ? 'Please enter a first deposit value'
          : cardVerify.firstDeposit?.length > 5
            ? 'First deposit value limit exceed'
            : '',
      secondDepositError:
        cardVerify.secondDeposit?.length === 0
          ? 'Please enter a second deposit value'
          : cardVerify.secondDeposit?.length > 5
            ? 'Second deposit value limit exceed'
            : '',
    });
    if (
      cardVerify.firstDeposit?.length &&
      cardVerify.firstDeposit?.length <= 5 &&
      cardVerify.secondDeposit?.length &&
      cardVerify.secondDeposit?.length <= 5
    ) {
      const firstDepositString = cardVerify.firstDeposit.toString();
      const secondDepositString = cardVerify.secondDeposit.toString();

      const amountarray = [firstDepositString, secondDepositString];
      const payload = {
        accountId: accountId?.id,
        amounts: amountarray,
      };
      verifyBankDetailsApi(payload);
    }
  };
  const columns = [
    {
      header: 'Bank Name',
      accessorKey: 'accountType',
      canSort: false,
      sortFieldName: 'accountType',
      cell: (value: CellType<TableRow>) => {
        return (
          <>
            <span className="mx-3">{value.row.original.accountType}</span>
            {value.row.original.isPrimary && (
              <button className="ml-3 bg-green-100 px-3 py-[4px] text-success rounded-xl font-semibold cursor-auto">
                Primary
              </button>
            )}
          </>
        );
      },
    },
    {
      header: 'Account Number',
      accessorKey: 'last4',
      canSort: false,
      isEllipsis: true,
      sortFieldName: 'last4',
      cell: (cell: CellType<TableRow>) =>
        cell.row.original?.last4 ? (
          <span>
            <sub className="text-lg">************</sub>
            {`${cell.row.original?.last4}`}
          </span>
        ) : (
          ''
        ),
    },
    {
      header: 'Account Nick Name',
      accessorKey: 'accountName',
      canSort: false,
      sortFieldName: 'accountName',
    },
    {
      header: 'Routing Number',
      accessorKey: 'routingNumber',
      canSort: false,
      sortFieldName: 'routingNumber',
    },
    {
      header: 'Verified',
      accessorKey: 'isVerified',
      canSort: false,
      sortFieldName: 'isVerified',
      cell: (value: CellType<TableRow>) => {
        if (value.row.original.isVerified) {
          return (
            <button className="bg-green-100 px-3 py-[4px] text-success rounded-xl font-semibold cursor-auto">
              Verified
            </button>
          );
        } else {
          return (
            <span
              className={`bg-white py-[4px] text-primary rounded-lg font-medium cursor-pointer ${value.row.original?.isVerified ? 'disabled' : ''}`}
              onClick={() => {
                setVerifyModal(true);
                setAccountid(value.row.original);
                setCardVerify({
                  firstDeposit: '',
                  secondDeposit: '',
                  firstDepositError: '',
                  secondDepositError: '',
                });
              }}
            >
              Verify Now
            </span>
          );
        }
      },
    },
    // {
    //   header: '',
    //   accessorKey: 'isPrimary',
    //   canSort: false,
    //   sortFieldName: 'isPrimary',
    //   cell: (value: CellType<TableRow>) => {
    //     // Check the Status value and conditionally render a button
    //     if (value.row.original.isPrimary) {
    //       return (
    //         <button className="bg-green-100 px-3 py-[4px] text-success rounded-xl font-semibold cursor-auto">
    //           Primary
    //         </button>
    //       )
    //     }
    //   },
    // },

    {
      header: 'Action',
      accessorKey: 'status',
      canSort: false,
      cell: ({
        cell: {
          row: { original },
        },
      }: {
        cell: CellType<TableRow>;
      }) => (
        <div className="flex flex-row gap-2">
          <div
            className="cursor-pointer"
            onClick={() => navigate('/payment/bank-add', { state: { original } })}
          >
            <Icon icon="ph:eye-bold" color="#374BFF" width="18" height="18" />{' '}
          </div>
          <div className="cursor-pointer" onClick={() => handleConfirmModal(original)}>
            <Icon icon="ph:trash-bold" color="#B20036" width="18" height="18" />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (DeleteCardApiData?.isSuccess) {
      showToastify('success', DeleteCardApiData?.data?.message);
      setMutationExtra({
        sortBy: 'createdAt:DESC',
      });
      setConfirmModal(false);
    } else if (DeleteCardApiData?.isError) {
      showToastify('error', (DeleteCardApiData?.error as FetchQueryError)?.data?.error);
    }
  }, [DeleteCardApiData]);
  useEffect(() => {
    if (VerifyBankDetailsApiData?.isSuccess) {
      showToastify('success', VerifyBankDetailsApiData?.data?.message);
      setMutationExtra({
        sortBy: 'createdAt:DESC',
      });
      setVerifyModal(false);
    } else if (VerifyBankDetailsApiData?.isError) {
      showToastify('error', (VerifyBankDetailsApiData?.error as FetchQueryError)?.data?.error);
    }
  }, [VerifyBankDetailsApiData]);

  const handlePaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    inputValue: string,
    setValue: string,
    maxLength: number,
  ) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const sanitizedText = pastedText.replace(/[^0-9.]+|(\.[0-9]*\.)+/g, ''); // Remove non-numeric characters
    // const maxLength = 12
    const currentLength = inputValue.length;
    const remainingLength = maxLength - currentLength;
    const newTextLength = Math.min(sanitizedText.length, remainingLength);
    const newValue = inputValue.slice(0, currentLength) + sanitizedText.slice(0, newTextLength);

    // Update formik value with sanitized pasted text
    // formik.setFieldValue('accountNumber', newValue)
    setCardVerify({ ...cardVerify, [setValue]: newValue });
  };

  return (
    <div className="relative grow overflow-y-auto">
      <div className="relative">
        <LegacyTable
          dataColumns={columns}
          payment="card"
          addTitle="MappedUser"
          isSortable={true}
          canAdd={true}
          defaultSort={'createdAt'}
          AddPath={currentTab == 1 ? '/payment/bank-add' : '/payment/card-add'}
          useMutation={useGetUserAccountMutation}
          mutationExtra={mutationExtra}
          noData={
            <>
              No payment information was found.
              <br />
              Please ensure that you have added at least one payment method, either a credit card or
              a bank account, to enable automatic processing of all your invoices.
            </>
          }
        />
        <MarkupModal
          showModal={confirmModal}
          title="Confirmation"
          body={
            <>
              <p className="mt-3">
                Are you sure you want to delete the {accountId?.accountName} bank ?
              </p>
            </>
          }
          btn2Value={'Confirm'}
          btn1Value={'Cancel'}
          loading={DeleteCardApiData?.isLoading}
          btn2Click={() => {
            handleDeleteApiCall();
          }}
          btn1Click={() => {
            setConfirmModal(false);
          }}
          close={() => {
            setConfirmModal(false);
          }}
        />
        <PaymentModal
          showModal={VerifyModal}
          title="Verify bank account"
          subTitle=" Stripe sent two small deposits to this bank account. To verify this account. Please confirm the amounts of the deposits."
          body={
            <div className="flex items-start w-full gap-3">
              <div className="flex flex-col mt-3 w-1/2">
                <label>First Deposit</label>
                <input
                  type="text"
                  name="cardholderName"
                  className="border border-gray-300 rounded px-3 py-1.5 focus:outline-none focus:border-primary w-full"
                  placeholder="0.01"
                  value={cardVerify?.firstDeposit}
                  onChange={(e) => {
                    setCardVerify({
                      ...cardVerify,
                      firstDeposit: e.target.value,
                      firstDepositError: '',
                    });
                  }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    handleDecimalPress(e, 5, true)
                  }
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
                    handlePaste(e, cardVerify.firstDeposit, 'firstDeposit', 5)
                  }
                />
                {cardVerify.firstDepositError && (
                  <p className="error mt-2 text-sm">{cardVerify.firstDepositError}</p>
                )}
              </div>
              <div className="flex flex-col mt-3 w-1/2">
                <label>Second Deposit</label>
                <input
                  type="number"
                  name="cardholderName"
                  className="border border-gray-300 rounded px-3 py-1.5 focus:outline-none focus:border-primary w-full"
                  placeholder="0.02"
                  value={cardVerify?.secondDeposit}
                  onChange={(e) => {
                    setCardVerify({
                      ...cardVerify,
                      secondDeposit: e.target.value,
                      secondDepositError: '',
                    });
                  }}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    handleDecimalPress(e, 5, true)
                  }
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
                    handlePaste(e, cardVerify.secondDeposit, 'secondDeposit', 5)
                  }
                />
                {cardVerify.secondDepositError && (
                  <p className="error mt-2 text-sm">{cardVerify.secondDepositError}</p>
                )}
              </div>
            </div>
          }
          btn2Value={'Confirm'}
          btn1Value={'Cancel'}
          loading={VerifyBankDetailsApiData?.isLoading}
          btn2Click={() => {
            handleVerifyApiCall();
          }}
          btn1Click={() => {
            setVerifyModal(false);
          }}
          close={() => {
            setVerifyModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default PaymentBankList;
