import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useLocalStorage } from 'react-use';
import {
  BUY_PRODUCTS_CART_STORAGE_KEY,
  CART_FILL_USER_ID_STORAGE_KEY,
  SELECTED_CARD_LOCATION_STORAGE_KEY,
} from '../../constants';
import { convertMapToObject, convertObjectToMap, getPriceBottlesTotal } from './utils';
import { CartContext, ProductQuantityChangeSubscriber, UpdateQuantityData } from './context';
import type {
  CartStorage,
  ChangeProductQuantity,
  GetProductQuantityInCart,
  StoredProduct,
  RemoveProductsFromCart,
} from './context';
import { useGetRetailerLocationsQuery } from '../../redux/api/retailerOrderListApi/retailerOrderListApi';
import type { ShopLocation } from '../../redux/api/retailerOrderListApi/types';
import type { UserDataType } from '../../redux/api/authApi/types';
import { mockLocations } from './constants';

type Props = PropsWithChildren & {
  user: UserDataType;
};

export const CartProvider = ({ children, user }: Props) => {
  const [cart, updateCart] = useLocalStorage<CartStorage>(BUY_PRODUCTS_CART_STORAGE_KEY);
  const [cartFilledUserId, updateCartFilledUserId] = useLocalStorage<string>(
    CART_FILL_USER_ID_STORAGE_KEY,
  );
  const { data: locations } = useGetRetailerLocationsQuery(undefined, {
    skip: process.env.REACT_APP_HIDE_LOCATIONS === 'yes',
  });
  const [selectedLocation, setSelectedLocation] = useLocalStorage<ShopLocation>(
    SELECTED_CARD_LOCATION_STORAGE_KEY,
    locations?.[0] ?? mockLocations[0],
  );

  const quantityChangeSubscribers = useRef<ProductQuantityChangeSubscriber[]>([]);

  const total = selectedLocation?.id
    ? getPriceBottlesTotal(cart?.[selectedLocation.id]?.data)
    : null;

  useEffect(() => {
    if (locations && !selectedLocation) {
      setSelectedLocation(locations[0]);
    }
  }, [locations]);

  useEffect(() => {
    if (cart && cartFilledUserId) {
      if (user.userId !== cartFilledUserId) {
        updateCart({});
      }
    }
    updateCartFilledUserId(user.userId);
  }, [user, cartFilledUserId, cart]);

  const updateSelectedLocation = (locationId?: string) => {
    setSelectedLocation(locations?.find((item) => item.id === locationId));
  };

  const changeProductQuantity: ChangeProductQuantity = useCallback(
    (product, count) => {
      if (selectedLocation?.id) {
        const storageMap = convertObjectToMap(cart);
        const locationMap = storageMap.get(selectedLocation.id) || new Map<string, StoredProduct>();
        const oldProductQuantity = locationMap.get(product.productId)?.selectedCount;
        if (count > 0) {
          locationMap.set(product.productId, {
            product,
            selectedCount: count <= product.unitsAvailable ? count : product.unitsAvailable,
          });
        } else {
          locationMap.delete(product.productId);
        }

        storageMap.set(selectedLocation.id, locationMap);

        const updatedCart = convertMapToObject(storageMap);
        updateCart(updatedCart);

        const updatingProductData: UpdateQuantityData = {
          product,
          updatedQuantity: locationMap.get(product.productId)?.selectedCount ?? 0,
        };
        if (oldProductQuantity !== updatingProductData.updatedQuantity) {
          quantityChangeSubscribers.current.map((cb) => {
            cb({ updatedData: [updatingProductData], cart: updatedCart });
          });
        }
      }
    },
    [cart, selectedLocation, updateCart],
  );

  const getProductQuantityInCart: GetProductQuantityInCart = useCallback(
    (product) => {
      if (selectedLocation?.id) {
        const locationMap = convertObjectToMap(cart).get(selectedLocation.id);
        return locationMap?.get(product.productId)?.selectedCount ?? 0;
      }
      return 0;
    },
    [cart, selectedLocation],
  );

  const resetCart = useCallback(() => {
    if (selectedLocation?.id) {
      const storageMap = convertObjectToMap(cart);
      storageMap.set(selectedLocation.id, new Map<string, StoredProduct>());
      updateCart(convertMapToObject(storageMap));
    }
  }, [cart, selectedLocation, updateCart]);

  const removeProductsFromCart: RemoveProductsFromCart = useCallback(
    (productIds) => {
      if (selectedLocation?.id && cart) {
        const storageMap = convertObjectToMap(cart);
        const locationMap = storageMap.get(selectedLocation.id);
        if (locationMap) {
          const productsToRemove = productIds
            .map((item) => {
              const currentStoredProduct = locationMap.get(item);
              if (currentStoredProduct && currentStoredProduct.selectedCount !== 0) {
                return {
                  product: locationMap.get(item)?.product,
                  updatedQuantity: 0,
                };
              }
              return null;
            })
            .filter((item) => item?.product) as UpdateQuantityData[];
          productIds.forEach((product) => {
            locationMap.delete(product);
          });

          storageMap.set(selectedLocation.id, locationMap);
          const updatedCart = convertMapToObject(storageMap);
          updateCart(updatedCart);
          quantityChangeSubscribers.current.map((cb) => {
            cb({ updatedData: productsToRemove, cart: updatedCart });
          });
        }
      }
    },
    [cart, selectedLocation, updateCart],
  );

  const subscribeToCartProductsQuantityUpdate = (cb: ProductQuantityChangeSubscriber) => {
    quantityChangeSubscribers.current.push(cb);
    return {
      unsubscribe: () =>
        (quantityChangeSubscribers.current = quantityChangeSubscribers.current.filter(
          (item) => item !== cb,
        )),
    };
  };

  useEffect(() => {
    return () => {
      quantityChangeSubscribers.current = [];
    };
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        updateCart,
        selectedLocation,
        updateSelectedLocation,
        changeProductQuantity,
        getProductQuantityInCart,
        resetCart,
        removeProductsFromCart,
        locations: locations || mockLocations,
        total,
        subscribeToCartProductsQuantityUpdate,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
