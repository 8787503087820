import React from 'react';
import { TailSpin } from 'react-loader-spinner';

type ButtonProps = {
  label?: string;
  type?: string;
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  iconPosition?: 'Right' | 'Left';
  isLoading?: boolean;
  disabled?: boolean;
  spinColor?: string;
};

const LegacyButton: React.FC<ButtonProps> = ({
  label,
  icon,
  onClick,
  className = '',
  iconPosition = 'Left',
  isLoading,
  disabled,
  spinColor,
}) => {
  const maxLength = 40;

  const truncatedLabel =
    label && label.length > maxLength ? label.slice(0, maxLength) + '...' : label;

  return (
    <button
      className={`font-semibold rounded-lg  ${className} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {iconPosition === 'Right' ? (
        <>
          {label && <span>{truncatedLabel}</span>}
          {icon && <span>{icon}</span>}
        </>
      ) : (
        <>
          {icon && <span>{icon}</span>}
          {isLoading && label ? (
            <div className="flex items-center">
              <TailSpin
                visible={true}
                height="20"
                width="20"
                color={spinColor}
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
              />
              &ensp;{truncatedLabel}
            </div>
          ) : (
            label && <span>{truncatedLabel}</span>
          )}
        </>
      )}
    </button>
  );
};

export default LegacyButton;
