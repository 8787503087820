import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const LoaderComponent: React.FC = () => {
  return (
    <div className="items-center grow justify-center top-0 left-0 w-full z-[9999] h-[320px]">
      <RotatingLines
        visible={true}
        width="40"
        strokeColor="#b20036"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
      />
    </div>
  );
};

export default LoaderComponent;
