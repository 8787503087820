import { IconCart } from '../../assets/icons';

type Props = {
  count: number;
};

export const CartCountIcon = ({ count }: Props) => {
  return (
    <div className="relative">
      <IconCart />
      {!!count && (
        <div className="absolute rounded-full text-icon-badge top-0 left-[55%] min-w-4 h-4 flex items-center justify-center bg-primary-brand-dark text-white px-[1px] py-[2px]">
          {count < 100 ? count : '99+'}
        </div>
      )}
    </div>
  );
};
