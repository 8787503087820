import React, { ChangeEvent, CSSProperties } from 'react';

type CommonTextareaProps = {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  style?: CSSProperties;
  name?: string;
  disabled?: boolean;
};

const CommonTextarea: React.FC<CommonTextareaProps> = ({
  label,
  placeholder,
  value,
  onChange,
  rows,
  style,
  name,
  disabled,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
      <textarea
        name={name}
        className="border border-gray-100 rounded-lg block w-full p-3 text-sm text-stroke"
        placeholder={placeholder}
        rows={rows}
        value={value}
        onChange={onChange}
        style={style}
        disabled={disabled}
      />
    </div>
  );
};

export default CommonTextarea;
