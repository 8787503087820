// utils/navigationTracker.js

// Function to get the URL array from localStorage
function getUrlHistory() {
  const history = localStorage.getItem('urlHistory');
  return history ? JSON.parse(history) : [];
}

// Function to add a URL to the URL array in localStorage without duplication
function addUrlToHistory(url: string) {
  const history = getUrlHistory();
  // Only add the URL if it's different from the last one
  if (history.length === 0 || history[history.length - 1] !== url) {
    history.push(url);
    localStorage.setItem('urlHistory', JSON.stringify(history));
    console.log('URL added to history:', url); // Debugging log
  } else {
    console.log('Duplicate URL, not added:', url); // Debugging log
  }
}

// Function to get the previous URL from the URL array
function getPreviousUrl() {
  const history = getUrlHistory();
  if (history.length > 1) {
    history.pop(); // Remove current URL
    const previousUrl = history.pop(); // Get previous URL
    localStorage.setItem('urlHistory', JSON.stringify(history)); // Update history
    return previousUrl;
  }
  return null;
}

// Function to clear the URL history
function clearUrlHistory() {
  localStorage.removeItem('urlHistory');
}

export { getUrlHistory, addUrlToHistory, getPreviousUrl, clearUrlHistory };
