import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { RetailerDetailsFormData } from '../../../types';
import {
  GetFulfilmentTypeRequestData,
  GetRetailerByIdRequestData,
  GetRetailerListData,
  InviteRetailerRequestBody,
  RetailerFormItems,
  VerifyRequestData,
} from './types';
export const retailerApi = createApi({
  reducerPath: 'retailerApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getRetailerList: builder.mutation<ApiResponse<GetRetailerListData>, null>({
      query: (body) => ({
        url: 'api/getUsers',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    addRetailerList: builder.mutation<ApiResponse<null>, RetailerFormItems>({
      query: (body) => ({
        url: 'api/addUser',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateRetailer: builder.mutation<ApiResponse<null>, RetailerDetailsFormData>({
      query: (body) => ({
        url: 'api/updateUser',
        method: 'POST',
        body,
      }),
    }),
    getRetailerById: builder.query<ApiResponse<GetRetailerByIdRequestData>, string>({
      query: (params) => `api/getUserById?${params}`,
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getFulfilmentType: builder.query<ApiResponse<GetFulfilmentTypeRequestData[]>, string>({
      query: (params) => `api/getMasterData?${params}`,
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    retailerOnboarding: builder.mutation<ApiResponse<VerifyRequestData>, string>({
      query: (token) => ({
        url: `api/verify?token=${token}`,
        method: 'GET',
      }),
    }),
    inviteRetailer: builder.mutation<ApiResponse<null>, InviteRetailerRequestBody>({
      query: (body) => ({
        url: 'api/inviteUser',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetRetailerListMutation,
  useAddRetailerListMutation,
  useGetRetailerByIdQuery,
  useGetFulfilmentTypeQuery,
  useInviteRetailerMutation,
  useUpdateRetailerMutation,
  useRetailerOnboardingMutation,
} = retailerApi;
