import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { authApi } from './api/authApi/authApi';
import userReducer from './features/userSlice';
import { distributerApi } from './api/distributerApi/distributerApi';
import { retailerApi } from './api/retailerApi/retailerApi';
import { exchangeApi } from './api/exchangeApi/exchangeApi';
import { generateKeyApi } from './api/generateKeyApi/generateKeyApi';
import { retailerOrderListApi } from './api/retailerOrderListApi/retailerOrderListApi';
import { mappedDistributorApi } from './api/mappedDistributor/mappedDistributor';
import { requestDistributorApi } from './api/requestDistributor/requestDistributor';
import { requestRetailerApi } from './api/requestRetailerApi/requestRetailerApi';
import { distributorsOrderListApi } from './api/distributorsOrderListApi/distributorsOrderListApi';
import { retailerDetail_distributorTabApi } from './api/retailerDetailDistributorTabApi/retailerDetailDistributorTabApi';
import { markupProductApi } from './api/markupProductApi/markupProductApi';
import { reportApi } from './api/reportApi/reportApi';
import { dashboardApi } from './api/dashboardApi/dashboardApi';
import { revenueReportApi } from './api/revenueReportApi/revenueReportApi';
import { platformFeesApi } from './api/platformFeesApi/platformFeesApi';
import { purchasedProductReportApi } from './api/purchasedProductReportApi/purchasedProductReportApi';
import { paymentApi } from './api/paymentApi/paymentApi';
import { agreementApi } from './api/agreementApi/agreementApi';
import { profileApi } from './api/profileApi/profileApi';
import { stripeApi } from './api/stripeApi/stripeApi';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [distributerApi.reducerPath]: distributerApi.reducer,
    [retailerApi.reducerPath]: retailerApi.reducer,
    [exchangeApi.reducerPath]: exchangeApi.reducer,
    [generateKeyApi.reducerPath]: generateKeyApi.reducer,
    [retailerOrderListApi.reducerPath]: retailerOrderListApi.reducer,
    [mappedDistributorApi.reducerPath]: mappedDistributorApi.reducer,
    [requestDistributorApi.reducerPath]: requestDistributorApi.reducer,
    [requestRetailerApi.reducerPath]: requestRetailerApi.reducer,
    [distributorsOrderListApi.reducerPath]: distributorsOrderListApi.reducer,
    [retailerDetail_distributorTabApi.reducerPath]: retailerDetail_distributorTabApi.reducer,
    [markupProductApi.reducerPath]: markupProductApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [revenueReportApi.reducerPath]: revenueReportApi.reducer,
    [platformFeesApi.reducerPath]: platformFeesApi.reducer,
    [purchasedProductReportApi.reducerPath]: purchasedProductReportApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [agreementApi.reducerPath]: agreementApi.reducer,
    [retailerDetail_distributorTabApi.reducerPath]: retailerDetail_distributorTabApi.reducer,
    [stripeApi.reducerPath]: stripeApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,

    userState: userReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      distributerApi.middleware,
      retailerApi.middleware,
      exchangeApi.middleware,
      generateKeyApi.middleware,
      retailerOrderListApi.middleware,
      mappedDistributorApi.middleware,
      requestDistributorApi.middleware,
      requestRetailerApi.middleware,
      distributorsOrderListApi.middleware,
      retailerDetail_distributorTabApi.middleware,
      markupProductApi.middleware,
      reportApi.middleware,
      dashboardApi.middleware,
      revenueReportApi.middleware,
      platformFeesApi.middleware,
      purchasedProductReportApi.middleware,
      paymentApi.middleware,
      agreementApi.middleware,
      stripeApi.middleware,
      profileApi.middleware,
    ]),
});

export const RootState = store.getState;
export const AppDispatch = store.dispatch;
export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;
