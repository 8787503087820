import React from 'react';
import Select, { CSSObjectWithLabel } from 'react-select';
import { CustomStylesDataType, OptionType } from '../types';

type Props = {
  onChange: (selectedOption: OptionType) => void;
  duration?: string;
  name?: string;
};

const SelectDashboard: React.FC<Props> = ({ onChange, duration, name }) => {
  const options: OptionType[] =
    name === 'Top Sold Products'
      ? [
          { value: 'TODAY', label: 'Today' },
          { value: 'WEEK', label: 'Week' },
          { value: 'MONTH', label: 'Month' },
          { value: 'YEAR', label: 'Year' },
        ]
      : name === 'Daily Movers'
        ? [
            { value: 'TODAY', label: 'Today' },
            { value: 'WEEK', label: 'Week' },
            { value: 'MONTH', label: 'Month' },
          ]
        : [
            { value: 'WEEK', label: 'Week' },
            { value: 'MONTH', label: 'Month' },
            { value: 'YEAR', label: 'Year' },
          ];

  const customStyles = {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      width: '120px',
      borderColor: 'rgb(206 212 218/1)',
      cursor: 'pointer',
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#494949',
    }),
    indicatorSeparator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'none',
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#494949',
    }),
    option: (
      styles: CSSObjectWithLabel,
      { isDisabled, isFocused, isSelected }: CustomStylesDataType,
    ) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? '#b20036'
          : isFocused
            ? '#b20036'
            : undefined,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? 'white' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? '#b20036' : '') : undefined,
      },
    }),
  };

  const handleSelectChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      onChange(selectedOption);
    }
  };

  return (
    <Select
      value={options.find((option) => option.value === duration)}
      onChange={handleSelectChange}
      options={options}
      styles={customStyles}
    />
  );
};

export default SelectDashboard;
