import { IconProps } from './types';

export const IconChecked = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5399 7.47939C19.8274 7.77755 19.8188 8.25235 19.5206 8.53987L10.1876 17.5399C9.89709 17.82 9.43696 17.82 9.14642 17.5399L4.47942 13.0399C4.18124 12.7524 4.17259 12.2776 4.4601 11.9794C4.74761 11.6812 5.2224 11.6726 5.52058 11.9601L9.66697 15.9581L18.4794 7.46013C18.7776 7.1726 19.2523 7.18123 19.5399 7.47939Z"
        fill="currentColor"
      />
    </svg>
  );
};
