import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { ExchangeDetails } from './types';
import { Dispatch, SetStateAction } from 'react';

export const exchangeApi = createApi({
  reducerPath: 'exchangeApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    postExchangeDetails: builder.mutation<ApiResponse<ExchangeDetails>, ExchangeDetails>({
      query: (body) => ({
        url: 'api/addFileTransferConfig',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getExchangeDetails: builder.query<
      ApiResponse<ExchangeDetails>,
      { skip: Dispatch<SetStateAction<boolean>> }
    >({
      query: () => 'api/getFileTransferConfig',
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateExchangeDetails: builder.mutation<ApiResponse<ExchangeDetails>, ExchangeDetails>({
      query: (body) => ({
        url: 'api/updateFileTransferConfig',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    GetInventoryFile: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getInventoryFile',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    GetInventoryFileList: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getInventoryList',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  usePostExchangeDetailsMutation,
  useGetExchangeDetailsQuery,
  useUpdateExchangeDetailsMutation,
  useGetInventoryFileMutation,
  useGetInventoryFileListMutation,
} = exchangeApi;
