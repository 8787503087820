import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import {
  SendMappingRequestBody,
  UpdateMappingByUserIdRequestBody,
  UpdateMappingRequestBody,
} from './types';

export const requestDistributorApi = createApi({
  reducerPath: 'requestDistributorApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    requestDistributors: builder.mutation<ApiResponse<null>, SendMappingRequestBody>({
      query: (body) => ({
        url: 'api/sendMappingRequest',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    connectUserDistributors: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getConnectUsers',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateMappingUser: builder.mutation<ApiResponse<null>, UpdateMappingRequestBody>({
      query: (body) => ({
        url: 'api/updateMapping',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateMappingUserById: builder.mutation<ApiResponse<null>, UpdateMappingByUserIdRequestBody>({
      query: (body) => ({
        url: 'api/updateMappingByUserId',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useRequestDistributorsMutation,
  useConnectUserDistributorsMutation,
  useUpdateMappingUserMutation,
  useUpdateMappingUserByIdMutation,
} = requestDistributorApi;
