import React, { useEffect } from 'react';
import { Icon } from '@iconify/react';
import logo from '../../assets/images/logo.webp';
import { SalesByRegion, Graph, RetailerDailyMovers, TopPerforming } from '../../components';
import Header from '../../layout/dashboard/Header';
import { DISTRIBUTOR } from '../../constants';
import {
  useGetMetricsOnSalesMutation,
  useGetTopPerformingUserMutation,
  useGetTopSellingProductsMutation,
} from '../../redux/api/dashboardApi/dashboardApi';
import moment from 'moment';
import { SidebarProps } from '../../types';

const Distributer: React.FC<SidebarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const toggleSidebar = () => {
    setIsSidebarOpen?.(!isSidebarOpen);
  };

  const [getMetrics, { data, isLoading }] = useGetMetricsOnSalesMutation();
  const date = new Date();
  const offset = moment.parseZone(date).utcOffset();
  useEffect(() => {
    getMetrics(offset);
  }, [getMetrics]);

  return (
    <>
      {' '}
      <div className="Banner">
        <div
          className={`inline-flex items-center justify-between w-full p-3  text-sm text-gray-500 rounded-lg lg:hidden ${
            isSidebarOpen ? 'hidden' : ''
          }`}
          onClick={toggleSidebar}
        >
          <img src={logo} alt="logo" className="w-48" />
          <Icon icon="ri:menu-3-line" className="text-primary" width="24" height="24" />
        </div>
        <div className="lg:px-8 px-4 py-6 lg:ml-[16rem] h-full">
          <Header title="Dashboard" />
          <div className="relative h-full overflow-y-auto pb-10">
            <div className="grid xl:grid-cols-4 lg:grid-cols-2 gap-4 mt-2 h-fit mb-4">
              <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
                <div className="flex justify-center items-center xl:w-16 xl:h-16 w-22 h-22 bg-amber-100 rounded-full">
                  <Icon
                    icon="material-symbols:calendar-month-rounded"
                    className="text-warning"
                    width="32"
                    height="32"
                  />
                </div>
                <div>
                  <p className="font-medium mb-1.5">Total Sales</p>
                  <p className="font-semibold flex gap-2 mb-1.5 text-lg items-center">
                    {isLoading ? (
                      <div className="animate-pulse flex space-x-2 items-center">
                        <div className="h-2 bg-slate-100 rounded w-16"></div>
                      </div>
                    ) : (
                      <>{data?.data?.total?.totalPrice}</>
                    )}
                  </p>
                  <span>
                    {' '}
                    {isLoading ? (
                      <div className="animate-pulse flex space-x-2 items-center">
                        <div className="h-2 bg-slate-100 rounded w-16"></div>
                      </div>
                    ) : (
                      <>{data?.data?.total?.quantity} Bottles</>
                    )}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
                <div className="flex justify-center items-center xl:w-16 xl:h-16 w-22 h-22 bg-green-400 rounded-full">
                  <Icon
                    icon="material-symbols:calendar-month-rounded"
                    className="text-success"
                    width="32"
                    height="32"
                  />
                </div>
                <div>
                  <p className="font-medium mb-1.5">Today Sales</p>
                  <p className="font-semibold flex mb-1.5 text-lg items-center">
                    {isLoading ? (
                      <div className="animate-pulse flex space-x-2 items-center">
                        <div className="h-2 bg-slate-100 rounded w-16"></div>
                      </div>
                    ) : (
                      <>
                        {data?.data?.today?.totalPrice}
                        {data?.data?.today?.isGain === 0 &&
                        data?.data?.today?.percentage === '0.00' ? (
                          <Icon
                            icon="ion:arrow-up"
                            className="ml-1.5 text-gray-500"
                            width="22"
                            height="22"
                          />
                        ) : data?.data?.today?.isGain === 0 ? (
                          <Icon
                            icon="ion:arrow-down"
                            className="text-danger ml-1.5"
                            width="22"
                            height="22"
                          />
                        ) : (
                          <Icon
                            icon="ion:arrow-up"
                            className="text-green-900 ml-1.5"
                            width="22"
                            height="22"
                          />
                        )}
                        <span
                          className={
                            data?.data?.today?.isGain === 0 &&
                            data?.data?.today?.percentage === '0.00'
                              ? 'text-gray font-normal text-sm'
                              : data?.data?.today?.isGain === 0
                                ? 'text-danger font-normal text-sm'
                                : 'text-green-900 font-normal text-sm'
                          }
                        >
                          {data?.data?.today?.percentage}%
                        </span>
                      </>
                    )}
                  </p>
                  <span>
                    {isLoading ? (
                      <div className="animate-pulse flex space-x-2 items-center">
                        <div className="h-2 bg-slate-100 rounded w-16"></div>
                      </div>
                    ) : (
                      <>{data?.data?.today?.quantity} Bottles </>
                    )}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
                <div className="flex justify-center items-center xl:w-16 xl:h-16 w-22 h-22 bg-indigo-200 rounded-full">
                  <Icon
                    icon="bi:calendar2-week-fill"
                    className="text-indigo-800"
                    width="24"
                    height="24"
                  />
                </div>
                <div>
                  <p className="font-medium mb-1.5">This Week Sales</p>
                  <p className="font-semibold flex mb-1.5 text-lg items-center">
                    {isLoading ? (
                      <div className="animate-pulse flex space-x-2 items-center">
                        <div className="h-2 bg-slate-100 rounded w-16"></div>
                      </div>
                    ) : (
                      <>
                        {data?.data?.thisWeek?.totalPrice}
                        {data?.data?.thisWeek?.isGain === 0 &&
                        data?.data?.thisWeek?.percentage === '0.00' ? (
                          <Icon
                            icon="ion:arrow-up"
                            className="ml-1.5 text-gray-500"
                            width="22"
                            height="22"
                          />
                        ) : data?.data?.thisWeek?.isGain === 0 ? (
                          <Icon
                            icon="ion:arrow-down"
                            className="text-danger ml-1.5"
                            width="22"
                            height="22"
                          />
                        ) : (
                          <Icon
                            icon="ion:arrow-up"
                            className="text-green-900 ml-1.5"
                            width="22"
                            height="22"
                          />
                        )}

                        <span
                          className={
                            data?.data?.thisWeek?.isGain === 0 &&
                            data?.data?.thisWeek?.percentage === '0.00'
                              ? 'text-gray font-normal text-sm'
                              : data?.data?.thisWeek?.isGain === 0
                                ? 'text-danger font-normal text-sm'
                                : 'text-green-900 font-normal text-sm'
                          }
                        >
                          {data?.data?.thisWeek?.percentage}%
                        </span>
                      </>
                    )}
                  </p>
                  <span>
                    {' '}
                    {isLoading ? (
                      <div className="animate-pulse flex space-x-2 items-center">
                        <div className="h-2 bg-slate-100 rounded w-16"></div>
                      </div>
                    ) : (
                      <>{data?.data?.thisWeek?.quantity} Bottles</>
                    )}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
                <div className="flex justify-center items-center xl:w-16 xl:h-16 w-22 h-22 bg-red-200 rounded-full">
                  <Icon
                    icon="bi:calendar2-week-fill"
                    className="text-danger"
                    width="24"
                    height="24"
                  />
                </div>
                <div>
                  <p className="font-medium mb-1.5">This Month Sales</p>
                  <p className="font-semibold flex mb-1.5 text-lg items-center">
                    {isLoading ? (
                      <div className="animate-pulse flex space-x-2 items-center">
                        <div className="h-2 bg-slate-100 rounded w-16"></div>
                      </div>
                    ) : (
                      <>
                        {data?.data?.thisMonth?.totalPrice}

                        {data?.data?.thisMonth?.isGain === 0 &&
                        data?.data?.thisMonth?.percentage === '0.00' ? (
                          <Icon
                            icon="ion:arrow-up"
                            className="ml-1.5 text-gray-500"
                            width="22"
                            height="22"
                          />
                        ) : data?.data?.thisMonth?.isGain === 0 ? (
                          <Icon
                            icon="ion:arrow-down"
                            className="text-danger ml-1.5"
                            width="22"
                            height="22"
                          />
                        ) : (
                          <Icon
                            icon="ion:arrow-up"
                            className="text-green-900 ml-1.5"
                            width="22"
                            height="22"
                          />
                        )}

                        <span
                          className={
                            data?.data?.thisMonth?.isGain === 0 &&
                            data?.data?.thisMonth?.percentage === '0.00'
                              ? 'text-gray font-normal text-sm'
                              : data?.data?.thisMonth?.isGain === 0
                                ? 'text-danger font-normal text-sm'
                                : 'text-green-900 font-normal text-sm'
                          }
                        >
                          {data?.data?.thisMonth?.percentage}%
                        </span>
                      </>
                    )}
                  </p>
                  <span>
                    {' '}
                    {isLoading ? (
                      <div className="animate-pulse flex space-x-2 items-center">
                        <div className="h-2 bg-slate-100 rounded w-16"></div>
                      </div>
                    ) : (
                      <>{data?.data?.thisMonth?.quantity} Bottles </>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4 h-fit my-4">
              <div className="xl:col-span-6 col-span-12">
                <RetailerDailyMovers
                  name={'Top Selling Products'}
                  // @ts-ignore:next-line
                  useMutation={useGetTopSellingProductsMutation}
                  userType={DISTRIBUTOR}
                />
              </div>
              <div className="xl:col-span-6 col-span-12">
                <TopPerforming
                  name={'Top Performing Retailers'}
                  useMutation={useGetTopPerformingUserMutation}
                  userType={DISTRIBUTOR}
                />
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4 h-fit my-4">
              <div className="xl:col-span-6 col-span-12">
                <div className="bg-white rounded-2xl p-4 text-secondary h-[460px]">
                  <Graph />
                </div>
              </div>
              <SalesByRegion />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Distributer;
