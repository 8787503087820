import { IconProps } from './types';

export const IconLocation = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 10V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14.833 21V15C14.833 14.4696 14.6223 13.9609 14.2472 13.5858C13.8721 13.2107 13.3634 13 12.833 13H10.833C10.3026 13 9.79387 13.2107 9.41879 13.5858C9.04372 13.9609 8.83301 14.4696 8.83301 15V21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="16"
      />
      <path
        d="M21.818 9.364L20.124 3.435C20.0881 3.30965 20.0124 3.19939 19.9083 3.1209C19.8042 3.04241 19.6774 2.99997 19.547 3H15.5L15.975 8.704C15.9823 8.79568 16.0114 8.88429 16.0597 8.96254C16.1081 9.04078 16.1743 9.10641 16.253 9.154C16.643 9.387 17.405 9.817 18 10C19.016 10.313 20.5 10.2 21.346 10.096C21.4282 10.0854 21.5072 10.0569 21.5773 10.0126C21.6474 9.96835 21.707 9.90929 21.752 9.8396C21.7969 9.7699 21.8261 9.69123 21.8375 9.60909C21.8489 9.52695 21.8423 9.44331 21.818 9.364Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M13.9999 10C14.5679 9.825 15.2879 9.426 15.6899 9.188C15.7835 9.13205 15.8594 9.05087 15.9089 8.95377C15.9584 8.85667 15.9796 8.74757 15.9699 8.639L15.4999 3H8.49994L8.02994 8.639C8.02012 8.74774 8.04118 8.85704 8.09071 8.95433C8.14025 9.05163 8.21624 9.13297 8.30994 9.189C8.71194 9.426 9.43194 9.825 9.99994 10C11.4929 10.46 12.5069 10.46 13.9999 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M3.87605 3.435L2.18205 9.365C2.15814 9.44418 2.15175 9.52762 2.16332 9.60951C2.17489 9.69141 2.20414 9.76981 2.24905 9.83927C2.29396 9.90873 2.35345 9.96758 2.42339 10.0117C2.49332 10.0559 2.57203 10.0843 2.65405 10.095C3.49905 10.2 4.98405 10.312 6.00005 10C6.59505 9.817 7.35805 9.387 7.74705 9.155C7.82588 9.10731 7.89221 9.04153 7.94056 8.9631C7.9889 8.88467 8.01786 8.79585 8.02505 8.704L8.50005 3H4.45305C4.32267 2.99997 4.19583 3.04241 4.09172 3.1209C3.98762 3.19939 3.91191 3.30965 3.87605 3.435Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
