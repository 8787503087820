import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { LoadingScreen } from '../components';
// import {
//   useDocSignStatusMutation,
//   useDocSignTokenMutation,
//   useGetdocSignStatusMutation,
// } from '../redux/api/authApi/authApi';
import { ADMIN } from '../constants';
import { ApiResponse, FetchQueryError } from '../redux/api/types';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { DocSignRequestData } from '../redux/api/authApi/types';

type AuthGuardProps = {
  isSignedIn: boolean;
  children: ReactNode;
};

export default function AuthGuard({ isSignedIn, children }: AuthGuardProps) {
  const [data, setData] = useState<{
    data?: ApiResponse<DocSignRequestData>;
    error?: FetchBaseQueryError | SerializedError;
  }>();
  // const [loading, setLoading] = useState<boolean>(false); // Set initial loading state to true

  const [docStatus, setDocStatus] = useState<{
    data?: ApiResponse<string>;
    error?: FetchBaseQueryError | SerializedError;
  }>();

  const navigate = useNavigate();

  // const [getDodSignStatus] = useDocSignStatusMutation();
  // const [getDocToken] = useDocSignTokenMutation();
  // const [getDocSignStatus] = useGetdocSignStatusMutation();

  // useEffect(() => {
  //   setLoading(true);
  //
  //   async function fetchMyAPI() {
  //     try {
  //       const data = await getDodSignStatus();
  //       setData(data);
  //       const docuSignTokenResponse = await getDocToken({});
  //       if ('data' in docuSignTokenResponse) {
  //         const docuSignToken = docuSignTokenResponse.data;
  //         localStorage.setItem('DOCUSIGN_TOKEN', docuSignToken?.data?.docuSignToken || '');
  //       } else {
  //         console.error('Error fetching DocuSign token:', docuSignTokenResponse.error);
  //       }
  //       const token = localStorage.getItem('DOCUSIGN_TOKEN');
  //       if (data?.data?.data?.userType !== ADMIN) {
  //         const data = await getDocSignStatus({ token: token });
  //
  //         setDocStatus(data);
  //       }
  //
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   }
  //
  //   fetchMyAPI();
  // }, []);

  // if (loading) {
  //   return <LoadingScreen />;
  // }

  const docStatusError = docStatus?.error;
  if (
    (data && data?.data?.data?.userType !== ADMIN && docStatus?.data?.data !== 'completed') ||
    (docStatusError as FetchQueryError)?.status === 400
  ) {
    if (process.env.REACT_APP_HIDE_AGREEMENT !== 'yes') {
      navigate('/agreement');
    }
  }

  return isSignedIn ? (
    <>{children}</>
  ) : (
    <div>
      <p>Something Went Wrong!</p>
    </div>
  );
}
