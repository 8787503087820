export const mockLocations = [
  {
    address: 'address',
    createdAt: 'createdAt',
    customerNumber: 1,
    id: '0',
    name: 'locationName',
    retailerId: 'retailerId',
    shopifyId: 'shopifyId',
    updatedAt: 'updatedAt',
  },
];
