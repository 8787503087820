import type { Column } from '@tanstack/react-table';
import type { CSSProperties } from 'react';
import clsx from 'clsx';

export const getCommonPinningStyles = <T extends object>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
  };
};

export const getPinningClassName = <T extends object>(
  column: Column<T>,
  hasData?: boolean,
): string => {
  const isPinned = column.getIsPinned();

  return clsx({
    'sticky z-[1] opacity-100 bg-white': isPinned,
    'shadow-table': isPinned && hasData,
    'z-[0] relative': !isPinned,
  });
};
