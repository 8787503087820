import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { DistributorListRequestBody } from './types';

export const distributorsOrderListApi = createApi({
  reducerPath: 'distributorsOrderListApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getDistributorsOrderList: builder.mutation<ApiResponse<null>, DistributorListRequestBody>({
      query: (body) => ({
        url: 'api/getOrders',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getDistributorsOrderListById: builder.mutation<ApiResponse<null>, DistributorListRequestBody>({
      query: (body) => ({
        url: 'api/getOrdersByUserId',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const { useGetDistributorsOrderListMutation, useGetDistributorsOrderListByIdMutation } =
  distributorsOrderListApi;
