import React, { useEffect, useState } from 'react';
import { LoaderComponent, SelectDashboard } from '../components';
import moment from 'moment';
import { MutationExtraType, OptionType } from '../types';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import {
  TopSoldProductsByDurationRequestBody,
  TopSoldProductsByDurationRequestData,
} from '../redux/api/dashboardApi/types';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { ApiResponse } from '../redux/api/types';
import noImage from '../assets/images/no-image.webp';

type Props = {
  name: string;
  useMutation: UseMutation<
    MutationDefinition<
      TopSoldProductsByDurationRequestBody,
      (
        args: string | FetchArgs,
        api: BaseQueryApi,
        extraOptions: MutationExtraType,
      ) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
      never,
      ApiResponse<TopSoldProductsByDurationRequestData[]>
    >
  >;
  mutationExtra?: MutationExtraType;
};

const TopSoldProducts: React.FC<Props> = ({ name, useMutation, mutationExtra }) => {
  const [datas, setDatas] = useState<ApiResponse<TopSoldProductsByDurationRequestData[]>>();

  const [getData, { isLoading }] = useMutation();
  const [duration, setDuration] = useState<string>('MONTH');
  const date = new Date();
  const offset = moment.parseZone(date).utcOffset();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData({
          ...mutationExtra,
          duration: duration,
          offset: offset,
        });
        if (response?.data) {
          setDatas(response?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [mutationExtra, duration]);

  const handleSelectChange = (selectedOption: OptionType) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setDuration(value as string);
    }
  };
  return (
    <>
      <div className="xl:col-span-6 col-span-12 h-full">
        <div className="bg-white rounded-2xl p-4 text-secondary h-full">
          <div className="flex justify-between items-center mb-6">
            <p className="font-medium">{name}</p>

            <SelectDashboard
              duration={duration}
              onChange={handleSelectChange}
              name={'Top Sold Products'}
            />
          </div>
          {isLoading ? (
            <>
              <LoaderComponent />
            </>
          ) : (
            <div className="h-[320px]">
              {datas?.data?.length && datas?.data?.length > 0 ? (
                <>
                  {datas?.data?.map((data: TopSoldProductsByDurationRequestData, index: number) => (
                    <div key={index} className="sm:flex justify-between items-center mb-4">
                      <div className="flex gap-4 items-center">
                        <div className="w-12 h-12">
                          <img src={noImage} alt="whitewine" className="w-full h-full" />
                        </div>
                        <div>
                          <div className="max-w-[280px]">
                            <p className="font-medium text-sm overflow-hidden overflow-ellipsis hover:overflow-visible">
                              {data?.oi_product_name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="text-right mt-4 sm:mt-0">
                        <span className="rounded p-2 bg-transparent text-secondary font-medium text-sm">
                          {data?.quantity} {data?.quantity > 1 ? 'Bottles' : 'Bottle'}
                        </span>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm h-[320px]">
                  <h3 className="text-lg font-semibold">No Data Found</h3>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TopSoldProducts;
