import type { IconProps } from '../types';

export const IconBooth = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10.25C6.41421 10.25 6.75 10.5858 6.75 11V16C6.75 16.6904 7.30964 17.25 8 17.25H16C16.6904 17.25 17.25 16.6904 17.25 16V11C17.25 10.5858 17.5858 10.25 18 10.25C18.4142 10.25 18.75 10.5858 18.75 11V16C18.75 17.5188 17.5188 18.75 16 18.75H8C6.48122 18.75 5.25 17.5188 5.25 16V11C5.25 10.5858 5.58579 10.25 6 10.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 14.75C10.8619 14.75 10.75 14.8619 10.75 15V18C10.75 18.4142 10.4142 18.75 10 18.75C9.58579 18.75 9.25 18.4142 9.25 18V15C9.25 14.0335 10.0335 13.25 11 13.25H13C13.9665 13.25 14.75 14.0335 14.75 15V18C14.75 18.4142 14.4142 18.75 14 18.75C13.5858 18.75 13.25 18.4142 13.25 18V15C13.25 14.8619 13.1381 14.75 13 14.75H11Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91429 5.75C6.80746 5.75 6.71244 5.81787 6.6778 5.91892L5.75 8.625V8.75C5.75 9.57843 6.42157 10.25 7.25 10.25H7.46669C8.17544 10.25 8.75 9.67544 8.75 8.96669V8.91152C8.75 7.95636 10.1288 7.81997 10.3161 8.75644L10.3432 8.89175C10.5012 9.68152 11.1946 10.25 12 10.25C12.8054 10.25 13.4988 9.68151 13.6568 8.89175L13.7084 8.63393C13.8927 7.71203 15.25 7.84644 15.25 8.78658C15.25 9.58687 15.9154 10.25 16.7316 10.25C17.568 10.25 18.25 9.57049 18.25 8.75V8.625L17.3222 5.91892C17.2876 5.81787 17.1925 5.75 17.0857 5.75H6.91429ZM5.25888 5.43243C5.50139 4.7251 6.16653 4.25 6.91429 4.25H17.0857C17.8335 4.25 18.4986 4.7251 18.7411 5.43243L19.7077 8.25171C19.7357 8.33334 19.75 8.41904 19.75 8.50533V8.75C19.75 10.4148 18.3804 11.75 16.7316 11.75C15.8023 11.75 14.9628 11.3206 14.4131 10.6464C13.8231 11.3283 12.9531 11.75 12 11.75C11.075 11.75 10.2283 11.3528 9.63976 10.706C9.12968 11.3425 8.34578 11.75 7.46669 11.75H7.25C5.59315 11.75 4.25 10.4069 4.25 8.75V8.50943C4.25 8.42042 4.26473 8.33203 4.2936 8.24783L5.25888 5.43243Z"
        fill="currentColor"
      />
    </svg>
  );
};
