import { Scrollbars } from 'rc-scrollbars';
import { IconChecked } from '../../../assets/icons';
import type { SelectOption } from '../types';
import clsx from 'clsx';

type SelectDialogContentProps = {
  options: SelectOption[];
  selectedOption?: SelectOption;
  handleSelect: (selectedOption: SelectOption) => void;
  isDivided: boolean;
  variant: 'default' | 'multi-select' | 'search';
};

export const SelectDialogContent = ({
  options,
  selectedOption,
  handleSelect,
  isDivided,
}: SelectDialogContentProps) => {
  return (
    <div
      className={clsx(
        'w-auto bg-white border border-grey-5 rounded-2.5 outline-none overflow-hidden shadow-1 md:!w-80',
      )}
    >
      <Scrollbars autoHeight autoHeightMax={320}>
        <ul className={clsx({ 'divide-y divide-grey-4': isDivided })}>
          {options.map((option) => (
            <li
              key={option.value}
              className="flex flex-col gap-1 py-2 px-4 text-grey-1 hover:bg-grey-7 cursor-pointer transition duration-300"
              onClick={() => handleSelect(option)}
            >
              <div className="flex justify-between">
                <span className="text-grey-1 mt-1 text-body-lg font-subtitle overflow-hidden text-ellipsis whitespace-nowrap">
                  {option.label}
                </span>
                {selectedOption?.value === option.value && <IconChecked />}
              </div>
              {option.description && (
                <span className="text-grey-3 mb-1 text-sm overflow-hidden text-ellipsis whitespace-nowrap">
                  {option.description}
                </span>
              )}
            </li>
          ))}
        </ul>
      </Scrollbars>
    </div>
  );
};
