import { Icon } from '@iconify/react';
import React, { ReactNode } from 'react';
import { TailSpin } from 'react-loader-spinner';

type CommonModalProps = {
  showModal: boolean;
  title: string;
  body: ReactNode;
  btn1Click?: () => void;
  btn2Click?: () => void;
  btn1Value?: string;
  btn2Value?: string;
  close: () => void;
  textareaValue?: string;
  subTitle?: string;
  loading?: boolean;
};

const PaymentModal: React.FC<CommonModalProps> = ({
  showModal,
  title,
  body,
  btn1Click,
  btn2Click,
  btn1Value,
  btn2Value,
  close,
  subTitle,
  loading,
}) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 p-2 ${
        showModal ? 'bg-black bg-opacity-25' : 'hidden'
      }`}
    >
      <div
        className="bg-white p-6 rounded-2xl shadow-md 2xl:w-2/6 lg:w-1/2 md:w-2/3 w-full"
        id="closePopup"
      >
        <div>
          <div className="flex justify-between items-center mb-3">
            <p className="text-secondary font-semibold">{title}</p>
            <Icon icon="ic:round-close" className="text-black cursor-pointer" onClick={close} />
          </div>
          <hr className="m-0 text-black" />
          <p className="mt-3">{subTitle}</p>
          {body}
          <div className="mt-8 flex justify-center">
            {btn1Value && (
              <button
                className="bg-white text-secondary border border-gray-300 w-fit me-2 px-[20px] py-[8px] rounded-xl font-medium"
                onClick={btn1Click}
              >
                {btn1Value}
              </button>
            )}
            {loading && (
              <button
                className="text-white bg-primary border  w-fit ms-2 px-[20px] py-[8px] rounded-xl font-medium"
                onClick={btn2Click}
              >
                <div className="flex items-center justify-center">
                  <TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                  &ensp;{btn2Value}
                </div>
              </button>
            )}
            {!loading && btn2Value && (
              <button
                className="bg-primary text-white w-fit h-[42px] px-[20px] py-[10px] rounded-xl font-medium ml-5"
                onClick={btn2Click}
              >
                {btn2Value}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
