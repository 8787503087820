import ReactPaginate from 'react-paginate';
import clsx from 'clsx';
import { IconChevronLeft, IconChevronRight } from '../assets/icons';
import { Button } from './Button';

type PaginationProps = {
  pageCount: number;
  onPageChange: (selectedPage: number) => void;
  currentPage: number;
  isLoading?: boolean;
};

export const Pagination = ({
  pageCount,
  onPageChange,
  currentPage,
  isLoading,
}: PaginationProps) => {
  const handlePageChange = (event: { selected: number }) => {
    if (!isLoading) {
      onPageChange(event.selected + 1);
    }
  };

  return (
    <div className="flex items-center justify-center space-x-2 relative">
      <div
        className={clsx({
          'absolute top-0 left-0 w-full h-full cursor-not-allowed z-[2]': isLoading,
        })}
      />
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={7}
        marginPagesDisplayed={1}
        onPageChange={handlePageChange}
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        containerClassName={clsx('flex items-center gap-1', {
          'opacity-50': isLoading,
        })}
        pageLinkClassName="font-semibold block px-[13px] py-1.5 text-sm rounded-2.5 transition-colors duration-200 text-grey-1 hover:text-primary-brand-dark select-none"
        activeLinkClassName="bg-primary-brand hover:!text-white hover:bg-primary-brand-dark text-white"
        previousLabel={<Button icon={<IconChevronLeft />} variant="grey" size="small" />}
        nextLabel={<Button icon={<IconChevronRight />} variant="grey" size="small" />}
        breakLabel="..."
        disabledClassName="!cursor-not-allowed text-grey-3 opacity-50"
        nextLinkClassName={clsx({
          '!cursor-not-allowed opacity-50': isLoading,
        })}
        previousLinkClassName={clsx({
          '!cursor-not-allowed opacity-50': isLoading,
        })}
        breakLinkClassName={clsx(
          'inline-block px-3 text-sm py-1.5 w-8 flex justify-center hover:text-primary-brand-dark transition-colors duration-200 select-none',
          {
            '!cursor-not-allowed opacity-50': isLoading,
          },
        )}
      />
    </div>
  );
};
