type PriceProps = {
  value?: string | number;
  className?: string;
  withoutCurrency?: boolean;
};

export const Price = ({ value, className, withoutCurrency = false }: PriceProps) => {
  const formattedPrice = withoutCurrency
    ? Number(value || 0.0).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : Number(value || 0.0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });

  return <span className={className ?? ''}>{formattedPrice}</span>;
};
