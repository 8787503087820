import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import {
  AddUpdateMarkupRequestBody,
  MarkupByProductRequestBody,
  MarkupRequestBody,
  MarkupRequestData,
  MarkupByIdsRequestBody,
  MarkupByIdsRequestData,
  AddUpdateMarkupRequestData,
  MarkupByFiltersRequestBody,
} from './types';
import { GetProductsListResponse } from '../retailerOrderListApi/types';
import { successMessage } from '../../../pages/markup/utils';

export const markupProductApi = createApi({
  reducerPath: 'markupProductApi',
  tagTypes: ['ProductsList'],
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    addUpdateMarkup: builder.mutation<
      ApiResponse<AddUpdateMarkupRequestData>,
      AddUpdateMarkupRequestBody
    >({
      query: (body) => ({
        url: 'api/addUpdateMarkup',
        method: 'POST',
        body,
      }),
      invalidatesTags: (data) => {
        if (data?.message === successMessage) {
          return ['ProductsList'];
        } else {
          return [];
        }
      },
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getMarkupValue: builder.mutation<ApiResponse<MarkupRequestData>, MarkupRequestBody>({
      query: () => ({
        url: 'api/getMarkup',
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getMarkupByProduct: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getMarkupByProduct',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    updateMarkupByProduct: builder.mutation<ApiResponse<null>, MarkupByProductRequestBody>({
      query: (body) => ({
        url: 'api/updateMarkupByProduct',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getDistributorProductsList: builder.query<GetProductsListResponse, string>({
      query: (query: string) => ({
        url: `/api/v2/products?${query}`,
        method: 'GET',
      }),
      providesTags: ['ProductsList'],
    }),

    updateMarkup: builder.mutation<
      MarkupByIdsRequestData,
      MarkupByIdsRequestBody | MarkupByFiltersRequestBody
    >({
      query: (body) => ({
        url: 'api/v2/products/update-markup',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useAddUpdateMarkupMutation,
  useGetMarkupValueMutation,
  useGetMarkupByProductMutation,
  useUpdateMarkupByProductMutation,
  useUpdateMarkupMutation,
  useGetDistributorProductsListQuery,
} = markupProductApi;
