import type { IconProps } from './types';

export const IconSuccessFull = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.834 4.95825C8.48474 4.95825 4.95898 8.48401 4.95898 12.8333V15.1666C4.95898 19.5158 8.48474 23.0416 12.834 23.0416H15.1673C19.5166 23.0416 23.0423 19.5158 23.0423 15.1666V12.8333C23.0423 8.48401 19.5166 4.95825 15.1673 4.95825H12.834ZM18.9943 11.9937C19.336 11.652 19.3361 11.098 18.9944 10.7563C18.6528 10.4145 18.0987 10.4144 17.757 10.7561L12.9309 15.5812L10.8277 13.478C10.486 13.1363 9.93197 13.1363 9.59027 13.478C9.24856 13.8197 9.24856 14.3737 9.59027 14.7155L12.3121 17.4373C12.6538 17.779 13.2078 17.779 13.5495 17.4374L18.9943 11.9937Z"
        fill="currentColor"
      />
    </svg>
  );
};
