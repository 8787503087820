import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './sidebar';
import { useGetAuthUser } from '../../hooks';
import { DISTRIBUTOR, RETAILER } from '../../constants';
import { useLoginUserMutation } from '../../redux/api/authApi/authApi';
import { LoadingScreen } from '../../components';
import type { SidebarProps } from '../../types';
import { CartProvider } from '../cart/Provider';
import {
  adminSidenavConfig,
  distributorSidenavConfig,
  retailerSidenavConfig,
} from './sidebar/constants';

export default function DashboardLayout({
  isSidebarOpen = false,
  setIsSidebarOpen = () => null,
}: SidebarProps) {
  const [logUserData, { isLoading }] = useLoginUserMutation();
  const { user } = useGetAuthUser();

  useEffect(() => {
    logUserData();
  }, []);

  const sidenav =
    user?.userType === RETAILER
      ? retailerSidenavConfig
      : user?.userType === DISTRIBUTOR
        ? distributorSidenavConfig
        : adminSidenavConfig;

  return (
    <div className="bg-background">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Sidebar
            sidenav={sidenav}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />

          <div className="overflow-auto dashboard-layout">
            {user?.userType === RETAILER ? (
              <CartProvider user={user}>
                <Outlet />
              </CartProvider>
            ) : (
              <Outlet />
            )}
          </div>
        </>
      )}
    </div>
  );
}
