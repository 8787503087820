import { useState } from 'react';
import type { ChangeEvent, ReactNode } from 'react';
import { Input as AriaInput, TextArea as TextAreaReact } from 'react-aria-components';
import type { InputProps, TextAreaProps } from 'react-aria-components';
import clsx from 'clsx';
import { IconClose } from '../assets/icons';
import { Button } from './Button';

type BaseProps = {
  value: string;
  id?: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  successMessage?: string;
  description?: string;
  className?: string;
  iconClassName?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
};

type FieldProps = {
  variant: 'field';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: 'text' | 'password' | 'email' | 'number';
} & BaseProps &
  InputProps;

type TextAreaVariantProps = {
  variant: 'textarea';
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
} & BaseProps &
  TextAreaProps;

type Props = FieldProps | TextAreaVariantProps;

const isInput = (props: Props): props is FieldProps => {
  return props.variant === 'field';
};

const isTextArea = (props: Props): props is TextAreaVariantProps => {
  return props.variant === 'textarea';
};

const createInputStyles = (props: Props, isHovered: boolean) => {
  const {
    value,
    disabled,
    errorMessage,
    successMessage,
    className,
    iconLeft,
    iconRight,
    variant,
    description,
  } = props;

  const baseClasses =
    'w-full border bg-white rounded-2.5 py-[10px] px-3 text-body-md transition-all flex items-center outline-none';
  const stateClasses = clsx({
    'border-grey-4': !disabled && !errorMessage && !successMessage,
    'border-status-green': successMessage && !errorMessage && !disabled,
    'border-primary-brand': errorMessage && !disabled,
    'border-grey-6 cursor-not-allowed text-grey-4': disabled,
    'text-grey-1': !disabled && value,
    'text-grey-3': !disabled && !value,
    'h-10': variant === 'field',
    'h-20': variant === 'textarea',
    'mb-2': description || errorMessage || successMessage,
  });

  const inputPaddingClasses = clsx({
    '!pl-10': iconLeft,
    '!pr-10': iconRight || (isHovered && variant === 'field'),
    '!pr-12': iconRight && isHovered && variant === 'field',
  });

  return clsx(baseClasses, stateClasses, inputPaddingClasses, className);
};

export const Input = (props: Props) => {
  const {
    variant,
    value,
    onChange,
    label,
    disabled,
    errorMessage,
    successMessage,
    description,
    className,
    iconLeft,
    iconRight,
    id,
    iconClassName,
    ...restProps
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  const inputProps = {
    ...restProps,
    id,
    value,
    onChange,
    disabled,
    className: clsx(createInputStyles(props, isHovered), className),
  };

  return (
    <label className="block">
      {label && (
        <span
          className={clsx('text-body-md mb-2 block', {
            'text-grey-1': !disabled,
            'text-grey-4': disabled,
          })}
        >
          {label}
        </span>
      )}
      <div
        className="relative flex items-center transition-all"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isTextArea(props) && <TextAreaReact {...(inputProps as TextAreaProps)} />}
        {isInput(props) && (
          <>
            {iconLeft && (
              <div
                className={clsx(
                  'absolute h-10 top-0 left-2 flex items-center text-grey-3',
                  iconClassName,
                )}
              >
                {iconLeft}
              </div>
            )}
            <AriaInput {...(inputProps as InputProps)} />
            {(iconRight || isHovered) && (
              <div
                className={clsx(
                  'absolute h-10 top-0 right-2 flex items-center text-grey-3',
                  iconClassName,
                )}
              >
                {variant === 'field' && isHovered && !disabled && value && (
                  <Button
                    stopPropogation
                    variant="grey"
                    size="small"
                    icon={<IconClose className="w-4 h-4" />}
                    onClick={() =>
                      onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)
                    }
                  />
                )}
                {iconRight}
              </div>
            )}
          </>
        )}
      </div>
      {(description || errorMessage || successMessage) && (
        <span
          className={clsx('text-body-md', {
            'text-grey-3': description && !(successMessage || errorMessage) && !disabled,
            'text-status-green': successMessage && !errorMessage && !disabled,
            'text-status-red': errorMessage && !disabled,
            'text-grey-4': disabled,
          })}
        >
          {disabled && description
            ? description
            : errorMessage
              ? errorMessage
              : successMessage ?? description}
        </span>
      )}
    </label>
  );
};
