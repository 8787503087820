import { useGetdocSignUrlMutation } from '../redux/api/authApi/authApi';

const useDocuSignUrl = () => {
  const [getDocUrl] = useGetdocSignUrlMutation();

  const fetchDocuSignUrl = async (token: string | null): Promise<string> => {
    try {
      const response = await getDocUrl({ token });

      return response?.data?.data ?? '';
    } catch (error) {
      throw new Error(`Error fetching DocuSign URL: ${error}`);
    }
  };

  return { fetchDocuSignUrl };
};

export default useDocuSignUrl;
