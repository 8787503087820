import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import {
  GetTopPerformingUserRequestData,
  MetricsData,
  WithDurationRequestBody,
  RevenueBySalesRequestData,
  GetSalesByRegionRequestBody,
  SalesByRegionWithDurationRequestData,
  UserTypeBody,
  GetRecentlyJoinedUserRequestData,
  TopSoldProductsByDurationRequestBody,
  TopSoldProductsByDurationRequestData,
  GetDailyMoversRequestBody,
  GetDailyMoversRequestData,
  GetTopSellingProductsRequestData,
  GetTopSellingProductsRequestBody,
} from './types';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMetrics: builder.mutation<ApiResponse<MetricsData>, number>({
      query: (offset) => ({
        url: `/api/getMetricsOnSales?offset=${offset}`,
        method: 'GET',
      }),
    }),
    getTopPerformingUser: builder.mutation<
      ApiResponse<GetTopPerformingUserRequestData[]>,
      UserTypeBody
    >({
      query: ({ userType }) => ({
        url: `/api/getTopPerformingUser?userType=${userType}`,
        method: 'GET',
      }),
    }),
    getRecentlyJoinedUser: builder.mutation<
      ApiResponse<GetRecentlyJoinedUserRequestData[]>,
      UserTypeBody
    >({
      query: ({ userType }) => ({
        url: `/api/getRecentlyJoinedUser?userType=${userType}`,
        method: 'GET',
      }),
    }),
    getSalesByRegion: builder.mutation<
      ApiResponse<null>, //error
      // ApiResponse<GetSalesByRegionRequestData[]>, //error
      GetSalesByRegionRequestBody
    >({
      query: () => ({
        url: '/api/getSalesByRegion',
        method: 'GET',
      }),
    }),
    getMetricsOnSales: builder.mutation<ApiResponse<MetricsData>, number>({
      query: (offset) => ({
        url: `/api/getMetricsOnSalesForUser?offset=${offset}`,
        method: 'GET',
      }),
    }),
    getTopSellingProducts: builder.mutation<
      ApiResponse<GetTopSellingProductsRequestData>,
      GetTopSellingProductsRequestBody
    >({
      query: () => ({
        url: '/api/getTopSellingProducts',
        method: 'GET',
      }),
    }),
    getTopPerformingUsers: builder.mutation<ApiResponse<null>, null>({
      query: () => ({
        url: '/api/getTopPerformingUser',
        method: 'GET',
      }),
    }),
    getTopSoldProductsByDuration: builder.mutation<
      ApiResponse<TopSoldProductsByDurationRequestData[]>,
      TopSoldProductsByDurationRequestBody
    >({
      query: (body) => ({
        url: '/api/getTopSoldProductsByDuration',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),

    getSalesByRegionWithDuration: builder.mutation<
      ApiResponse<SalesByRegionWithDurationRequestData[]>,
      WithDurationRequestBody
    >({
      query: (body) => ({
        url: '/api/getSalesByRegionWithDuration',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getRevenueBySales: builder.mutation<
      ApiResponse<RevenueBySalesRequestData[]>,
      WithDurationRequestBody
    >({
      query: (body) => ({
        url: '/api/getRevenueBySales',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getDailyMovers: builder.mutation<
      ApiResponse<GetDailyMoversRequestData[]>,
      GetDailyMoversRequestBody
    >({
      query: (body) => ({
        url: '/api/getDailyMovers',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetMetricsMutation,
  useGetTopPerformingUserMutation,
  useGetRecentlyJoinedUserMutation,
  useGetSalesByRegionMutation,
  useGetMetricsOnSalesMutation,
  useGetTopSellingProductsMutation,
  useGetTopPerformingUsersMutation,
  useGetTopSoldProductsByDurationMutation,
  useGetSalesByRegionWithDurationMutation,
  useGetRevenueBySalesMutation,
  useGetDailyMoversMutation,
} = dashboardApi;
