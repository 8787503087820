import React from 'react';
import { TailSpin } from 'react-loader-spinner';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  className: string;
  props?: {
    isValid?: boolean;
    dirty?: boolean;
    isSubmitting?: boolean;
  };
  label: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  isLoading?: boolean;
  spinColor?: string;
  spinHeight?: string;
  spinWidth?: string;
};

const ButtonAction: React.FC<ButtonProps> = ({
  type,
  className,
  props,
  label,
  style,
  disabled,
  isLoading,
  spinColor,
  spinHeight,
  spinWidth,
}) => {
  return (
    <button
      type={type}
      className={className}
      style={{
        ...style,
      }}
      disabled={disabled}
    >
      {props?.isSubmitting && (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      )}{' '}
      {isLoading ? (
        <div className="flex items-center justify-center">
          <TailSpin
            visible={true}
            height={spinHeight}
            width={spinWidth}
            color={spinColor}
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
          &ensp;{label}
        </div>
      ) : (
        label
      )}
    </button>
  );
};

export default ButtonAction;
