import React from 'react';
import { AuthProvider } from '../hooks';
import AppRoutes from './AppRoutes';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import UnAuthAppRoutes from './UnAuthAppRoutes';

type RouterProps = {
  instance: IPublicClientApplication;
};

const Router: React.FC<RouterProps> = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <AuthenticatedTemplate>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnAuthAppRoutes />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default Router;
