import * as Yup from 'yup';

export const bankAddValidationSchema = (value: string) =>
  Yup.object().shape({
    accountNumber: value
      ? Yup.string().nullable()
      : Yup.string()
          .max(12, 'Account number must be at most 12 characters')
          .required('Account number is required'),
    routingNumber: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .length(9, 'Must be exactly 9 digits')
      .required('Routing number is required'),

    accountName: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9\s]+$/, 'Account name must not contain special characters')
      .required('Account name is required')
      .label('Account Name'),
  });
