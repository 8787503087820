import { IconProps } from './types';

export const IconCart = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92789 21.3071C8.92435 21.8446 8.4864 22.2777 7.94894 22.2754C7.41147 22.273 6.97734 21.836 6.97852 21.2986C6.97969 20.7611 7.41572 20.326 7.95319 20.326C8.49313 20.3281 8.92932 20.7672 8.92789 21.3071V21.3071Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7775 21.3071C14.774 21.8446 14.336 22.2777 13.7985 22.2754C13.2611 22.273 12.827 21.836 12.8281 21.2986C12.8293 20.7611 13.2653 20.326 13.8028 20.326C14.3427 20.3281 14.7789 20.7672 14.7775 21.3071Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5121 14.9111C18.0574 15.0448 19.5039 14.1373 20.056 12.6878L21.2506 9.62485L21.9076 7.61501C21.9887 7.40071 22.0165 7.16992 21.9886 6.94249C21.8125 5.90736 20.7825 6.01666 19.539 6.01666H6.87695L7.43503 14.9111H16.5121Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1708 6.26826C6.30904 6.65873 6.73763 6.8632 7.1281 6.72497C7.51857 6.58674 7.72304 6.15814 7.58481 5.76767L6.1708 6.26826ZM6.03427 3.63523L5.28432 3.62595C5.28323 3.71433 5.29777 3.8022 5.32726 3.88552L6.03427 3.63523ZM5.85594 3.18924L6.39275 2.66547L6.39275 2.66546L5.85594 3.18924ZM5.41447 3L5.42364 2.25H5.41447V3ZM4 2.25C3.58579 2.25 3.25 2.58579 3.25 3C3.25 3.41421 3.58579 3.75 4 3.75V2.25ZM7.48746 15.6594C7.90069 15.6309 8.21258 15.2728 8.18408 14.8595C8.15558 14.4463 7.79749 14.1344 7.38425 14.1629L7.48746 15.6594ZM7.43586 18.1593L7.38425 18.9075C7.40143 18.9087 7.41864 18.9093 7.43586 18.9093V18.1593ZM16.5875 18.9093C17.0017 18.9093 17.3375 18.5735 17.3375 18.1593C17.3375 17.7451 17.0017 17.4093 16.5875 17.4093V18.9093ZM7.58481 5.76767L6.74127 3.38493L5.32726 3.88552L6.1708 6.26826L7.58481 5.76767ZM6.78421 3.6445C6.78873 3.27916 6.64792 2.92698 6.39275 2.66547L5.31913 3.71301C5.29644 3.68975 5.28392 3.65844 5.28432 3.62595L6.78421 3.6445ZM6.39275 2.66546C6.13759 2.40395 5.78898 2.25452 5.42363 2.25006L5.40531 3.74994C5.37282 3.74955 5.34182 3.73626 5.31913 3.71301L6.39275 2.66546ZM5.41447 2.25H4V3.75H5.41447V2.25ZM7.38425 14.1629C6.13742 14.2489 5.16993 15.2854 5.16993 16.5352H6.66993C6.66993 16.0738 7.02713 15.6911 7.48746 15.6594L7.38425 14.1629ZM5.16993 16.5352C5.16993 17.785 6.13742 18.8215 7.38425 18.9075L7.48746 17.411C7.02713 17.3793 6.66993 16.9966 6.66993 16.5352H5.16993ZM7.43586 18.9093H16.5875V17.4093H7.43586V18.9093Z"
        fill="currentColor"
      />
    </svg>
  );
};
