import { useState } from 'react';
import wineImagePlaceholder from '../../assets/images/wine-image-placeholder.png';

type Props = {
  src?: string;
};

export const ProductImage = ({ src }: Props) => {
  const [imageSrc, setImageSrc] = useState(src || wineImagePlaceholder);

  const handleError = () => {
    setImageSrc(wineImagePlaceholder);
  };

  return (
    <div className="rounded-2.5 relative min-w-12 w-12 h-12 overflow-hidden">
      <img
        alt="Product image"
        src={imageSrc}
        onError={handleError}
        className="w-full h-full object-cover"
      />
    </div>
  );
};
