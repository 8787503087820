import React, { useEffect, useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
  AreaChart,
} from 'recharts';
import {
  useGetRevenueBySalesMutation,
  useGetSalesByRegionMutation,
} from '../../redux/api/dashboardApi/dashboardApi';
import { useGetAuthUser } from '../../hooks';
import { ADMIN, DISTRIBUTOR } from '../../constants';
import { LoaderComponent, SelectDashboard } from '../index';
import moment from 'moment';
import { RevenueBySalesRequestData } from '../../redux/api/dashboardApi/types';
import { OptionType } from '../../types';
import { CustomTooltip } from './components/TooltipContent';

const Graph: React.FC = () => {
  const [duration, setDuration] = useState<string>('WEEK');

  const [getGraphData, { data: datas, isLoading }] = useGetSalesByRegionMutation();

  const [getRevenueData, { data: revenueDatas, isLoading: isLoadingRevenue }] =
    useGetRevenueBySalesMutation();

  const { user } = useGetAuthUser();
  const date = new Date();
  const offset = moment.parseZone(date).utcOffset();
  useEffect(() => {
    if (user && user?.userType === ADMIN) {
      getGraphData({});
    }

    if (user && user?.userType === DISTRIBUTOR) {
      getRevenueData({ duration: duration, offset: offset });
    }
  }, [getGraphData, getRevenueData, duration]);

  const graphData =
    user?.userType === ADMIN
      ? datas?.data
      : user?.userType === DISTRIBUTOR
        ? revenueDatas?.data
        : [];

  let formattedData;
  if (user?.userType === ADMIN) {
    formattedData = graphData?.map((item: RevenueBySalesRequestData) => ({
      region: item?.region,
      sales: typeof item?.sales === 'string' ? parseFloat(item.sales.replace(/[^\d.]/g, '')) : null,
      displaySales: item?.sales,
    }));
  }

  let formattedRevenueData;
  if (user?.userType === DISTRIBUTOR) {
    formattedRevenueData = graphData?.map((item: RevenueBySalesRequestData) => ({
      no: item.no,
      duration: item?.duration,
      sales: typeof item?.sales === 'string' ? parseFloat(item.sales.replace(/[^\d.]/g, '')) : null,
      displaySales: item?.sales,
    }));
  }

  const formatYAxisTick = (value: number) =>
    `${user?.userType === ADMIN ? `${value / 10000}k` : `${value}`}`;

  const handleSelectChange = (selectedOption: OptionType) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setDuration(value as string);
    }
  };

  return (
    <div
      style={
        user?.userType === ADMIN
          ? { width: '100%', height: '18rem' }
          : { width: '100%', height: '25rem' }
      }
      className="md:p-2.5 p-0 flex flex-col"
    >
      {isLoading ? (
        <LoaderComponent />
      ) : // @ts-ignore next line
      formattedData?.length > 0 && user?.userType === ADMIN ? (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={formattedData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#B20036" stopOpacity={1} />
                  <stop offset="95%" stopColor="white" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="region" tick={{ fill: '#494949', fontSize: 12 }} dy={10} />
              <YAxis
                tick={{ fill: '#494949', fontSize: 12 }}
                tickFormatter={formatYAxisTick}
                dx={-5}
              />
              <CartesianGrid />
              <Tooltip content={<CustomTooltip userType={user?.userType} />} />
              <Area
                type="monotone"
                dataKey="sales"
                stroke="#B20036"
                fillOpacity={1}
                fill="url(#colorPv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center mb-6">
            <p className="font-medium">Revenue by Sales</p>
            <SelectDashboard duration={duration} onChange={handleSelectChange} />
          </div>
          <>
            {isLoadingRevenue ? (
              <>
                <LoaderComponent />
              </>
            ) : graphData?.length && graphData.length > 0 ? (
              <>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={formattedRevenueData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#B20036" stopOpacity={1} />
                        <stop offset="95%" stopColor="white" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="duration" tick={{ fill: '#494949', fontSize: 12 }} dy={10} />
                    <YAxis
                      tick={{ fill: '#494949', fontSize: 12 }}
                      tickFormatter={formatYAxisTick}
                      dx={-5}
                    />
                    <CartesianGrid />
                    <Tooltip content={<CustomTooltip userType={user?.userType} />} />
                    <Area
                      type="monotone"
                      dataKey="sales"
                      stroke="#B20036"
                      fillOpacity={1}
                      fill="url(#colorPv)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </>
            ) : (
              <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm h-[320px]">
                <h3 className="text-lg font-semibold">No Data Found</h3>
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default Graph;
