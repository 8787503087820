import React from 'react';
import { useSliderThumb } from '@react-aria/slider';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { SliderState } from '@react-stately/slider';

type SliderThumbProps = {
  index: number;
  state: SliderState;
  trackRef: React.RefObject<HTMLDivElement>;
};

export const SliderThumb = ({ index, state, trackRef }: SliderThumbProps) => {
  const inputRef = React.useRef(null);
  const { thumbProps, inputProps } = useSliderThumb({ index, trackRef, inputRef }, state);

  return (
    <div
      {...thumbProps}
      className="absolute transform -translate-y-1/2 rounded-full bg-white border border-[#ECECEC] top-0.5 w-3 h-3 shadow-slider-thumb"
      style={{
        left: `calc(${state.getThumbPercent(index) * 100}% - 6px)`,
      }}
    >
      <VisuallyHidden>
        <input {...inputProps} ref={inputRef} />
      </VisuallyHidden>
    </div>
  );
};
