// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  SortingState,
  RowData,
} from '@tanstack/react-table';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { CommonButton, DebouncedInput, FilterDropdown } from '../components';
import { useGetAuthUser } from '../hooks';
import { ADMIN } from '../constants';
import { TailSpin } from 'react-loader-spinner';
import { CellType, CustomStylesDataType, MutationExtraType, OptionType } from '../types';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { ApiResponse } from '../redux/api/types';
import { CSSObjectWithLabel, SingleValue } from 'react-select';

type Action = {
  icon?: string;
  onClick: (data: any) => void;
};

type TableProps = {
  dataColumns: any;
  showActions?: boolean;
  actions?: Action[];
  refetch?: () => void;
  isSortable?: boolean;
  data?: any[];
  resetFilter?: boolean;
  defaultSort?: string;
  sortingKey?: string;
  canSearch?: boolean;
  canAdd?: boolean;
  canInvite?: boolean;
  selectFilter?: boolean;
  platformFilter?: boolean;
  addTitle?: string;
  title?: string;
  AddPath?: string;
  InvitePath?: string;
  useMutation?: UseMutation<
    MutationDefinition<
      any,
      (
        args: string | FetchArgs,
        api: BaseQueryApi,
        extraOptions: MutationExtraType,
      ) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
      never,
      ApiResponse<any>
    >
  >;
  mutationExtra?: MutationExtraType;
  canHeader?: boolean;
  canConnect?: boolean;
  ConnectPath?: string;
  RowPath?: string;
  returnpath?: string;
  handleOption?: (selectedOption: OptionType) => void;
  monthOptions?: string[];
  monthlyReport?: boolean;
  platformReport?: boolean;
  distributorOptions?: string[];
  distributorhandleOption?: (selectedOption: OptionType) => void;
  distributorSelectOptions?: (selectedOption: OptionType) => void;
  retailerhandleOption?: (selectedOption: OptionType) => void;
  selectedRetailerId?: string;
  selectedDistributorId?: string;
  revenueReport?: boolean;
  downloadCsv?: boolean;
  exportData?: boolean;
  monthOption?: boolean;
  handleDropdownChange?: (selectedOption: OptionType) => void;
  searchDownload?: (value: string) => void;
  sortingDownload?: (value: string) => void;
  handleDownloadClick?: () => void;
  isLoadingCsv?: boolean;
  isSortBy?: boolean;
  topPerformingRetailer?: boolean;
  topSellingProduct?: boolean;
  sellingDistributor?: boolean;
  noData?: ReactNode;
  payNow?: boolean;
  retailerReport?: boolean;
  name?: string;
  purchasedProduct?: boolean;
  distributorReport?: boolean;
  tableTitle?: string;
  selectedUserId?: string;
  selectedMonths?: number;
  selectedYear?: number | string;
  handleYearChange?: (selectedOption: SingleValue<OptionType>) => void;
  handleMonthChange?: (selectedOption: SingleValue<OptionType>) => void;
  month?: number | string;
  year?: number | string;
  isEllipsis?: boolean;
  payment?: string;
  reportLoading?: boolean;
  isDataLoading?: boolean;
};

const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    padding: '4px',
    // width: "270px",
    borderColor: 'rgb(206 212 218/1)', // Set the background color for the display box
    cursor: 'pointer',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#494949', // Set the color for the displayed value
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none', // Hide the indicator separator
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#494949', // Set the color for the placeholder text
  }),
  option: (
    styles: CSSObjectWithLabel,
    { isDisabled, isFocused, isSelected }: CustomStylesDataType,
  ) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
        ? '#b20036'
        : isFocused
          ? '#b20036'
          : undefined,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? 'white' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',

    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled ? (isSelected ? '#b20036' : '') : undefined,
    },
  }),
};

const LegacyTable: React.FC<TableProps> = ({
  dataColumns = [],
  showActions = false,
  actions = [],
  refetch,
  resetFilter = false,
  defaultSort = 'created_at',
  sortingKey,
  canSearch,
  canAdd,
  canInvite,
  selectFilter,
  addTitle,
  title,
  InvitePath,
  AddPath,
  useMutation,
  mutationExtra,
  canHeader,
  canConnect,
  ConnectPath,
  platformFilter,
  RowPath,
  returnpath,
  distributorhandleOption,
  retailerhandleOption,
  revenueReport,
  downloadCsv,
  monthOption,
  handleDropdownChange,
  handleYearChange,
  searchDownload,
  sortingDownload,
  handleDownloadClick,
  isLoadingCsv,
  isSortBy = true,
  noData,
  payNow,
  tableTitle,
  selectedUserId,
  selectedMonths,
  selectedYear,
  handleMonthChange,
  month,
  year,
  isEllipsis,
  payment,
  reportLoading,
  isDataLoading,
}) => {
  const [datas, setDatas] = useState<any[]>([]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean> | undefined>({});
  const [columnFilters, setColumnFilters] = useState<unknown[]>([]);
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean> | undefined>({});
  const [filtering, setFiltering] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [searchText, setSearchText] = useState<string>('');
  const [connectSearchText, setConnectSearchText] = useState<string>('');
  const [getData, { isLoading }] = useMutation({
    skip: canConnect && !searchText,
  });
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<string>('');

  const path = window.location.pathname;

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: defaultSort,
      desc: sortingKey ? false : true,
    },
  ]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (searchDownload) {
    searchDownload(searchText);
  }
  if (sortingDownload) {
    sortingDownload(sorting[0]?.id);
  }

  const navigate = useNavigate();
  const { user } = useGetAuthUser();
  const handlePageSizeChange = (newSize: number | string) => {
    setPageSize(Number(newSize));
    table.setPageSize(Number(newSize));
  };
  const handleOption = (selections: any) => {
    if (selections) {
      setSelectedMonth(selections.value);
    } else {
      setSelectedMonth('');
    }
  };

  useEffect(() => {
    if (mutationExtra?.distributorId) {
      setPage(1);
    }
    if (mutationExtra?.retailerId) {
      setPage(1);
    }
  }, [mutationExtra]);

  const dropdownRef = useRef(null);
  useEffect(() => {
    const clearTime = setTimeout(() => {
      const sortingData = sorting?.map((s) => `${s.id}:${s.desc ? 'DESC' : 'ASC'}`).join(',');

      const fetchData = async () => {
        const modifiedMutationExtra = { ...mutationExtra };
        if (sorting?.length > 0 && isSortBy) {
          modifiedMutationExtra.sortBy = sortingData;
        }
        if (searchText) {
          modifiedMutationExtra.searchTerm = searchText;
          modifiedMutationExtra.page = page;
        }

        if (page) {
          modifiedMutationExtra.page = page;
        }
        if (pageSize) {
          modifiedMutationExtra.size = pageSize;
          modifiedMutationExtra.page = page;
        }
        if (selectedMonth) {
          modifiedMutationExtra.month = selectedMonth;
        }
        const response = await getData(modifiedMutationExtra);
        if (title === 'Connect' && !searchText) {
          setDatas([]);
        } else {
          setDatas(response?.data?.data);
        }
      };
      fetchData();
    }, 800);

    function myStopFunction() {
      clearTimeout(clearTime);
    }

    return () => {
      myStopFunction();
    };
  }, [mutationExtra, sorting, searchText, page, pageSize, selectedMonth, isDataLoading]);

  useEffect(() => {
    if (connectSearchText?.length === 0) {
      setSearchText('');
    }
  }, [connectSearchText]);

  if (showActions && actions?.length) {
    dataColumns = [
      ...dataColumns,
      {
        id: 'custom-actions',
        header: () => 'Action',
        disableHidden: true,

        cell: (cell: CellType<RowData>) =>
          actions?.map(
            (action, index) =>
              action.icon && (
                <button
                  key={index}
                  type="button"
                  className="btn btn-default btn-icon p-0"
                  onClick={() => action.onClick(cell.row.original)}
                >
                  <i className={`${action.icon} text-secondary`} />
                </button>
              ),
          ),
      },
    ];
  }

  const tableContainerRef = useRef(null);
  const virtualizerInstanceRef = useRef(null);

  const sortedData = useMemo(() => {
    const flattenedData = datas?.items?.flatMap((page: any) => page) ?? [];
    const comparator = (a, b) => {
      const aValue = String(a.distributorId);
      const bValue = String(b.distributorId);

      if (sorting[0]?.desc) {
        return bValue.localeCompare(aValue);
      } else {
        return aValue.localeCompare(bValue);
      }
    };

    return flattenedData.slice().sort(comparator);
  }, [datas?.items, sorting]);

  useEffect(() => {
    setColumnFilters([]);
  }, [refetch, resetFilter, sorting]);

  const flatData = useMemo(
    () => datas?.items?.flatMap((page) => page) ?? [],
    [datas?.items, sorting],
  );

  const totalDBRowCount = datas?.totalCount;
  const filteredDataArray =
    datas && datas?.items?.length && datas?.items.filter((item) => item.accountType !== payment);

  const table = useReactTable({
    data: datas?.items?.length ? (payment ? filteredDataArray : datas?.items) : sortedData,
    columns: dataColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualSorting: true,
    enableSortingRemoval: false,
    manualPagination: true,
    enableMultiRowSelection: true,
    manualGlobalFilter: true,
    state: {
      rowSelection,
      sorting,
      columnVisibility,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChanged: setFiltering,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setSearchText,
  });
  useEffect(() => {
    if (virtualizerInstanceRef.current) {
      virtualizerInstanceRef.current.scrollToIndex(0);
    }
  }, [sorting, columnFilters]);

  const BTableFun = () => {
    return (
      <>
        <div ref={tableContainerRef} className="bg-white rounded-lg overflow-auto">
          {!isLoading && payment && filteredDataArray !== undefined ? (
            (filteredDataArray && filteredDataArray?.length === 0) || !filteredDataArray ? (
              <>
                {
                  <div className="flex flex-col w-full justify-center items-center bg-thead text-secondary font-light text-sm rounded-lg border-b border-b-stroke">
                    <div className="mx-auto">
                      <p className="text-base font-semibold py-6 mx-auto text-center tracking-[-0.14px] break-words">
                        {noData}
                      </p>
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                }
              </>
            ) : (
              <table className="w-full text-left text-secondary">
                <thead className="text-base font-medium whitespace-nowrap border-b border-b-stroke">
                  {table.getHeaderGroups()?.map((headerGroup) => (
                    <tr key={headerGroup.id} className="align-middle">
                      {headerGroup.headers?.map((header, index) => (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className={`p-4 ${
                            index === 0 && dataColumns?.length === 2
                              ? 'w-3/4 rounded-tl-lg'
                              : index === 0
                                ? 'rounded-tl-lg'
                                : index === dataColumns?.length - 1
                                  ? 'rounded-tr-lg'
                                  : ''
                          }`}
                        >
                          <div className="flex items-center gap-1">
                            <span
                              className={header?.column?.columnDef?.canSort ? 'cursor-pointer' : ''}
                              onClick={
                                header?.column?.columnDef?.canSort
                                  ? header.column.getToggleSortingHandler()
                                  : undefined
                              }
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </span>

                            {!header?.column?.columnDef?.canSort ? null : (
                              <>
                                {header.column.getIsSorted() === 'desc' && (
                                  <div className="flex flex-col">
                                    <CaretUp
                                      className={
                                        header?.column?.columnDef?.canSort
                                          ? 'h-2 w-2 cursor-pointer'
                                          : 'h-2 w-2'
                                      }
                                      onClick={
                                        header?.column?.columnDef?.canSort
                                          ? header.column.getToggleSortingHandler()
                                          : undefined
                                      }
                                    />
                                  </div>
                                )}
                                {header.column.getIsSorted() === 'asc' && (
                                  <div className="flex flex-col">
                                    <CaretDown
                                      className={
                                        header?.column?.columnDef?.canSort
                                          ? 'h-2 w-2 cursor-pointer'
                                          : 'h-2 w-2'
                                      }
                                      onClick={
                                        header?.column?.columnDef?.canSort
                                          ? header.column.getToggleSortingHandler()
                                          : undefined
                                      }
                                    />
                                  </div>
                                )}
                                {!header.column.getIsSorted() && (
                                  <div className="flex flex-col">
                                    <CaretDown
                                      className={
                                        header?.column?.columnDef?.canSort
                                          ? 'h-2 w-2 cursor-pointer'
                                          : 'h-2 w-2'
                                      }
                                      onClick={
                                        header?.column?.columnDef?.canSort
                                          ? header.column.getToggleSortingHandler()
                                          : undefined
                                      }
                                    />
                                    <CaretUp
                                      className={
                                        header?.column?.columnDef?.canSort
                                          ? 'h-2 w-2 cursor-pointer'
                                          : 'h-2 w-2'
                                      }
                                      onClick={
                                        header?.column?.columnDef?.canSort
                                          ? header.column.getToggleSortingHandler()
                                          : undefined
                                      }
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                {!isLoading &&
                ((filteredDataArray && filteredDataArray?.length === 0) ||
                  filteredDataArray === false) ? (
                  ''
                ) : (
                  <tbody>
                    {isLoading || reportLoading
                      ? Array.from({ length: pageSize }).map((_, rowIndex) => (
                          <tr
                            key={rowIndex}
                            className={`bg-thead text-secondary whitespace-nowrap font-light text-sm${
                              rowIndex === pageSize - 1 ? '' : 'border-b border-b-stroke'
                            }`}
                          >
                            {dataColumns.map((column, columnIndex: number) => (
                              <td
                                key={columnIndex}
                                className={clsx('px-4 py-3.5 whitespace-nowrap max-h-[50px]', {
                                  'overflow-visible': column?.header === 'Action',
                                  'overflow-hidden': column?.header !== 'Action',
                                })}
                              >
                                <Skeleton
                                  width={'100%'}
                                  baseColor="#ffffff30"
                                  highlightColor="#ffffff30"
                                  style={{
                                    background:
                                      ' linear-gradient(90deg, rgba(153,153,153,0.3) 0%, rgba(153,153,153,0.3) 100%)',
                                    zIndex: 0,
                                  }}
                                />
                              </td>
                            ))}
                          </tr>
                        ))
                      : datas?.items?.length !== 0 &&
                        table.getRowModel().rows.map((row, index) =>
                          index === table.getRowModel().rows?.length - 1 ? (
                            <tr
                              key={row.id}
                              className={`bg-thead text-secondary whitespace-nowrap font-light text-sm ${
                                addTitle === 'MappedUser' ? 'cursor-auto' : 'cursor-pointer'
                              }  ${
                                index === table.getRowModel().rows?.length - 1
                                  ? ''
                                  : 'border-b border-b-stroke'
                              }`}
                              onClick={() => {
                                if (payNow === true) {
                                  return;
                                }
                                if (addTitle === 'MappedUser') {
                                  return;
                                }
                                localStorage.setItem('userId', JSON.stringify(row.original));
                                localStorage.setItem('returnPath', JSON.stringify(returnpath));
                                if (RowPath === '/orders/customer') {
                                  localStorage.setItem('month', JSON.stringify(month));
                                  localStorage.setItem('year', JSON.stringify(year));
                                  localStorage.setItem('tab', 'distributor');
                                }
                                localStorage.setItem('tab', 'distributor');
                                navigate(RowPath ?? '');
                              }}
                            >
                              {row.getVisibleCells().map((cell, index, array) => (
                                <td
                                  key={cell.id}
                                  style={{
                                    maxWidth: cell?.column?.columnDef?.isEllipsis
                                      ? '300px'
                                      : 'none',

                                    overflow: cell?.column?.columnDef?.isEllipsis
                                      ? 'hidden'
                                      : cell?.column?.id === 'status'
                                        ? 'visible'
                                        : 'hidden',
                                    textOverflow: cell?.column?.columnDef?.isEllipsis
                                      ? 'ellipsis'
                                      : 'clip',
                                    whiteSpace: 'nowrap',
                                    maxHeight: cell?.column?.id === 'status' ? 50 : 'none',
                                  }}
                                  className={`px-4 py-3.5 ${
                                    index === 0
                                      ? 'rounded-bl-lg'
                                      : index === array?.length - 1
                                        ? 'rounded-br-lg'
                                        : ''
                                  }`}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              ))}
                            </tr>
                          ) : (
                            <tr
                              key={row.id}
                              className={`bg-thead text-secondary whitespace-nowrap font-light text-sm ${
                                addTitle === 'MappedUser' ? '' : 'cursor-pointer'
                              } ${
                                index === table.getRowModel().rows?.length - 1
                                  ? 'rounded-b-lg'
                                  : 'border-b border-b-stroke'
                              }`}
                              onClick={() => {
                                if (addTitle === 'MappedUser') {
                                  return;
                                }
                                localStorage.setItem('userId', JSON.stringify(row.original));
                                if (RowPath === '/orders/customer') {
                                  localStorage.setItem('month', JSON.stringify(month));
                                  localStorage.setItem('year', JSON.stringify(year));
                                  localStorage.setItem('tab', 'distributor');
                                }
                                localStorage.setItem('returnPath', JSON.stringify(returnpath));
                                localStorage.setItem('tab', 'distributor');
                                navigate(RowPath ?? '');
                              }}
                            >
                              {row.getVisibleCells().map((cell, index) => (
                                <>
                                  <td
                                    key={cell.id}
                                    style={{
                                      maxWidth: cell?.column?.columnDef?.isEllipsis
                                        ? '300px'
                                        : 'none',

                                      overflow: cell?.column?.columnDef?.isEllipsis
                                        ? 'hidden'
                                        : cell?.column?.id === 'status'
                                          ? 'visible'
                                          : 'hidden',
                                      textOverflow: cell?.column?.columnDef?.isEllipsis
                                        ? 'ellipsis'
                                        : 'clip',
                                      whiteSpace: 'nowrap',
                                      maxHeight: cell?.column?.id === 'status' ? 50 : 'none',
                                    }}
                                    className={'px-4 py-3.5'}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                                </>
                              ))}
                            </tr>
                          ),
                        )}
                  </tbody>
                )}
              </table>
            )
          ) : (datas && datas?.items?.length === 0) || datas === false ? (
            <div className="flex flex-col w-full justify-center items-center bg-thead text-secondary font-light text-sm rounded-lg border-b border-b-stroke">
              <div className="mx-auto">
                <p className="text-base font-semibold py-6 mx-auto text-center tracking-[-0.14px] break-words">
                  {noData}
                </p>
              </div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <table className="w-full text-left text-secondary">
              <thead className="text-base font-medium whitespace-nowrap border-b border-b-stroke">
                {table.getHeaderGroups()?.map((headerGroup) => (
                  <tr key={headerGroup.id} className="align-middle">
                    {headerGroup.headers?.map((header, index) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={`p-4 ${
                          index === 0 && dataColumns?.length === 2
                            ? 'w-3/4 rounded-tl-lg'
                            : index === 0
                              ? 'rounded-tl-lg'
                              : index === dataColumns?.length - 1
                                ? 'rounded-tr-lg'
                                : ''
                        }`}
                      >
                        <div className="flex items-center gap-1">
                          <span
                            className={header?.column?.columnDef?.canSort ? 'cursor-pointer' : ''}
                            onClick={
                              header?.column?.columnDef?.canSort
                                ? header.column.getToggleSortingHandler()
                                : undefined
                            }
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </span>

                          {!header?.column?.columnDef?.canSort ? null : (
                            <>
                              {header.column.getIsSorted() === 'desc' && (
                                <div className="flex flex-col">
                                  <CaretUp
                                    className={
                                      header?.column?.columnDef?.canSort
                                        ? 'h-2 w-2 cursor-pointer'
                                        : 'h-2 w-2'
                                    }
                                    onClick={
                                      header?.column?.columnDef?.canSort
                                        ? header.column.getToggleSortingHandler()
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                              {header.column.getIsSorted() === 'asc' && (
                                <div className="flex flex-col">
                                  <CaretDown
                                    className={
                                      header?.column?.columnDef?.canSort
                                        ? 'h-2 w-2 cursor-pointer'
                                        : 'h-2 w-2'
                                    }
                                    onClick={
                                      header?.column?.columnDef?.canSort
                                        ? header.column.getToggleSortingHandler()
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                              {!header.column.getIsSorted() && (
                                <div className="flex flex-col">
                                  <CaretDown
                                    className={
                                      header?.column?.columnDef?.canSort
                                        ? 'h-2 w-2 cursor-pointer'
                                        : 'h-2 w-2'
                                    }
                                    onClick={
                                      header?.column?.columnDef?.canSort
                                        ? header.column.getToggleSortingHandler()
                                        : undefined
                                    }
                                  />
                                  <CaretUp
                                    className={
                                      header?.column?.columnDef?.canSort
                                        ? 'h-2 w-2 cursor-pointer'
                                        : 'h-2 w-2'
                                    }
                                    onClick={
                                      header?.column?.columnDef?.canSort
                                        ? header.column.getToggleSortingHandler()
                                        : undefined
                                    }
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {!isLoading && ((datas && datas?.items?.length === 0) || datas === false) ? (
                ''
              ) : (
                <tbody>
                  {isLoading || reportLoading
                    ? Array.from({ length: pageSize }).map((_, rowIndex) => (
                        <tr
                          key={rowIndex}
                          className={`bg-thead text-secondary whitespace-nowrap font-light text-sm${
                            rowIndex === pageSize - 1 ? '' : 'border-b border-b-stroke'
                          }`}
                        >
                          {dataColumns.map((column, columnIndex: number) => (
                            <td
                              key={columnIndex}
                              className={clsx(
                                'px-4 py-3.5 whitespace-nowrap max-h-[50px]',
                                column?.header === 'Action'
                                  ? 'overflow-visible'
                                  : 'overflow-hidden',
                              )}
                            >
                              <Skeleton
                                width={'100%'}
                                baseColor="#ffffff30"
                                highlightColor="#ffffff30"
                                style={{
                                  background:
                                    ' linear-gradient(90deg, rgba(153,153,153,0.3) 0%, rgba(153,153,153,0.3) 100%)',
                                  zIndex: 0,
                                }}
                              />
                            </td>
                          ))}
                        </tr>
                      ))
                    : datas?.items?.length !== 0 &&
                      table.getRowModel().rows.map((row, index) =>
                        index === table.getRowModel().rows?.length - 1 ? (
                          <tr
                            key={row.id}
                            className={`bg-thead text-secondary whitespace-nowrap font-light text-sm ${
                              addTitle === 'MappedUser' ? 'cursor-auto' : 'cursor-pointer'
                            }  ${
                              index === table.getRowModel().rows?.length - 1
                                ? ''
                                : 'border-b border-b-stroke'
                            }`}
                            onClick={() => {
                              if (payNow === true) {
                                return;
                              }
                              if (addTitle === 'MappedUser') {
                                return;
                              }
                              localStorage.setItem('userId', JSON.stringify(row.original));
                              localStorage.setItem('returnPath', JSON.stringify(returnpath));
                              if (RowPath === '/orders/customer') {
                                localStorage.setItem('month', JSON.stringify(month));
                                localStorage.setItem('year', JSON.stringify(year));
                              }
                              navigate(RowPath ?? '', {
                                state: row?.original?.status,
                              });
                            }}
                          >
                            {row.getVisibleCells().map((cell, index, array) => (
                              <td
                                key={cell.id}
                                style={{
                                  maxWidth: cell?.column?.columnDef?.isEllipsis ? '300px' : 'none',

                                  overflow: cell?.column?.columnDef?.isEllipsis
                                    ? 'hidden'
                                    : cell?.column?.id === 'status'
                                      ? 'visible'
                                      : 'hidden',
                                  textOverflow: cell?.column?.columnDef?.isEllipsis
                                    ? 'ellipsis'
                                    : 'clip',
                                  whiteSpace: 'nowrap',
                                  maxHeight: cell?.column?.id === 'status' ? 50 : 'none',
                                }}
                                className={`px-4 py-3.5 ${
                                  index === 0
                                    ? 'rounded-bl-lg'
                                    : index === array?.length - 1
                                      ? 'rounded-br-lg'
                                      : ''
                                }`}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ))}
                          </tr>
                        ) : (
                          <tr
                            key={row.id}
                            className={`bg-thead text-secondary whitespace-nowrap font-light text-sm ${
                              addTitle === 'MappedUser' ? '' : 'cursor-pointer'
                            } ${
                              index === table.getRowModel().rows?.length - 1
                                ? 'rounded-b-lg'
                                : 'border-b border-b-stroke'
                            }`}
                            onClick={() => {
                              if (addTitle === 'MappedUser') {
                                return;
                              }
                              localStorage.setItem('userId', JSON.stringify(row.original));
                              if (RowPath === '/orders/customer') {
                                localStorage.setItem('month', JSON.stringify(month));
                                localStorage.setItem('year', JSON.stringify(year));
                              }
                              localStorage.setItem('returnPath', JSON.stringify(returnpath));
                              navigate(RowPath ?? '', {
                                state: row?.original?.status,
                              });
                            }}
                          >
                            {row.getVisibleCells().map((cell, index) => (
                              <>
                                <td
                                  key={cell.id}
                                  style={{
                                    maxWidth: cell?.column?.columnDef?.isEllipsis
                                      ? '300px'
                                      : 'none',

                                    overflow: cell?.column?.columnDef?.isEllipsis
                                      ? 'hidden'
                                      : cell?.column?.id === 'status'
                                        ? 'visible'
                                        : 'hidden',
                                    textOverflow: cell?.column?.columnDef?.isEllipsis
                                      ? 'ellipsis'
                                      : 'clip',
                                    whiteSpace: 'nowrap',
                                    maxHeight: cell?.column?.id === 'status' ? 50 : 'none',
                                  }}
                                  className={'px-4 py-3.5'}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              </>
                            ))}
                          </tr>
                        ),
                      )}
                </tbody>
              )}
            </table>
          )}
        </div>
      </>
    );
  };
  const numPages = Math.ceil(totalDBRowCount / pageSize);

  return (
    <>
      {canAdd ? (
        <div className="flex md:flex-row flex-col justify-between mt-2 md:items-center">
          <form>
            {canSearch && (
              <div className="relative flex items-center lg:mb-0 mb-3">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <Icon icon="ic:outline-search" color="#7D7D7D" width="16" height="16" />
                </div>

                <DebouncedInput
                  type="search"
                  id="default-search"
                  className="block px-4 py-3 pl-8 text-sm bg-white text-gray-900 border border-gray-100 rounded-lg lg:w-[261px] w-full h-[45px]"
                  placeholder={isFocused ? '' : 'Search'}
                  value={searchText}
                  onChange={(value) => {
                    setPage(1);
                    setSearchText(value);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              </div>
            )}
          </form>
          <div className="flex gap-4 md:mt-0 mt-4 cursor-pointer">
            {canAdd && (
              <button
                className="bg-white text-primary w-[95px] h-[42px] px-[10px] py-[10px] rounded-lg flex items-center justify-center gap-2 font-medium"
                onClick={() => navigate(AddPath ?? '')}
              >
                Add
                <Icon icon="ic:round-add" className="text-primary" width="20" height="20" />
              </button>
            )}
            {canInvite && user?.userType === ADMIN && (
              <button
                className="bg-white text-primary w-[95px] h-[42px] px-[10px] py-[10px] rounded-lg flex items-center justify-center gap-2 font-medium"
                onClick={() =>
                  navigate(InvitePath ?? '', {
                    state: {
                      label: 'Invite Distributor',
                      returnInvitePath: '/distributors/list',
                    },
                  })
                }
              >
                Invite
                <Icon icon="mdi:invite" className="text-primary" width="16" height="16" />
              </button>
            )}
          </div>
        </div>
      ) : canAdd && canInvite ? (
        <div className="flex md:flex-row flex-col justify-between mt-2 md:items-center">
          <form>
            {canSearch && (
              <div className="relative flex items-center lg:mb-0 mb-3">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <Icon icon="ic:outline-search" color="#7D7D7D" width="16" height="16" />
                </div>

                <DebouncedInput
                  type="search"
                  id="default-search"
                  className="block px-4 py-3 pl-8 text-sm bg-white text-gray-900 border border-gray-100 rounded-lg lg:w-[261px] w-full h-[45px]"
                  placeholder={isFocused ? '' : 'Search'}
                  value={searchText}
                  onChange={(value) => {
                    setPage(1);
                    setSearchText(value);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              </div>
            )}
          </form>
          <div className="flex gap-4 md:mt-0 mt-4 cursor-pointer">
            {canAdd && user?.userType === ADMIN && (
              <button
                className="bg-white text-primary w-[95px] h-[42px] px-[10px] py-[10px] rounded-lg flex items-center justify-center gap-2 font-medium"
                onClick={() => navigate(AddPath ?? '')}
              >
                Add
                <Icon icon="ic:round-add" className="text-primary" width="20" height="20" />
              </button>
            )}
            {canInvite && user?.userType === ADMIN && (
              <button
                className="bg-white text-primary w-[95px] h-[42px] px-[10px] py-[10px] rounded-lg flex items-center justify-center gap-2 font-medium"
                onClick={() =>
                  navigate(InvitePath ?? '', {
                    state: {
                      label: 'Invite Distributor',
                      returnInvitePath: '/distributors/list',
                    },
                  })
                }
              >
                Invite
                <Icon icon="mdi:invite" className="text-primary" width="16" height="16" />
              </button>
            )}
          </div>
        </div>
      ) : canHeader ? (
        <>
          <div className="flex md:flex-row flex-col justify-between mt-2 md:items-center">
            {path === '/markup' ? (
              <p></p>
            ) : (
              <button className="bg-white text-primary h-[48px] px-[12px] py-[12px] rounded-lg flex items-center justify-center gap-2 font-medium dropshadow-xl cursor-auto">
                {tableTitle}
              </button>
            )}

            <div className="md:flex gap-4 md:mt-0 mt-4 cursor-pointer">
              <div className="relative flex items-center lg:mb-0 mb-3">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <Icon icon="ic:outline-search" color="#7D7D7D" width="16" height="16" />
                </div>

                <DebouncedInput
                  type="search"
                  id="default-search"
                  className="block px-4 py-3 pl-8 text-sm bg-white text-gray-900 border border-gray-100 rounded-lg lg:w-[261px] w-full h-[45px]"
                  placeholder={isFocused ? '' : 'Search'}
                  value={searchText}
                  onChange={(value) => {
                    setPage(1);
                    setSearchText(value);
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              </div>
              {downloadCsv && (
                <>
                  <div
                    className={`flex items-center px-4 py-3 text-sm text-primary border border-primary rounded-lg w-[161px] h-[45px] font-medium ${
                      !datas ? 'disabled' : ''
                    }`}
                    onClick={datas?.items?.length > 0 ? handleDownloadClick : undefined}
                  >
                    <div className="flex items-center pointer-events-none">
                      {isLoadingCsv ? (
                        <>
                          <TailSpin
                            visible={true}
                            height="16"
                            width="16"
                            color={'#B2003699'}
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                          &ensp;
                        </>
                      ) : (
                        <Icon
                          icon="bi:download"
                          className="mr-2"
                          color={'#B2003699'}
                          width="16"
                          height="16"
                        />
                      )}
                    </div>
                    <a>{'Download CSV'}</a>
                  </div>
                </>
              )}
            </div>
          </div>
          {user?.userType === ADMIN && revenueReport ? (
            <div className="flex md:flex-row flex-col justify-between mt-5 md:items-center">
              <button className="bg-white text-primary h-[42px] px-[10px] py-[10px] rounded-lg flex items-center justify-center gap-2 font-semibold">
                <span className="text-secondary font-normal">Total Commission:</span>{' '}
                {datas?.totalCommission}
              </button>
            </div>
          ) : (
            ''
          )}
        </>
      ) : canConnect && title !== 'Connect' ? (
        <div className="flex md:flex-row flex-col justify-between mt-2 md:items-center">
          <button className="bg-white text-primary h-[48px] px-[12px] py-[12px] rounded-lg flex items-center justify-center gap-2 font-medium dropshadow-xl cursor-auto">
            {'List of Distributors'}
          </button>
          <div className="flex gap-4 md:mt-0 mt-4 cursor-pointer">
            {canConnect && (
              <button
                className="bg-white text-primary w-[95px] h-[42px] px-[10px] py-[10px] rounded-lg flex items-center justify-center gap-2 font-medium"
                onClick={() => navigate(ConnectPath ?? '')}
              >
                Connect
              </button>
            )}
          </div>
        </div>
      ) : (
        title && (
          <div className="flex md:flex-row flex-col justify-between mt-2 md:items-center">
            <CommonButton
              label={title}
              className="px-[10px] py-[15px]  bg-white text-base tracking-[-0.16px] text-primary flex md:justify-between items-center gap-3"
              icon={<Icon icon="ic:round-arrow-back" color="#B20036" width="20" height="20" />}
              onClick={() => navigate('/distributors/list')}
            />
            <div className="md:flex gap-4 md:mt-0 mt-4 cursor-pointer">
              {canSearch && (
                <div className="relative flex items-center lg:mb-0 mb-3">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <Icon icon="ic:outline-search" color="#7D7D7D" width="16" height="16" />
                  </div>

                  <DebouncedInput
                    type="search"
                    id="default-search"
                    className="block px-4 py-3 pl-8 text-sm text-white border border-stroke rounded-lg w-[261px] h-[45px] bg-transparent"
                    placeholder={isFocused ? '' : 'Search'}
                    value={searchText}
                    onChange={(value) => {
                      setPage(1);
                      setSearchText(value);
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </div>
              )}
              {canConnect && (
                <>
                  <div className="relative flex items-center lg:mb-0 mb-3">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <Icon icon="ic:outline-search" color="#7D7D7D" width="16" height="16" />
                    </div>

                    <DebouncedInput
                      type="search"
                      id="default-search"
                      className="block px-4 py-3 pl-8 text-sm bg-white text-gray-900 border border-gray-100 rounded-lg lg:w-[261px] w-full h-[45px]"
                      placeholder={isFocused ? '' : 'Search'}
                      value={connectSearchText}
                      onChange={(value) => {
                        setPage(1);
                        setConnectSearchText(value);
                      }}
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                    />
                  </div>
                  <button
                    className="bg-white text-primary w-[95px] h-[42px] px-[10px] py-[10px] rounded-lg flex items-center justify-center gap-2 font-medium"
                    onClick={() => setSearchText(connectSearchText)}
                  >
                    Search
                  </button>
                </>
              )}
            </div>
          </div>
        )
      )}
      {selectFilter && (
        <FilterDropdown
          monthOption={monthOption}
          customStyles={customStyles}
          handleMonthChange={handleMonthChange}
          handleYearChange={handleYearChange}
          handleOption={handleOption}
          distributorhandleOption={distributorhandleOption}
          retailerhandleOption={retailerhandleOption}
          selectedUserId={selectedUserId}
          selectedMonths={selectedMonths}
          selectedYear={selectedYear}
        />
      )}
      {platformFilter && (
        <button className="text-white bg-primary h-[42px] px-[10px] py-[10px] rounded-lg flex items-center justify-center gap-2 mt-4 cursor-auto">
          Shopify
          <span className="bg-white text-primary text-sm h-8 w-8 rounded-full flex justify-center items-center">
            {datas?.totalCount}
          </span>
        </button>
      )}
      <div className="relative h-full">
        {title === 'Connect' && searchText === '' ? (
          <div className="col-span-12 mt-6">
            <div className="text-sm mx-auto my-4 font-light bg-thead whitespace-nowrap p-12 rounded-xl text-center w-full sm:w-8/12">
              <p className="text-secondary text-wrap">
                Use the search function to locate and connect your preferred distributors.
              </p>
            </div>
          </div>
        ) : (
          <div className="col-span-12 mt-6">
            {BTableFun()}
            {payment ? (
              <></>
            ) : title === 'Connect' && searchText === '' ? (
              ''
            ) : (
              !isLoading &&
              datas?.items?.length > 0 && (
                <div className="mt-6 flex md:flex-row flex-col justify-between md:items-center bg-white px-[16px] py-[12px] rounded-xl">
                  <p className="text-sm font-normal text-secondary flex items-center gap-3">
                    Showing {page} to {flatData.length} of {totalDBRowCount} entries
                    <div className="relative" ref={dropdownRef}>
                      <div className="border border-[#E4E6EA] w-[59px] h-[26px] rounded p-1 bg-white flex items-center justify-between">
                        <div className="flex justify-between items-center w-full">
                          <p className="text-sm text-secondary font-medium">{pageSize}</p>
                          <div
                            className="bg-primary flex items-center justify-center ml-4 cursor-pointer rounded"
                            onClick={() => {
                              const dropdown = document.querySelector('.dropdown-content');
                              if (dropdown) {
                                dropdown.style.display =
                                  dropdown.style.display === 'block' ? 'none' : 'block';
                              }
                            }}
                          >
                            <Icon
                              icon="prime:chevron-down"
                              className="text-white"
                              width="20"
                              height="20"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="absolute bottom-full left-0 mt-1 bg-white border border-gray-300 rounded-sm p-1 dropdown-content max-h-[200px] overflow-y-auto"
                        style={{
                          display: 'none',
                        }}
                      >
                        <ul>
                          {[10, 20, 30, 40, 50].map((size) => (
                            <li
                              key={size}
                              className="py-2 px-4 cursor-pointer text-black hover:bg-light-gold" // Updated hover color to light gold
                              onClick={() => {
                                handlePageSizeChange(size);
                                const dropdown = document.querySelector('.dropdown-content');
                                if (dropdown) {
                                  dropdown.style.display = 'none';
                                }
                                setPage(1);
                              }}
                            >
                              {size}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </p>
                  <div>
                    <nav
                      className="inline-flex md:mt-0 mt-4 rounded-md space-x-2 w-full justify-center items-center"
                      aria-label="Pagination"
                    >
                      <button
                        className="relative inline-flex justify-center items-center sm:w-8 sm:h-8 w-6 h-6 text-sm font-light text-white"
                        onClick={() => {
                          if (page > 1 && page !== 1) {
                            setPage(page - 1);
                          }
                        }}
                        disabled={page === 1}
                      >
                        <Icon
                          icon="zondicons:cheveron-left"
                          className="text-secondary"
                          width="16"
                          height="16"
                        />
                      </button>
                      {/* {numPages &&
                        Array.from({
                          length: Math.min(datas?.totalCount, numPages),
                        }).map((_, index) => {
                          const pageNumber = index + 1
                          const isFirstPage = pageNumber === 1
                          const isLastPage = pageNumber === numPages
                          const isWithinRange =
                            isFirstPage ||
                            isLastPage ||
                            Math.abs(pageNumber - page) <= 2 ||
                            (page <= 3 && pageNumber <= 5) ||
                            (page >= numPages - 2 && pageNumber > numPages - 5)(
                              page >= numPages - 2 && pageNumber > numPages - 5,
                            )

                          if (isFirstPage || isLastPage || isWithinRange) {
                            return (
                              <button
                                key={index}
                                onClick={() => {
                                  table.setPageIndex(index)
                                  setPage(pageNumber)
                                }}
                                className={`inline-flex justify-center items-center sm:w-8 sm:h-8 w-6 h-6 text-sm ${
                                  page === pageNumber
                                    ? 'font-semibold text-white activePage rounded'
                                    : 'font-light text-secondary'
                                }`}
                                disabled={table.state?.page === index}
                              >
                                {pageNumber}
                              </button>
                            )
                          } else if (pageNumber === 2 && page > 4) {
                            // Display ellipsis after page 1 if page number is more than 5
                            return (
                              <span
                                key={`dots-${pageNumber}`}
                                className="inline-flex items-center sm:px-4 px-2 py-2 text-sm font-light text-secondary"
                              >
                                ...
                              </span>
                            )
                          } else if (
                            pageNumber === page + 3 &&
                            page > 3 &&
                            page < numPages - 2
                          ) {
                            // Display ellipsis after page 4 or after page 5
                            return (
                              <span
                                key={`dots-${pageNumber}`}
                                className="inline-flex items-center sm:px-4 px-2 py-2 text-sm font-light text-secondary"
                              >
                                ...
                              </span>
                            )
                          }
                          return null
                        })} */}
                      {numPages &&
                        Array.from({
                          length: Math.min(datas?.totalCount, numPages),
                        }).map((_, index) => {
                          const pageNumber = index + 1;
                          const isFirstPage = pageNumber === 1;
                          const isLastPage = pageNumber === numPages;
                          const isWithinRange =
                            isFirstPage ||
                            isLastPage ||
                            Math.abs(pageNumber - page) <= 2 ||
                            (page <= 3 && pageNumber <= 5) ||
                            (page >= numPages - 2 && pageNumber > numPages - 5);

                          if (isFirstPage || isLastPage || isWithinRange) {
                            return (
                              <button
                                key={index}
                                onClick={() => {
                                  table.setPageIndex(index);
                                  setPage(pageNumber);
                                }}
                                className={`inline-flex justify-center items-center sm:w-8 sm:h-8 w-6 h-6 text-sm ${
                                  page === pageNumber
                                    ? 'font-semibold text-white activePage rounded'
                                    : 'font-light text-secondary'
                                }`}
                                disabled={table.state?.page === index}
                              >
                                {pageNumber}
                              </button>
                            );
                          } else if (pageNumber === 2 && page > 4) {
                            // Display ellipsis after page 1 if page number is more than 5
                            return (
                              <span
                                key={`dots-${pageNumber}`}
                                className="inline-flex items-center sm:px-4 px-2 py-2 text-sm font-light text-secondary"
                              >
                                ...
                              </span>
                            );
                          } else if (pageNumber === page + 3 && page > 3 && page < numPages - 2) {
                            // Display ellipsis after page 4 or after page 5
                            return (
                              <span
                                key={`dots-${pageNumber}`}
                                className="inline-flex items-center sm:px-4 px-2 py-2 text-sm font-light text-secondary"
                              >
                                ...
                              </span>
                            );
                          }
                          return null;
                        })}

                      <button
                        className="relative inline-flex justify-center items-center sm:w-8 sm:h-8 w-6 h-6 text-sm font-light text-secondary"
                        onClick={() => {
                          if (page !== numPages) setPage(page + 1);
                        }}
                        disabled={page === numPages}
                      >
                        <Icon
                          icon="zondicons:cheveron-right"
                          className="text-secondary"
                          width="16"
                          height="16"
                        />
                      </button>
                    </nav>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default LegacyTable;
