import { Link } from 'react-router-dom';
import { SideNavEntry } from '../../../types';
import clsx from 'clsx';
import { IconChevronRight } from '../../../assets/icons';

type SidebarLinkProps = {
  activeTabId?: number;
  item: SideNavEntry;
};

export const SidebarLink = ({ activeTabId, item }: SidebarLinkProps) => {
  const isActiveTab = activeTabId && activeTabId === item.id;
  const isActiveSubTab =
    !isActiveTab &&
    activeTabId &&
    item.children?.length &&
    !!item.children?.find((subItem) => {
      return subItem.id === activeTabId;
    });

  return (
    <div>
      <Link
        to={item.children && item.children.length > 0 ? item.children[0].url : item.url}
        className={clsx(
          'flex items-center p-3 rounded-2.5 text-subtitle-sm font-subtitle cursor-pointer transition-all',
          {
            'text-white bg-primary-brand hover:bg-primary-brand-dark':
              isActiveTab || isActiveSubTab,
            'text-grey-1 hover:text-primary-brand-dark': !(isActiveTab || isActiveSubTab),
          },
        )}
      >
        {item.icon}
        <span className="flex-1 ml-3 whitespace-nowrap">{item.label}</span>
        {item?.children && <IconChevronRight />}
      </Link>
      {item?.children && (isActiveTab || isActiveSubTab) && (
        <div className="pl-8 my-2 bg-transparent">
          {item.children.map((submenuItem: SideNavEntry, index) => {
            const isActive = isActiveSubTab ? activeTabId === submenuItem.id : index === 0;

            return (
              <Link
                to={submenuItem.url}
                key={submenuItem.id}
                className={clsx(
                  'flex cursor-pointer whitespace-nowrap items-center pl-4 p-3 mb-2 transition-all hover:text-primary-brand-dark',
                  {
                    'text-primary-brand font-subtitle': isActive,
                    'text-grey-1 font-body': !isActive,
                  },
                )}
              >
                {submenuItem.label}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
