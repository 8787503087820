import React, { useEffect } from 'react';
import Select, { CSSObjectWithLabel, GroupBase, SingleValue, StylesConfig } from 'react-select';
import {
  useGetDistributorMutation,
  useGetOrderSummaryMutation,
  useGetRetailerMutation,
} from '../redux/api/reportApi/reportApi';
import { useGetAuthUser } from '../hooks';
import { ADMIN, DISTRIBUTOR, RETAILER } from '../constants';
import { useGetMappedDistributorsMutation } from '../redux/api/mappedDistributor/mappedDistributor';
import { useLocation } from 'react-router-dom';
import { CustomStylesDataType, OptionType } from '../types';
import { DistributorType } from '../redux/api/distributerApi/types';
import { RetailerType } from '../redux/api/retailerApi/types';

type LabeledSelectProps = {
  customStyles: StylesConfig<unknown, false, GroupBase<unknown>> | undefined;
  distributorhandleOption?: (selectedOption: OptionType) => void;
  retailerhandleOption?: (selectedOption: OptionType) => void;
  selectedUserId?: string | number;
  selectedMonths?: number;
  selectedYear?: string | number;
  handleMonthChange?: (selectedOption: SingleValue<OptionType>) => void;
  handleYearChange?: (selectedOption: SingleValue<OptionType>) => void;
};

const FilterDropdown: React.FC<LabeledSelectProps> = ({
  customStyles,
  distributorhandleOption,
  retailerhandleOption,
  selectedMonths,
  selectedYear,
  handleMonthChange,
  handleYearChange,
}) => {
  const { user } = useGetAuthUser();
  const retailerMutation = useGetRetailerMutation();
  const orderSummary = useGetOrderSummaryMutation();
  const mappedDataMutation = useGetMappedDistributorsMutation();
  const distributorMutation = useGetDistributorMutation();
  const currentDate = new Date();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const years = Array.from({ length: 2 }, (_, index) => currentDate.getFullYear() - index);
  const location = useLocation();
  const [getDistributor, { data }] =
    user?.userType === ADMIN
      ? distributorMutation
      : DISTRIBUTOR
        ? orderSummary
        : mappedDataMutation;
  const [getRetailer, { data: datas }] =
    user?.userType === ADMIN ? retailerMutation : DISTRIBUTOR ? orderSummary : mappedDataMutation;

  useEffect(() => {
    if (user?.userType === ADMIN) {
      getDistributor({});
      getRetailer({});
    } else if (user?.userType === DISTRIBUTOR) {
      getRetailer({});
    } else if (user?.userType === RETAILER) {
      getDistributor({});
    }
  }, [getDistributor, getRetailer]);

  const distributorSelectOptions =
    user?.userType === ADMIN
      ? (data?.data || []).map((distributor: DistributorType) => ({
          value: distributor.userId,
          label: distributor.companyName,
        })) || [{ value: null, label: 'No data', isDisabled: true }]
      : (datas?.data || []).map((distributor: DistributorType) => ({
          value: distributor.userId,
          label: distributor.companyName,
        })) || [{ value: null, label: 'No data', isDisabled: true }];

  const retailerSelectOptions =
    user?.userType === ADMIN
      ? (datas?.data || [])?.map((retailer: RetailerType) => ({
          value: retailer.userId,
          label: retailer.companyName,
        })) || [{ value: null, label: 'No data', isDisabled: true }]
      : user?.userType === DISTRIBUTOR
        ? (datas?.data || [])?.map((retailer: RetailerType) => ({
            value: retailer.userId,
            label: retailer.companyName,
          })) || [{ value: null, label: 'No data', isDisabled: true }]
        : datas?.data?.items1?.map((retailer: RetailerType) => ({
            value: retailer.retailerId,
            label: retailer.companyName,
          })) || [{ value: null, label: 'No data', isDisabled: true }];

  const customNoOptionsMessage = (value?: { inputValue: string }) =>
    value ? 'No Data' : 'Start typing to search data';

  const monthOptions = months.map((month, index) => ({
    value: index + 1,
    label: month,
  }));
  const yearOptions = years.map((year) => ({
    value: year,
    label: year.toString(),
  }));

  const customStyle = {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      padding: '4px',
      // width: "170px",
      borderColor: 'rgb(206 212 218/1)',
      cursor: 'pointer',
    }),
    singleValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#494949',
    }),
    indicatorSeparator: (provided: CSSObjectWithLabel) => ({
      ...provided,
      display: 'none',
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: '#494949',
    }),
    option: (
      styles: CSSObjectWithLabel,
      { isDisabled, isFocused, isSelected }: CustomStylesDataType,
    ) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? '#b20036'
          : isFocused
            ? '#b20036'
            : undefined,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? 'white' : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? '#b20036' : '') : undefined,
      },
    }),
  };

  const handleDistributorChange = (newValue: unknown): void => {
    distributorhandleOption?.(newValue as OptionType);
  };

  const handleRetailerSelectChange = (newValue: unknown): void => {
    retailerhandleOption?.(newValue as OptionType);
  };

  return (
    <div className="flex md:flex-row flex-col mt-6 md:items-center flex-wrap gap-3">
      <div className="flex md:flex-row flex-col ml-[1px] lg:mr-2">
        <label className="block text-lg text-secondary me-2 mt-2 font-medium">Month</label>

        <Select
          className="md:w-[170px] w-full"
          value={
            selectedMonths ? { value: selectedMonths, label: months[selectedMonths - 1] } : null
          }
          styles={customStyle}
          onChange={handleMonthChange}
          options={monthOptions}
          placeholder="Select Month"
        />
        <label className="block text-lg text-secondary ms-2 me-2 mt-2 font-medium">Year</label>
        <Select
          className="md:w-[170px] w-full"
          value={selectedYear ? { value: selectedYear, label: selectedYear.toString() } : null}
          styles={customStyle}
          onChange={handleYearChange}
          isClearable={true}
          options={yearOptions}
          placeholder="Select Year"
        />
      </div>

      {user?.userType === ADMIN &&
        location?.pathname !== '/retailers/platformfee' &&
        location?.pathname !== '/distributors/platformfee' && (
          <>
            <div className="flex md:flex-row flex-col lg:mr-2">
              <label className="block text-lg text-secondary mt-2 me-2 font-medium">
                Distributor
              </label>
              <Select
                className="md:w-[270px] w-full"
                options={distributorSelectOptions}
                onChange={handleDistributorChange}
                styles={customStyles}
                placeholder="Select Distributor"
                isClearable={true}
                noOptionsMessage={customNoOptionsMessage}
              />
            </div>
            <div className="flex md:flex-row flex-col lg:mr-2">
              <label className="block text-lg text-secondary me-2 mt-2 font-medium">Retailer</label>
              <Select
                className="md:w-[270px] w-full"
                options={retailerSelectOptions}
                onChange={handleRetailerSelectChange}
                styles={customStyles}
                placeholder="Select Retailer"
                isClearable={true}
                noOptionsMessage={customNoOptionsMessage}
              />
            </div>
          </>
        )}
      {user?.userType === DISTRIBUTOR &&
        location?.pathname !== '/reports/purchased-products' &&
        location?.pathname !== '/reports/retailer-report' && (
          <div className="flex flex-row lg:mr-2  md:mt-0 mt-4">
            <label className="block text-lg text-secondary me-2 mt-2 font-medium">Retailer</label>
            <Select
              options={retailerSelectOptions}
              onChange={handleRetailerSelectChange}
              styles={customStyles}
              placeholder="Select Retailer"
              isClearable={true}
              noOptionsMessage={customNoOptionsMessage}
            />
          </div>
        )}
      {user?.userType === RETAILER &&
        location?.pathname !== '/reports/purchased-products' &&
        location?.pathname !== '/reports/distributor-report' && (
          <div className="flex flex-row lg:mr-2  md:mt-0 mt-4">
            <label className="block text-lg mt-2 text-secondary me-2">Distributor</label>
            <Select
              options={distributorSelectOptions}
              onChange={handleDistributorChange}
              styles={customStyles}
              placeholder="Select Distributor"
              isClearable={true}
              noOptionsMessage={customNoOptionsMessage}
            />
          </div>
        )}
    </div>
  );
};

export default FilterDropdown;
