import { FocusEventHandler, useState } from 'react';
import {
  default as ReactSelect,
  InputAction,
  CSSObjectWithLabel,
  MenuPlacement,
} from 'react-select';
import { OptionType } from '../../types';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import { Option } from './components/Option';

const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderRadius: '0.5rem',
    padding: '4px',
    borderColor: 'rgb(206 212 218/1)',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#494949',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#494949',
    fontSize: '14px',
  }),
};

type MultiSelectProps = {
  components?: unknown[];
  touched?: boolean | FormikTouched<OptionType>[];
  name?: string;
  error?: string | string[] | FormikErrors<OptionType>[];
  options?: OptionType[];
  onChange: (selected: OptionType[]) => void;
  value?: string | string[] | OptionType | OptionType[];
  isSelectAll: boolean;
  menuPlacement?: MenuPlacement;
  props?: FormikValues;
  placeholder: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

const MultiSelect = (props: MultiSelectProps) => {
  const [selectInput, setSelectInput] = useState<string>('');

  const customFilterOption = ({ label }: OptionType, input: string) =>
    label.toLowerCase().includes(input.toLowerCase());
  const onInputChange = (inputValue: string, event: { action: InputAction }) => {
    if (event.action === 'input-change') setSelectInput(inputValue);
    else if (event.action === 'menu-close' && selectInput !== '') setSelectInput('');
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if ((e.key === ' ' || e.key === 'Enter') && !selectInput) e.preventDefault();
  };

  return (
    <>
      <ReactSelect
        {...props}
        onChange={(v) => props.onChange?.(v as OptionType[])}
        inputValue={selectInput}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        filterOption={customFilterOption}
        components={{
          Option: Option,
          ...props.components,
        }}
        name={props.name}
        isMulti
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        styles={customStyles}
      />
      {props?.error && props?.touched && (
        <>
          <p className="error text-sm mt-2">{props?.error as string}</p>
        </>
      )}
    </>
  );
};

export default MultiSelect;
