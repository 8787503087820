import type { IconProps } from '../types';

export const IconTruck = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17.25H7.18925C7.54346 18.2966 8.53374 19.05 9.69999 19.05C10.8662 19.05 11.8565 18.2966 12.2107 17.25H16.3893C16.7435 18.2966 17.7338 19.05 18.9 19.05C20.0775 19.05 21.0757 18.282 21.4208 17.2196C22.9794 16.9917 24.0966 15.5174 23.8288 13.9107L23.0293 9.114C22.802 7.74984 21.6217 6.75 20.2387 6.75H17.25V6.5C17.25 5.5335 16.4665 4.75 15.5 4.75H7C6.0335 4.75 5.25 5.5335 5.25 6.5V15.5C5.25 16.4665 6.0335 17.25 7 17.25ZM7 6.25C6.86193 6.25 6.75 6.36193 6.75 6.5V15.5C6.75 15.6381 6.86193 15.75 7 15.75H7.13029C7.4201 14.6007 8.4607 13.75 9.69999 13.75C10.9393 13.75 11.9799 14.6007 12.2697 15.75H15.75V6.5C15.75 6.36193 15.6381 6.25 15.5 6.25H7ZM21.4495 15.6747C22.0585 15.4633 22.4622 14.835 22.3492 14.1573L22.0313 12.25H17.25V14.3262C17.7027 13.9655 18.2762 13.75 18.9 13.75C20.1121 13.75 21.1341 14.5638 21.4495 15.6747ZM17.25 10.75H21.7813L21.5498 9.3606C21.4429 8.71972 20.8885 8.25 20.2387 8.25H17.25V10.75ZM9.69999 15.25C9.06486 15.25 8.54999 15.7649 8.54999 16.4C8.54999 17.0351 9.06486 17.55 9.69999 17.55C10.3351 17.55 10.85 17.0351 10.85 16.4C10.85 15.7649 10.3351 15.25 9.69999 15.25ZM17.75 16.4C17.75 15.7649 18.2649 15.25 18.9 15.25C19.5351 15.25 20.05 15.7649 20.05 16.4C20.05 17.0351 19.5351 17.55 18.9 17.55C18.2649 17.55 17.75 17.0351 17.75 16.4Z"
        fill="currentColor"
      />
      <path
        d="M1 6.75C0.585786 6.75 0.25 7.08579 0.25 7.5C0.25 7.91421 0.585786 8.25 1 8.25H3.5C3.91421 8.25 4.25 7.91421 4.25 7.5C4.25 7.08579 3.91421 6.75 3.5 6.75H1Z"
        fill="currentColor"
      />
      <path
        d="M2 9.45001C1.58579 9.45001 1.25 9.7858 1.25 10.2C1.25 10.6142 1.58579 10.95 2 10.95H3.5C3.91421 10.95 4.25 10.6142 4.25 10.2C4.25 9.7858 3.91421 9.45001 3.5 9.45001H2Z"
        fill="currentColor"
      />
      <path
        d="M1.75 13C1.75 12.5858 2.08579 12.25 2.5 12.25H3.5C3.91421 12.25 4.25 12.5858 4.25 13C4.25 13.4142 3.91421 13.75 3.5 13.75H2.5C2.08579 13.75 1.75 13.4142 1.75 13Z"
        fill="currentColor"
      />
    </svg>
  );
};
