import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { RetryFailedPaymentsRequestBody, RetryFailedPaymentsRequestData } from './types';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getPayment: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: '/api/getPaymentLink',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getFailedPayment: builder.mutation<
      ApiResponse<RetryFailedPaymentsRequestData>,
      RetryFailedPaymentsRequestBody
    >({
      query: (body) => ({
        url: '/api/retryFailedPayments',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetPaymentMutation, useGetFailedPaymentMutation } = paymentApi;
