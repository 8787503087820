import React, { useEffect, useState } from 'react';
import { useLoginUserMutation } from '../../redux/api/authApi/authApi';
import Distributer from './Distrubuter';
import Retailer from './Retailer';
import Admin from './Admin';
import { LoadingScreen } from '../../components';
import { useIdToken } from '../../hooks';
import { toast } from 'react-toastify';
import { ADMIN, DISTRIBUTOR, RETAILER } from '../../constants';
import { SidebarProps } from '../../types';
import { FetchQueryError } from '../../redux/api/types';

const Dashboard: React.FC<SidebarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [loginUser, { data: loginData, isError, isLoading, error }] = useLoginUserMutation();
  const [loading, setLoading] = useState<boolean>(true);
  const { idToken } = useIdToken();

  useEffect(() => {
    const fetchData = async () => {
      if (idToken) {
        await loginUser();
      }
      setLoading(false);
    };

    fetchData();
  }, [idToken]);

  useEffect(() => {
    if (isError) {
      toast.error((error as FetchQueryError)?.data?.message);
    }
  }, [isLoading]);

  useEffect(() => {
    localStorage.setItem('TYPE', loginData?.data?.userType || '');
  }, [loginData]);
  if (loading) {
    return <LoadingScreen />;
  }

  if (loginData && loginData?.data?.userType === DISTRIBUTOR) {
    return <Distributer setIsSidebarOpen={setIsSidebarOpen} isSidebarOpen={isSidebarOpen} />;
  }

  if (loginData && loginData?.data?.userType === RETAILER) {
    return <Retailer setIsSidebarOpen={setIsSidebarOpen} isSidebarOpen={isSidebarOpen} />;
  }

  if (loginData && loginData?.data?.userType === ADMIN) {
    return <Admin setIsSidebarOpen={setIsSidebarOpen} isSidebarOpen={isSidebarOpen} />;
  }

  return <p>User type not recognized.</p>;
};

export default Dashboard;
