import type { IconProps } from '../types';

export const IconProduct = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 2C9.75 1.58579 10.0858 1.25 10.5 1.25H13.5C13.9142 1.25 14.25 1.58579 14.25 2V9C14.25 9.16228 14.3026 9.32018 14.4 9.45L16.2 11.85C16.8816 12.7588 17.25 13.8641 17.25 15V21C17.25 21.4641 17.0656 21.9092 16.7374 22.2374C16.4092 22.5656 15.9641 22.75 15.5 22.75H8.5C8.03587 22.75 7.59075 22.5656 7.26256 22.2374C6.93437 21.9092 6.75 21.4641 6.75 21V15C6.75 13.8641 7.11843 12.7588 7.8 11.85L9.6 9.45C9.69737 9.32018 9.75 9.16228 9.75 9V2ZM11.25 4.25H12.75V2.75H11.25V4.25ZM12.75 5.75H11.25V9C11.25 9.48683 11.0921 9.96053 10.8 10.35L9 12.75C8.51317 13.3991 8.25 14.1886 8.25 15V21C8.25 21.0663 8.27634 21.1299 8.32322 21.1768C8.37011 21.2237 8.43369 21.25 8.5 21.25H15.5C15.5663 21.25 15.6299 21.2237 15.6768 21.1768C15.7237 21.1299 15.75 21.0663 15.75 21V15C15.75 14.1886 15.4868 13.3991 15 12.75L13.2 10.35C12.9079 9.96053 12.75 9.48683 12.75 9V5.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
