import { IconProps } from './types';

export const IconCheckboxIndeterminate = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="8"
      height="2"
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.645813 1C0.645813 0.758375 0.841688 0.5625 1.08331 0.5625H6.91665C7.15827 0.5625 7.35415 0.758375 7.35415 1C7.35415 1.24162 7.15827 1.4375 6.91665 1.4375H1.08331C0.841688 1.4375 0.645813 1.24162 0.645813 1Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};
