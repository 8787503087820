import { IconProps } from './types';

export const IconPercentage = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77105 5.88622C3.34337 5.45856 3.21542 4.81538 3.44687 4.25661C3.67831 3.69783 4.22357 3.3335 4.82839 3.3335C5.4332 3.3335 5.97846 3.69783 6.2099 4.25661C6.44135 4.81538 6.3134 5.45856 5.88572 5.88622C5.30176 6.47014 4.35501 6.47014 3.77105 5.88622Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1138 12.2285C9.68615 11.8008 9.5582 11.1577 9.78964 10.5989C10.0211 10.0401 10.5663 9.67578 11.1712 9.67578C11.776 9.67578 12.3212 10.0401 12.5527 10.5989C12.7841 11.1577 12.6562 11.8008 12.2285 12.2285C11.6445 12.8124 10.6978 12.8124 10.1138 12.2285V12.2285Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.2285 3.77148L3.77051 12.2295"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
