import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentUser } from '../redux/features/userSlice';
import { UserStateType } from '../types';

const useGetAuthUser = () => {
  const user = useSelector((state: UserStateType) => currentUser(state));

  return useMemo(() => ({ user }), [user]);
};

export default useGetAuthUser;
