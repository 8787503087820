import { RadioGroup, Radio } from 'react-aria-components';
import type { RadioGroupProps } from 'react-aria-components';
import clsx from 'clsx';

type RadioButtonsGroupProps = {
  id?: string;
  options: { value: string; label: string }[];
  selectedValue: string;
  orientation?: 'horizontal' | 'vertical';
  onChange: (value: string) => void;
  isDisabled?: boolean;
  className?: string;
  labelClassName?: string;
  radioElementClassName?: string;
} & RadioGroupProps;

const createElementStyles = (isSelected: boolean, isDisabled?: boolean) => {
  const baseClasses = 'flex items-start gap-2 bg-white transition-all';
  const stateClasses = clsx({
    'text-grey-1 hover:bg-grey-6 focus:bg-grey-6 active:bg-grey-6': !isSelected && !isDisabled,
    'text-grey-1': isSelected && !isDisabled,
    'text-grey-3': isDisabled,
  });

  return clsx(baseClasses, stateClasses);
};

const createRadioStyles = (isDisabled?: boolean) => {
  const baseClasses = 'mt-[3px] w-[14px] h-[14px] min-w-[14px] rounded-full border transition-all';
  const stateClasses = clsx({
    'border-grey-1': !isDisabled,
    'border-grey-3': isDisabled,
  });

  return clsx(baseClasses, stateClasses);
};

export const RadioButtonsGroup = ({
  id,
  options,
  selectedValue,
  orientation = 'vertical',
  onChange,
  isDisabled,
  className,
  labelClassName,
  radioElementClassName,
  ...props
}: RadioButtonsGroupProps) => {
  return (
    <RadioGroup
      id={id}
      value={selectedValue}
      onChange={onChange}
      orientation={orientation}
      className={clsx(
        orientation === 'horizontal' ? 'flex flex-row gap-4' : 'flex flex-col gap-4',
        className,
      )}
      {...props}
    >
      {options.map((option) => (
        <label
          key={option.value}
          className={clsx(
            createElementStyles(selectedValue === option.value, isDisabled),
            labelClassName,
          )}
        >
          <Radio
            value={option.value}
            isDisabled={isDisabled}
            className={clsx(createRadioStyles(isDisabled), radioElementClassName)}
          >
            {({ isSelected }) => (
              <div className="flex items-center justify-center w-full h-full">
                {isSelected && <div className="w-2 h-2 bg-primary-brand rounded-full" />}
              </div>
            )}
          </Radio>
          <span className={clsx('text-body-md')}>{option.label}</span>
        </label>
      ))}
    </RadioGroup>
  );
};
