import type { IconProps } from '../types';

export const IconDashboard = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.58484 9.25055H9.41522C10.029 9.24635 10.6195 9.48569 11.0572 9.91618C11.4957 10.3475 11.7449 10.9353 11.75 11.5504L11.7501 11.5565L11.75 17.4507C11.745 18.0658 11.4957 18.6536 11.0572 19.0849C10.6195 19.5154 10.029 19.7548 9.41518 19.7505H6.58489C5.30624 19.7594 4.26143 18.7313 4.25006 17.4522L4.25 17.4455L4.25003 11.5504C4.2551 10.9353 4.50433 10.3475 4.94284 9.91618C5.38053 9.48569 5.97103 9.24635 6.58484 9.25055ZM6.57579 10.7505C6.35858 10.7487 6.14954 10.8333 5.99467 10.9856C5.84042 11.1373 5.7525 11.3439 5.75003 11.5601V17.4417C5.75555 17.8925 6.1246 18.2543 6.57579 18.2506L6.58203 18.2505L9.42427 18.2505C9.64148 18.2524 9.85052 18.1678 10.0054 18.0155C10.1596 17.8638 10.2476 17.6572 10.25 17.441V11.5601C10.2476 11.3439 10.1596 11.1373 10.0054 10.9856C9.85052 10.8333 9.64148 10.7487 9.42427 10.7505L9.41803 10.7506L6.57579 10.7505Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59143 3.25061H9.40863C10.6657 3.22188 11.7118 4.2128 11.7497 5.47101C11.7499 5.47854 11.75 5.48607 11.75 5.49361V6.50761C11.75 6.51514 11.7499 6.52268 11.7497 6.53021C11.7118 7.78841 10.6657 8.77934 9.40864 8.75061H6.59142C5.3344 8.77934 4.2883 7.78841 4.25037 6.53021C4.25014 6.52268 4.25003 6.51514 4.25003 6.50761V5.49361C4.25003 5.48607 4.25014 5.47854 4.25037 5.47101C4.2883 4.2128 5.3344 3.22188 6.59143 3.25061ZM5.75003 5.50675V6.49446C5.76808 6.92532 6.129 7.26289 6.56123 7.2509L6.58203 7.25061H9.41803L9.43882 7.2509C9.87106 7.26289 10.232 6.92532 10.25 6.49446V5.50676C10.232 5.0759 9.87106 4.73833 9.43882 4.75032L9.41803 4.75061H6.58203L6.56123 4.75032C6.129 4.73833 5.76808 5.0759 5.75003 5.50675Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5848 3.25067H17.4152C18.029 3.24647 18.6195 3.48581 19.0572 3.91631C19.4957 4.3476 19.7449 4.93544 19.75 5.55048L19.7501 5.55667L19.75 11.4509C19.745 12.0662 19.4955 12.6542 19.0567 13.0856C18.6188 13.516 18.028 13.7552 17.4141 13.7507H14.5849C13.971 13.7549 13.3805 13.5155 12.9428 13.085C12.5043 12.6537 12.2551 12.0659 12.2501 11.4509L12.25 11.4447L12.25 5.55048C12.2551 4.93544 12.5043 4.3476 12.9428 3.91631C13.3805 3.48581 13.971 3.24647 14.5848 3.25067ZM14.5758 4.75064C14.3586 4.74884 14.1495 4.8334 13.9947 4.98572C13.8404 5.13743 13.7525 5.34399 13.75 5.56026V11.4411C13.7525 11.6573 13.8404 11.8639 13.9947 12.0156C14.1495 12.1679 14.3586 12.2525 14.5758 12.2507L14.582 12.2506L17.4237 12.2507C17.6411 12.2526 17.8502 12.1681 18.0052 12.0158C18.1596 11.8641 18.2476 11.6574 18.25 11.4411V5.56024C18.2476 5.34398 18.1596 5.13743 18.0054 4.98572C17.8505 4.8334 17.6415 4.74884 17.4243 4.75064L17.418 4.7507L14.5758 4.75064Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5914 14.2506H17.4086C18.6657 14.2219 19.7118 15.2128 19.7497 16.471C19.7499 16.4785 19.75 16.4861 19.75 16.4936V17.5076C19.75 17.5151 19.7499 17.5227 19.7497 17.5302C19.7118 18.7889 18.6649 19.78 17.4074 19.7506H14.5914C13.3338 19.7793 12.2875 18.7875 12.2504 17.5287C12.2501 17.5214 12.25 17.514 12.25 17.5066V16.4936C12.25 16.4861 12.2501 16.4785 12.2504 16.471C12.2883 15.2128 13.3344 14.2219 14.5914 14.2506ZM13.75 16.5068V17.4937C13.7677 17.9249 14.1287 18.2629 14.5612 18.2509L14.582 18.2506H17.417C17.4241 18.2506 17.4312 18.2507 17.4383 18.2509C17.8707 18.2632 18.232 17.9255 18.25 17.4945V16.5068C18.232 16.0759 17.8711 15.7383 17.4388 15.7503L17.418 15.7506H14.582L14.5612 15.7503C14.129 15.7383 13.7681 16.0759 13.75 16.5068Z"
        fill="currentColor"
      />
    </svg>
  );
};
