import { components, GroupBase, OptionProps } from 'react-select';
import { OptionType } from '../../../types';

export const Option = (
  props: OptionProps<string | OptionType, true, GroupBase<string | OptionType>>,
) => {
  return (
    <components.Option {...props}>
      <input
        key={(props as unknown as OptionType).value}
        type="checkbox"
        checked={props.isSelected}
        onChange={() => {}}
      />
      <label className="ml-[5px]">{props.label}</label>
    </components.Option>
  );
};
