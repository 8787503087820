import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Header from '../../layout/dashboard/Header';
import Logo from '../../assets/images/logo.webp';
import { CommonButton } from '../../components';
import PaymentCardList from './PaymentCardList';
import PaymentBankList from './PaymentBankList';
import { SidebarProps, Tab } from '../../types';

const ViewPayment: React.FC<SidebarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const [searchParams] = useSearchParams();
  const tabId = searchParams.get('tabId');
  const [currentTab, setCurrentTab] = useState<number>(1); // Initialize currentTab here

  const toggleSidebar = () => {
    setIsSidebarOpen?.(!isSidebarOpen);
  };

  const tabs: Tab[] = [
    {
      id: 1,
      name: 'Bank Account',
      content: (
        <PaymentBankList
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
          currentTab={currentTab} // Pass currentTab as prop
        />
      ),
      path: 'bank',
    },
    {
      id: 2,
      name: 'Card',
      content: (
        <PaymentCardList
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
          currentTab={currentTab} // Pass currentTab as prop
        />
      ),
      path: 'card',
    },
  ];

  const matchingTab = tabs.find((tab) => tab.path === tabId);
  const parsedTabId = matchingTab ? matchingTab.id : 1;

  const handleTabClick = (id: number) => {
    setCurrentTab(id);
    localStorage.removeItem('month');
    localStorage.removeItem('year');
  };

  useEffect(() => {
    setCurrentTab(parsedTabId);
  }, [tabId, parsedTabId]);

  return (
    <div className="Banner">
      <div
        className={`inline-flex items-center justify-between w-full p-3 text-sm text-gray-500 rounded-lg lg:hidden ${
          isSidebarOpen ? 'hidden' : ''
        }`}
        onClick={toggleSidebar}
      >
        <img src={Logo} alt="Logo" className="w-48" />
        <Icon icon="ri:menu-3-line" className="text-primary" width="24" height="24" />
      </div>
      <div className="lg:px-8 px-4 py-6 lg:ml-[16rem] h-screen flex flex-col">
        <Header title="Payment Methods" isBack={true} />
        <div className="relative grow overflow-y-auto">
          <div className="flex md:flex-row flex-col justify-between mt-2 md:items-center">
            <div className="flex mt-2">
              <CommonButton
                label={currentTab === 1 ? 'Bank List' : 'Card List'}
                className="bg-white text-primary h-[48px] px-[12px] py-[12px] rounded-lg flex items-center justify-center gap-2 font-medium dropshadow-xl cursor-auto"
              />
            </div>
          </div>
          <div className="w-full bg-white rounded-2xl mt-6 p-3 h-fit">
            <div className="grid grid-cols-2 w-full justify-center text-primary space-y-0">
              {tabs &&
                tabs.length &&
                tabs.map((tabValue, index) => (
                  <div
                    key={tabValue.id}
                    className={`text-center ${
                      index + 1 === currentTab ? 'border-b-2 border-b-primary' : ''
                    }`}
                  >
                    <div
                      className={`cursor-pointer my-2 text-base tracking-[-0.16px] ${
                        index + 1 === currentTab ? 'font-semibold' : 'font-normal'
                      }`}
                      onClick={() => handleTabClick(tabValue.id)}
                    >
                      {tabValue.name}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {tabs.map((tab, i) => (
            <div key={i}>
              {currentTab === tab.id ? (
                <div>
                  <p className="mt-4">{tab.content}</p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewPayment;
