import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { GetTopPurchasedProductsRequestBody, GetTopPurchasedProductsRequestData } from './types';

export const purchasedProductReportApi = createApi({
  reducerPath: 'purchasedProductReportApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getTopPurchasedProducts: builder.mutation<
      ApiResponse<GetTopPurchasedProductsRequestData>,
      GetTopPurchasedProductsRequestBody
    >({
      query: (body) => ({
        url: 'api/getTopPurchasedProducts',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetTopPurchasedProductsMutation } = purchasedProductReportApi;
