import type { CSSObjectWithLabel } from 'react-select';
import { Id, toast } from 'react-toastify';
import { IconError, IconInfo, IconSuccess, IconWarning } from '../assets/icons';
import clsx from 'clsx';
import { ToastifyCloseButton } from '../components/shared';
import { ReactNode } from 'react';

export const reftoken = (): void => {
  const getLocalStorageKeys = Object.keys(localStorage);
  const getBaseAuthIdToken = getKeyValuesByLocalStorage(/refreshtoken/gi, getLocalStorageKeys);

  if (getBaseAuthIdToken) {
    const getlocalStorageIdToken = JSON.parse(localStorage.getItem(getBaseAuthIdToken)!);
    localStorage.setItem('REFRESH_TOKEN', getlocalStorageIdToken?.secret);
  } else {
    console.error('Base auth id token not found in local storage.');
  }
};

export const getKeyValuesByLocalStorage = (
  pattern: RegExp,
  getLocalStorageKeys: string[],
): string => {
  let localStorageKey = '';
  getLocalStorageKeys &&
    getLocalStorageKeys.length &&
    getLocalStorageKeys.forEach((values) => {
      if (values.match(pattern)) {
        if (values.match(pattern)) {
          localStorageKey = values;
        }
      }
    });
  return localStorageKey;
};

export const queryString = (params: Record<string, string>): string => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

const convertStringToArrayBuffer = (str: string) => {
  const textEncoder = new TextEncoder();
  return textEncoder.encode(str).buffer;
};

export function convertFileToArrayBuffer(file: File): Promise<ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    if (!file || !file.name) {
      reject(new Error('Invalid or missing file.'));
    }

    const reader = new FileReader();

    reader.onload = () => {
      const arrayBuffer: ArrayBuffer | null | string = reader.result;

      if (arrayBuffer === null) {
        resolve(null);
        return;
      }
      if (typeof arrayBuffer === 'string') {
        resolve(convertStringToArrayBuffer(arrayBuffer));
        return;
      }
      if (!arrayBuffer) {
        reject(new Error('Failed to read file into ArrayBuffer.'));
        return;
      }

      resolve(arrayBuffer);
    };

    reader.onerror = () => {
      reject(new Error('Error reading file.'));
    };

    reader.readAsArrayBuffer(file);
  });
}

export const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderRadius: '0.5rem',
    padding: '4px',
    borderColor: 'rgb(206 212 218/1)',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#494949',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#494949',
    fontSize: '14px',
  }),
};

const baseClassName =
  'text-subtitle-sm w-screen max-w-[450px] !p-1.5 !pr-8 !text-grey-1 !shadow-none !rounded-xl !font-instrument-sans';
const baseIconClassName = 'w-6 h-6 min-w-6';

export const showToastify = (
  type: 'success' | 'error' | 'warning' | 'info',
  message: string | ReactNode = '',
  autoClose: number | false | undefined = 5000,
  iconPosition: 'top' | 'middle' | 'bottom' = 'middle',
) => {
  let toastId: Id;

  const iconContainerClassName = clsx(
    'flex flex-col justify-start',
    iconPosition === 'top' && 'order-first',
    iconPosition === 'middle' && 'justify-center',
    iconPosition === 'bottom' && 'order-last justify-end',
  );

  switch (type) {
    case 'success':
      toastId = toast.success(
        <div className="flex items-start">
          <div className={iconContainerClassName}>
            <IconSuccess className={clsx('text-status-green', baseIconClassName)} />
          </div>
          <div className="ml-2">{message}</div>
        </div>,
        {
          icon: false,
          autoClose,
          closeButton: ToastifyCloseButton,
          className: clsx(
            '!bg-status-green-light border border-status-green !text-grey-1',
            baseClassName,
          ),
        },
      );
      break;
    case 'error':
      toastId = toast.error(
        <div className="flex items-start">
          <div className={iconContainerClassName}>
            <IconError className={clsx('text-status-red', baseIconClassName)} />
          </div>
          <div className="ml-2">{message}</div>
        </div>,
        {
          icon: false,
          autoClose,
          closeButton: ToastifyCloseButton,
          className: clsx('!bg-status-red-light border border-status-red', baseClassName),
        },
      );
      break;
    case 'warning':
      toastId = toast.warning(
        <div className="flex items-start">
          <div className={iconContainerClassName}>
            <IconWarning className={clsx('text-status-yellow-text', baseIconClassName)} />
          </div>
          <div className="ml-2">{message}</div>
        </div>,
        {
          icon: false,
          autoClose,
          closeButton: ToastifyCloseButton,
          className: clsx(
            '!bg-status-yellow-light border border-status-yellow-text',
            baseClassName,
          ),
        },
      );
      break;
    case 'info':
      toastId = toast.info(
        <div className="flex items-start">
          <div className={iconContainerClassName}>
            <IconInfo className={clsx('text-status-blue', baseIconClassName)} />
          </div>
          <div className="ml-2">{message}</div>
        </div>,
        {
          icon: false,
          autoClose,
          closeButton: ToastifyCloseButton,
          className: clsx('!bg-status-blue-light border border-status-blue', baseClassName),
        },
      );
      break;
    default:
      toastId = toast(message);
  }

  return toastId;
};
