import React, { useState, useEffect, InputHTMLAttributes } from 'react';

type DebouncedInputProps = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
  reset?: boolean;
};

const DebouncedInput: React.FC<DebouncedInputProps> = ({
  value: initialValue,
  onChange,
  debounce = 500,
  reset = false,
  ...props
}) => {
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  useEffect(() => {
    setValue('');
  }, [reset]);

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
};

export default DebouncedInput;
