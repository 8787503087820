import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { GetUserAccountRequestData } from './types';

export const stripeApi = createApi({
  reducerPath: 'stripeApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    addPaymentAccount: builder.mutation({
      query: (body) => ({
        url: 'api/addPaymentAccount',
        method: 'POST',
        body,
      }),
    }),
    updatePaymentAccount: builder.mutation({
      query: (body) => ({
        url: 'api/updatePaymentAccount',
        method: 'POST',
        body,
      }),
    }),
    getUserAccount: builder.mutation<ApiResponse<GetUserAccountRequestData>, void>({
      query: (body) => ({
        url: 'api/getUserAccounts',
        method: 'POST',
        body,
      }),
    }),
    deletePaymentAccount: builder.mutation({
      query: (body) => ({
        url: 'api/deletePaymentAccount',
        method: 'POST',
        body,
      }),
    }),
    verifyBankAccount: builder.mutation({
      query: (body) => ({
        url: 'api/verifyBankAccount',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAddPaymentAccountMutation,
  useGetUserAccountMutation,
  useDeletePaymentAccountMutation,
  useUpdatePaymentAccountMutation,
  useVerifyBankAccountMutation,
} = stripeApi;
