import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useGetSalesByRegionWithDurationMutation } from '../../redux/api/dashboardApi/dashboardApi';
import { useGetAuthUser } from '../../hooks';
import { DISTRIBUTOR } from '../../constants';
import { LoaderComponent, SelectDashboard } from '../index';
import moment from 'moment';
import { OptionType } from '../../types';
import { SalesByRegionWithDurationRequestData } from '../../redux/api/dashboardApi/types';
import { ProgressBar } from './components/ProgressBar';

const SalesByRegion: React.FC = () => {
  const { user } = useGetAuthUser();

  const [duration, setDuration] = useState<string>('WEEK');
  const date = new Date();
  const offset = moment.parseZone(date).utcOffset();

  const [getSalesByRegionWithDuration, { data: datas, isLoading }] =
    useGetSalesByRegionWithDurationMutation();

  useEffect(() => {
    if (user && user?.userType === DISTRIBUTOR) {
      getSalesByRegionWithDuration({
        duration: duration?.toUpperCase(),
        offset: offset,
      });
    }
  }, [getSalesByRegionWithDuration, duration]);

  const handleSelectChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setDuration(value as string);
    }
  };

  return (
    <div className="xl:col-span-6 col-span-12">
      <div className="bg-white rounded-2xl p-4 text-secondary h-[460px]">
        <div className="flex justify-between items-center mb-2 md:p-2.5 p-0">
          <p className="font-medium">Sales by Region</p>
          <SelectDashboard duration={duration} onChange={handleSelectChange} />
        </div>
        {isLoading ? (
          <>
            <LoaderComponent />
          </>
        ) : (
          <div>
            {' '}
            {datas?.data?.length && datas.data.length > 0 ? (
              <>
                {' '}
                <div className="p-[10px]">
                  {datas?.data?.map((data: SalesByRegionWithDurationRequestData, index: number) => (
                    <div key={index} className="justify-between items-center mb-6">
                      <ProgressBar
                        value={data?.percentage}
                        name={data?.region}
                        dataAmount={data?.sales}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {' '}
                <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm h-[320px]">
                  <h3 className="text-lg font-semibold">No Data Found</h3>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesByRegion;
