import { useRef, useState } from 'react';
import { Popover } from 'react-aria-components';
import type { FilterChangeArgs, FilterType } from './types';
import clsx from 'clsx';
import { FilterDialogContent, FilterButtonContent } from './components';

type FilterProps = {
  id: string;
  label: string;
  type: FilterType;
  options: string[] | number[];
  selectedValues: string[] | number[];
  onFilterClean?: (id: string) => void;
  onFilterChange: ({ id, type, newValue }: FilterChangeArgs) => void;
  inputPrefix?: React.ReactNode;
};

export const Filter = ({
  id,
  label,
  type,
  options,
  selectedValues,
  onFilterClean,
  onFilterChange,
  inputPrefix,
}: FilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);

  return (
    <>
      <div
        role="button"
        className={clsx(
          'py-2.5 px-3 flex items-center gap-1 cursor-pointer outline-none transition-all duration-300 border border-grey-5 rounded-xl h-10',
          {
            'bg-grey-6 hover:bg-grey-5': isOpen,
            'bg-white hover:bg-grey-7': !isOpen,
          },
        )}
        onClick={() => setIsOpen(true)}
        ref={triggerRef}
      >
        <FilterButtonContent
          id={id}
          label={label}
          type={type}
          selectedValues={selectedValues}
          handleClear={onFilterClean}
          isOpen={isOpen}
        />
      </div>
      <Popover
        placement="bottom right"
        offset={8}
        triggerRef={triggerRef}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <div className="w-60 bg-white border border-grey-5 rounded-md outline-none overflow-hidden shadow-1">
          <FilterDialogContent
            id={id}
            type={type}
            options={options}
            selectedValues={selectedValues}
            handleSelect={onFilterChange}
            inputPrefix={inputPrefix}
          />
        </div>
      </Popover>
    </>
  );
};
