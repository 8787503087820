import * as Yup from 'yup';

const passwordSchema = Yup.string()
  .required('Password is required')
  .min(8, 'Password must be at least 8 characters long')
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
    'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number',
  );

export const newPasswordSetupValidationSchema = Yup.object().shape({
  password: passwordSchema,
  confirmPassword: Yup.string()
    .required('Please retype your password')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
});
