import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Header from '../../layout/dashboard/Header';
import { CommonButton } from '../../components';

import {
  useElements,
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  useAddPaymentAccountMutation,
  useUpdatePaymentAccountMutation,
} from '../../redux/api/stripeApi/stripeApi';
import { handleAlphabetPress } from '../../utils/handlers';
import { TailSpin } from 'react-loader-spinner';
import { useFormik } from 'formik';
import { SidebarProps } from '../../types';
import { FetchQueryError } from '../../redux/api/types';
import { showToastify } from '../../utils/common';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { cardAddValidationSchema } from './constants';

const PaymentCardAdd: React.FC<SidebarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const stripe = useStripe();

  const navigate = useNavigate();
  const location = useLocation();
  const value = location?.state?.original;

  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isErrorMessage, setIsErrorMessage] = useState<boolean>(false);
  const [isPrimaryError, setIsPrimaryError] = useState<boolean>(false);
  const [cardHolderName, setCardHolderName] = useState<string>('');
  const [isPrimary, setIsPrimary] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [AddCardApi, AddCardApiResponse] = useAddPaymentAccountMutation();
  const [updateBankAccount, updateBankAccountData] = useUpdatePaymentAccountMutation();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPrimary(event.target.checked);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen?.(!isSidebarOpen);
  };
  const formik = useFormik({
    initialValues: {
      cardHolderName: '',
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
    },
    validationSchema: cardAddValidationSchema(value),
    onSubmit: async () => {
      // e.preventDefault()
      setIsLoading(true);
      if (value) {
        if (!value?.isPrimary && isPrimary) {
          updateBankAccount({
            accountId: value.id,
          });
        } else {
          setIsPrimaryError(true);
        }
      } else {
        if (!stripe || !elements) {
          return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        if (cardElement) {
          try {
            const { error, token } = await stripe.createToken(cardElement);
            if (error) {
              setIsErrorMessage(true);
              setIsLoading(false);
              setErrorMessage(error.message || 'An error occurred while creating token.');
            } else {
              setIsErrorMessage(false);
              setIsLoading(true);
              AddCardApi({
                accountType: token.type,
                accountName: formik.values.cardHolderName,
                last4: token.card?.last4,
                routingNumber: '',
                isPrimary: isPrimary,
                stripeToken: token.id,
              });
              // Proceed with token handling (e.g., send token to server for further processing)
            }
          } catch (error) {
            console.error('Error creating token:', error);
            setErrorMessage('An error occurred while creating token.');
          }
        } else {
          console.error('Card element is not available.');
          setErrorMessage('Card element is not available.');
        }
      }
    },
  });

  useEffect(() => {
    if (value) {
      setCardHolderName(value.accountName);
      setIsPrimary(value.isPrimary);
    }
  }, [value]);

  useEffect(() => {
    if (updateBankAccountData?.isSuccess) {
      showToastify('success', updateBankAccountData?.data?.message);
      setIsLoading(false);
      navigate('/payment?tabId=card');
    } else if (updateBankAccountData?.isError) {
      setIsLoading(false);
      showToastify('error', (updateBankAccountData?.error as FetchQueryError)?.data?.error);
    }
  }, [updateBankAccountData]);

  useEffect(() => {
    if (AddCardApiResponse?.isSuccess) {
      setIsLoading(false);
      showToastify('success', AddCardApiResponse?.data?.message);
      navigate('/payment?tabId=card');
    } else if (AddCardApiResponse?.isError) {
      setIsLoading(false);
      showToastify('error', (AddCardApiResponse?.error as FetchQueryError)?.data?.error);
    }
  }, [AddCardApiResponse]);

  return (
    <>
      <div className="Banner">
        <div
          className={`inline-flex items-center justify-between w-full p-3  text-sm text-gray-500 rounded-lg lg:hidden ${
            isSidebarOpen ? 'hidden' : ''
          }`}
          onClick={toggleSidebar}
        >
          <Icon icon="ri:menu-3-line" color="#B20036" width="24" height="24" />
        </div>

        <div className="lg:px-8 px-4 py-6 lg:ml-[16rem] h-screen flex flex-col">
          <Header title={value ? 'Update Card' : 'Add Card'} />
          <div className="relative grow overflow-y-auto">
            <div className="flex mt-2">
              <CommonButton
                label={'Card'}
                className="bg-white text-primary px-[10px] py-[12px] flex justify-between items-center gap-3 dropshadow-xl"
                icon={
                  <Icon
                    icon="ic:round-arrow-back"
                    className="text-primary"
                    width="20"
                    height="20"
                  />
                }
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>

            <div className="w-full bg-white rounded-2xl my-6 p-6 h-fit sm:mb-32">
              <form onSubmit={formik.handleSubmit}>
                <div className="grid xl:grid-cols-3 md:grid-cols-2 xl:gap-x-12 md:gap-x-6 sm:gap-y-6 gap-x-3 gap-y-8">
                  <div>
                    <label className="block mb-2 text-sm font-semibold text-secondary">
                      Cardholder Name *
                    </label>
                    <input
                      type="text"
                      name="cardHolderName"
                      className={`${value ? 'cursor-not-allowed text-gray-300' : ''} border border-gray-300 rounded px-3 py-1.5 focus:outline-none focus:border-primary w-full`}
                      value={cardHolderName}
                      disabled={value}
                      placeholder="Cardholder Name"
                      maxLength={24}
                      onChange={(e) => {
                        setCardHolderName(e.target.value);
                        formik.handleChange(e);
                      }}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        handleAlphabetPress(e, 25)
                      }
                      onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                        const maxLength = 24;
                        const currentValue = formik.values.cardHolderName;
                        const remainingLength = maxLength - currentValue.length;
                        if (remainingLength > 0) {
                          e.preventDefault(); // Prevent default pasting behavior
                          // Get pasted text
                          const pastedText = e.clipboardData.getData('text');
                          // Truncate if pasted text exceeds maxLength
                          const truncatedText = pastedText.slice(0, remainingLength);
                          // Remove numbers from pasted text
                          const filteredText = truncatedText.replace(/[^\p{L}\s]/gu, '');
                          // Insert filtered text at cursor position
                          const input = e.target as HTMLInputElement;
                          const cursorPosition: number | null = input.selectionStart;
                          const currentValue: string = input.value;
                          const newValue =
                            currentValue.slice(0, cursorPosition ?? 0) +
                            filteredText +
                            currentValue.slice(input.selectionEnd ?? 0);
                          input.value = newValue;
                          // Update formik value
                          formik.setFieldValue('cardHolderName', newValue);
                          setCardHolderName(newValue);
                        }
                      }}
                      autoComplete="off"
                    />
                    {formik.errors && (
                      <div>
                        <p className="error text-sm mt-5">
                          {formik.errors?.cardHolderName &&
                            formik.touched?.cardHolderName &&
                            formik.errors?.cardHolderName}
                        </p>
                      </div>
                    )}
                  </div>
                  {value ? (
                    <div className="cursor-not-allowed">
                      <div className="flex items-center">
                        <label className="block  mb-2 text-sm font-semibold text-secondary">
                          Card Number *
                        </label>
                        {/* <span className="text-secondary ms-1">*</span> */}
                      </div>

                      <div className="relative text-left">
                        {/* <span className="absolute left-4 top-2 text-2xl text-gray-300">
                          **** **** ****
                        </span>
                        <span className="absolute left-36 top-1/2 transform -translate-y-1/2 text-gray-300">
                          {value?.last4}
                        </span> */}
                        <input
                          type="text"
                          value={'************' + (value?.last4 || '')}
                          readOnly
                          className="pl-20 border border-gray-300 rounded bg-[#fafafa] block w-full p-2 text-sm cursor-not-allowed text-gray-300"
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <label
                        htmlFor="card"
                        className="block mb-2 text-sm font-semibold text-secondary"
                      >
                        Card Number *
                      </label>
                      <CardNumberElement
                        className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-primary"
                        options={{
                          showIcon: true,
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                        onChange={(element: StripeCardNumberElementChangeEvent) => {
                          element.complete &&
                            formik.setFieldValue(element.elementType, element.brand);
                        }}
                      />
                      {formik.errors && (
                        <div>
                          <p className="error text-sm mt-5">
                            {formik.errors?.cardNumber &&
                              formik.touched?.cardNumber &&
                              formik.errors?.cardNumber}
                          </p>
                        </div>
                      )}
                      {errorMessage == 'Your card number is incomplete.' && isErrorMessage ? (
                        <div>
                          <p className="error text-sm mt-5">{errorMessage}</p>
                        </div>
                      ) : null}
                    </div>
                  )}
                  {value ? (
                    <div>
                      <label className="block mb-2 text-sm font-semibold text-secondary">
                        Month *
                      </label>
                      <input
                        type="text"
                        name="cardholderName"
                        className="border text-gray-300 border-gray-300 rounded px-3 py-1.5 focus:outline-none focus:border-primary w-full cursor-not-allowed"
                        value={'****'}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <div className="">
                      <label className="block mb-2 text-sm font-semibold text-secondary">
                        Month *
                      </label>

                      <CardExpiryElement
                        className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-primary"
                        options={{
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                        onChange={(element: StripeCardExpiryElementChangeEvent) => {
                          element.complete &&
                            formik.setFieldValue(element.elementType, element.elementType);
                        }}
                      />
                      {formik.errors && (
                        <div>
                          <p className="error text-sm mt-5">
                            {formik.errors?.cardExpiry &&
                              formik.touched?.cardExpiry &&
                              formik.errors?.cardExpiry}
                          </p>
                        </div>
                      )}
                      {errorMessage == "Your card's expiry date is incomplete." &&
                      isErrorMessage ? (
                        <div>
                          <p className="error text-sm mt-5">{errorMessage}</p>
                        </div>
                      ) : null}
                    </div>
                  )}

                  {value ? (
                    <div>
                      <label className="block mb-2 text-sm font-semibold text-secondary">
                        CVC *
                      </label>
                      <input
                        type="text"
                        name="cardholderName"
                        className="text-gray-300 border border-gray-300 rounded px-3 py-1.5 focus:outline-none focus:border-primary w-full cursor-not-allowed"
                        value={'****'}
                        disabled={true}
                      />
                      {errorMessage == "Your card's security code is incomplete." &&
                      isErrorMessage ? (
                        <div>
                          <p className="error text-sm mt-5">{errorMessage}</p>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <label className="block mb-2 text-sm font-semibold text-secondary">
                        CVC *
                      </label>
                      <CardCvcElement
                        className="border border-gray-300 rounded px-3 py-2 focus:outline-none focus:border-primary"
                        options={{
                          style: {
                            base: {
                              fontSize: '16px',
                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                        onChange={(element: StripeCardCvcElementChangeEvent) => {
                          element.complete &&
                            formik.setFieldValue(element.elementType, element.elementType);
                        }}
                      />
                      {formik.errors && (
                        <div>
                          <p className="error text-sm mt-5">
                            {formik.errors?.cardCvc &&
                              formik.touched?.cardCvc &&
                              formik.errors?.cardCvc}
                          </p>
                        </div>
                      )}
                      {errorMessage == "Your card's security code is incomplete." &&
                      isErrorMessage ? (
                        <div>
                          <p className="error text-sm mt-5">{errorMessage}</p>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isPrimary"
                    name="isPrimary"
                    className="custom-checkbox cursor-pointer mt-8 w-[17px] h-[17px]"
                    onChange={handleCheckboxChange}
                    checked={isPrimary}
                    disabled={value?.isPrimary}
                  />
                  <div
                    className="mt-8 ms-2 cursor-pointer"
                    onClick={() => setIsPrimary(!isPrimary)}
                  >
                    <label className="block text-sm font-semibold text-secondary cursor-pointer">
                      Mark as Primary
                    </label>
                  </div>
                </div>
                {!value?.isPrimary && !isPrimary && isPrimaryError && (
                  <p className="error mt-2 text-sm">Please select as primary</p>
                )}

                <div className="mt-8 flex justify-end">
                  {value && value?.isPrimary ? (
                    ''
                  ) : (
                    <button
                      type="submit"
                      className={`text-white bg-primary border  w-fit ms-2 px-[20px] py-[8px] rounded-xl font-medium ${value && !isPrimary ? 'disabled' : ''}`}
                      disabled={!stripe || !elements || isLoading || (value && !isPrimary)}
                    >
                      {value ? (
                        isLoading ? (
                          <div className="flex items-center justify-center">
                            <TailSpin
                              visible={true}
                              height="20"
                              width="20"
                              color="white"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                            &ensp;{'Update'}
                          </div>
                        ) : (
                          'Update'
                        )
                      ) : isLoading ? (
                        <div className="flex items-center justify-center">
                          <TailSpin
                            visible={true}
                            height="20"
                            width="20"
                            color="white"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                          &ensp;{'Add Card'}
                        </div>
                      ) : (
                        'Add Card'
                      )}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCardAdd;
