import './sentry';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IdTokenProvider } from './hooks';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN as string);

const options: StripeElementsOptions = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <IdTokenProvider>
          <ToastContainer
            position="bottom-center"
            hideProgressBar={true}
            autoClose={3000}
            closeOnClick={true}
            pauseOnHover={true}
            limit={3}
          />
          <QueryClientProvider client={queryClient}>
            <Elements stripe={stripePromise} options={options}>
              <App />
            </Elements>
          </QueryClientProvider>
        </IdTokenProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
