import clsx from 'clsx';
import { IconChevronDown } from '../../../assets/icons';
import type { ReactNode } from 'react';
import type { SelectOption } from '../types';

type SelectButtonContentProps = {
  placeholder?: string;
  selectedOption?: SelectOption;
  isOpen: boolean;
  isDisabled: boolean;
  icon: ReactNode;
  variant: 'default' | 'multi-select' | 'search';
};

export const SelectButtonContent = ({
  placeholder,
  selectedOption,
  isOpen,
  icon,
  isDisabled,
}: SelectButtonContentProps) => (
  <>
    {icon}

    <span
      className={clsx('text-sm ml-2 overflow-hidden text-ellipsis whitespace-nowrap', {
        'text-grey-1': !isDisabled,
        'text-grey-4': isDisabled,
      })}
    >
      {selectedOption ? selectedOption.label : placeholder}
    </span>

    <IconChevronDown
      className={clsx('w-4 h-4 ml-auto transition-transform', {
        'transform rotate-180': isOpen,
        'transform rotate-0': !isOpen,
      })}
    />
  </>
);
