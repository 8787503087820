import { useEffect, useState } from 'react';
import { DISTRIBUTOR, RETAILER } from '../constants';
import { useGetAuthUser } from '../hooks';
import { LoaderComponent } from '../components';
import { MutationExtraType } from '../types';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { GetTopPerformingUserRequestData, UserTypeBody } from '../redux/api/dashboardApi/types';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { ApiResponse } from '../redux/api/types';

type CommonModalProps = {
  name: string;
  useMutation: UseMutation<
    MutationDefinition<
      UserTypeBody,
      (
        args: string | FetchArgs,
        api: BaseQueryApi,
        extraOptions: MutationExtraType,
      ) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
      never,
      ApiResponse<GetTopPerformingUserRequestData[]>
    >
  >;
  mutationExtra?: MutationExtraType;
  userType: string;
};

const TopPerforming: React.FC<CommonModalProps> = ({
  name,
  useMutation,
  mutationExtra,
  userType,
}) => {
  const [datas, setDatas] = useState<ApiResponse<GetTopPerformingUserRequestData[]>>();
  const [getData, { isLoading }] = useMutation();
  const { user } = useGetAuthUser();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getData({ userType });

      if (response?.data) {
        setDatas(response?.data);
      }
    };
    fetchData();
  }, [mutationExtra]);

  return (
    <>
      {user?.userType === DISTRIBUTOR && userType === DISTRIBUTOR ? (
        <div className="xl:col-span-6 col-span-12 h-full">
          <div className="bg-white rounded-2xl p-4 text-secondary h-full">
            <p className="font-medium mb-6">{name}</p>

            {isLoading ? (
              <>
                <LoaderComponent />
              </>
            ) : (
              <div>
                {datas?.data && datas.data.length > 0 ? (
                  datas?.data?.map((item: GetTopPerformingUserRequestData, index: number) => (
                    <div className="sm:flex justify-between items-center mb-8" key={index}>
                      <p className="font-normal">
                        {index + 1}. {item?.retailerName}
                      </p>
                      <div className="text-right mt-4 sm:mt-0">
                        <span className="rounded p-2 bg-green-100 text-success text-sm font-medium whitespace-nowrap">
                          {item?.orders} Orders
                        </span>
                        <span className="rounded p-2 bg-slate-50 text-yellow text-sm font-medium ml-4">
                          {item?.sales}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm h-[300px]">
                    <h3 className="text-lg font-semibold">No Data Found</h3>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : userType === DISTRIBUTOR ? (
        <div className="xl:col-span-6 col-span-12">
          <div className="bg-white rounded-2xl p-4 text-secondary font-medium dropshadow-xl h-full fixHeight flex flex-col">
            <p className="font-medium mb-6">{name}</p>

            {isLoading ? (
              <LoaderComponent />
            ) : (
              <div className="max-h-80 overflow-y-auto">
                {datas?.data && datas.data.length > 0 ? (
                  datas?.data?.map((item: GetTopPerformingUserRequestData, index: number) => (
                    <div className="sm:flex justify-between items-center mb-8" key={index}>
                      <p className="font-normal">
                        {index + 1}. {item?.distributorName}
                      </p>
                      <div className="text-right mt-4 sm:mt-0">
                        <span className="rounded p-2 bg-green-100 text-success text-sm font-medium whitespace-nowrap">
                          {item?.orders} Orders
                        </span>
                        <span className="rounded p-2 bg-slate-50 text-yellow text-sm font-medium ml-4">
                          {item?.sales}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm h-[320px]">
                    <h3 className="text-lg font-semibold">No Data Found</h3>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : null}

      {userType === RETAILER && (
        <div className="xl:col-span-6 col-span-12">
          <div className="bg-white rounded-2xl p-4 text-secondary font-medium dropshadow-xl h-full fixHeight flex flex-col">
            <p className="font-medium mb-6">{name}</p>

            {isLoading ? (
              <>
                <LoaderComponent />
              </>
            ) : (
              <>
                {datas?.data && datas.data.length > 0 ? (
                  datas?.data?.map((item: GetTopPerformingUserRequestData, index: number) => (
                    <div className="sm:flex justify-between items-center mb-8" key={index}>
                      <p className="font-normal">
                        {index + 1}. {item?.retailerName}
                      </p>
                      <div className="text-right mt-4 sm:mt-0">
                        <span className="rounded p-2 bg-green-100 text-success text-sm font-medium whitespace-nowrap">
                          {item?.orders} Orders
                        </span>
                        <span className="rounded p-2 bg-slate-50 text-yellow text-sm font-medium ml-4">
                          {item?.sales}
                        </span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm">
                    <h3 className="text-lg font-semibold">No Data Found</h3>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TopPerforming;
