import React from 'react';
import clsx from 'clsx';

type TextFieldProps = {
  fieldName: string;
  required?: boolean;
  label?: string;
  props?: {
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    values: { [key: string]: unknown };
    errors: { [key: string]: string };
    touched: { [key: string]: boolean };
  };
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  className?: string;
  type?: string;
  readOnly?: boolean;
  divClassName?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
};

const TextField: React.FC<TextFieldProps> = ({
  fieldName,
  required,
  label,
  props,
  value,
  placeholder,
  disabled,
  maxLength,
  divClassName,
  type,
  readOnly = false,
  onKeyDown,
  onKeyPress,
  onPaste,
}) => {
  return (
    <div className={divClassName}>
      <div className="flex items-center">
        {label && (
          <label htmlFor={fieldName} className="block  mb-2 text-sm font-semibold text-secondary">
            {label}
          </label>
        )}
        {required && <span className="text-secondary ms-1">*</span>}
      </div>

      <div className="your-container-class">
        {fieldName === 'phoneNumber' ? (
          <span className="notranslate">+1</span>
        ) : fieldName === 'minimumOrderThresholds' ? (
          <span className="notranslate">$</span>
        ) : (
          ''
        )}
        <input
          type={type}
          onChange={props?.handleChange}
          value={value || (props?.values[fieldName] as string)}
          name={fieldName}
          maxLength={maxLength}
          className={clsx(
            'border border-gray-100 rounded-lg block w-full p-3 text-sm bg-transparent text-[#494949]',
            {
              disabled: disabled,
              'pl-[32px]': fieldName === 'phoneNumber' || fieldName === 'minimumOrderThresholds',
            },
          )}
          id={fieldName}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          autoComplete="off"
        />
      </div>

      {props?.errors && (
        <p className="error text-sm mt-0.5">
          {props.errors[fieldName] && props.touched[fieldName] && props.errors[fieldName]}
        </p>
      )}
    </div>
  );
};

export default TextField;
