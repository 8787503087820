import { useEffect, useState } from 'react';
import type { SideNavEntry } from '../../../types';
import { SidebarLink } from './SidebarLink';
import { useLocation } from 'react-router-dom';
import { IconLogo } from '../../../assets/icons';

type SidebarProps = {
  sidenav: SideNavEntry[];
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
};

export const Sidebar = ({ sidenav, isSidebarOpen, setIsSidebarOpen }: SidebarProps) => {
  const location = useLocation();

  const [activeTabId, setActiveTabId] = useState<number | undefined>();

  const toggleSidebar = () => {
    setIsSidebarOpen?.(!isSidebarOpen);
  };

  useEffect(() => {
    const currentRoute = location.pathname;

    const findActiveTab = (tabs: SideNavEntry[]): number | undefined => {
      for (const tab of tabs) {
        if (tab.url === currentRoute) return tab.id;
        if (tab.activeUrls?.includes(currentRoute)) return tab.id;
        if (tab.children) {
          const activeSubTabId = findActiveTab(tab.children);
          if (activeSubTabId) return activeSubTabId;
        }
      }
    };

    setActiveTabId(findActiveTab(sidenav));
  }, [location.pathname]);

  return (
    <>
      <div
        className={`fixed top-0 z-30 w-full h-screen transition-all ${
          isSidebarOpen
            ? 'bg-grey-1 bg-opacity-40 pointer-events-auto lg:bg-opacity-0 lg:pointer-events-none'
            : 'bg-opacity-0 pointer-events-none'
        }`}
      />
      <div
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 lg:!w-[262px] w-full h-screen transition-transform shadow-sidebar lg:rounded-r-[20px] ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
        }`}
        aria-label="Sidebar"
        onClick={toggleSidebar}
      >
        <div className="h-full w-[262px] lg:w-full px-4 pt-10 pb-6 overflow-y-hidden bg-white shadow-sidebar lg:rounded-r-[20px]">
          <div className="flex items-center justify-center pb-8">
            <IconLogo />
          </div>
          <div className="space-y-8 pb-12 overflow-y-auto h-full">
            {sidenav.map((item: SideNavEntry) => (
              <SidebarLink key={item.id} item={item} activeTabId={activeTabId} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
