import type { ProductsFilter } from '../constants';

export const productFiltersToQueryString = (
  filters: ProductsFilter,
  page: number,
  search: string,
  title: string,
  sortingOrder: string | undefined,
  pageItemsCount: number,
  isOnlyAvailable: boolean,
): string => {
  const params = new URLSearchParams();
  if (filters) {
    filters.forEach(({ id: key, value }) => {
      value.forEach((val) => {
        if (val) {
          params.append(key, val.toString());
        }
      });
    });
  }

  params.append('page', page.toString());
  params.append('limit', pageItemsCount.toString());

  if (search?.trim()) {
    params.append('search', search);
  }

  if (title) {
    params.append('sortBy', title);
  }

  if (sortingOrder) {
    params.append('order', sortingOrder);
  }

  if (isOnlyAvailable) {
    params.append('inStock', `${isOnlyAvailable}`);
  }

  return params.toString();
};
