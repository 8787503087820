import React, { useEffect } from 'react';
import './styles/style.css';
import './styles/App.css';
import Router from './routes';
import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  EventMessage,
  AccountInfo,
} from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { useIdToken } from './hooks';
import { useLocation } from 'react-router-dom';
import { addUrlToHistory } from './utils/navigationTracker';

// MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
export const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

const App: React.FC = () => {
  const { setIdToken } = useIdToken();
  const location = useLocation();

  useEffect(() => {
    // Add the current URL to the history on each navigation

    if (
      window.location.pathname !== '/payment/card-add' &&
      window.location.pathname !== '/payment/bank-add'
    ) {
      addUrlToHistory(location.pathname + location.search);
    }
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const path = window.location.pathname;

    if (!token && path !== '/' && path !== '/retailer-onboard' && path !== '/distributor-onboard') {
      window.location.href = '/';
    }
  }, []);

  msalInstance.addEventCallback((event: EventMessage) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload &&
      'account' in event.payload
    ) {
      const authResult = event.payload as AuthenticationResult;
      setIdToken(authResult.idToken);
      localStorage.setItem('ACCESS_TOKEN', JSON.stringify(authResult.idToken));
      msalInstance.setActiveAccount(event.payload.account as AccountInfo);
    }
  });

  return <Router instance={msalInstance} />;
};

export default App;
