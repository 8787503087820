import React, { createContext, useContext, useState } from 'react';
import { UserDataType } from '../redux/api/authApi/types';

type AuthContextType = {
  activeAccount?: UserDataType;
  setAuthData: (account: UserDataType) => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeAccount, setActiveAccount] = useState<UserDataType>();

  const setAuthData = (account: UserDataType) => {
    setActiveAccount(account);
  };

  return (
    <AuthContext.Provider value={{ activeAccount, setAuthData }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
