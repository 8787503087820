import { useEffect, useState } from 'react';
import { RETAILER } from '../constants';
import { LoaderComponent } from '../components';
import { useNavigate } from 'react-router-dom';
import { MutationExtraType } from '../types';
import { GetRecentlyJoinedUserRequestData, UserTypeBody } from '../redux/api/dashboardApi/types';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryApi,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import { ApiResponse } from '../redux/api/types';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

type CommonModalProps = {
  name: string;
  useMutation: UseMutation<
    MutationDefinition<
      UserTypeBody,
      (
        args: string | FetchArgs,
        api: BaseQueryApi,
        extraOptions: MutationExtraType,
      ) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
      never,
      ApiResponse<GetRecentlyJoinedUserRequestData[]>
    >
  >;
  mutationExtra?: MutationExtraType;
  userType: string;
};

const RecentlyJoined: React.FC<CommonModalProps> = ({
  name,
  useMutation,
  mutationExtra,
  userType,
}) => {
  const [datas, setDatas] = useState<{ data: GetRecentlyJoinedUserRequestData[] }>();

  const [getData, { isLoading }] = useMutation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getData({ userType });
      setDatas(response?.data);
    };
    fetchData();
  }, [mutationExtra]);

  return (
    <>
      {userType === RETAILER ? (
        <div className="xl:col-span-6 col-span-12">
          <div className="bg-white rounded-2xl p-4 text-secondary font-medium dropshadow-xl h-full fixHeight flex flex-col">
            <p className="font-medium mb-4">{name}</p>
            <div className="overflow-x-auto flex flex-col grow">
              {isLoading ? (
                <>
                  <LoaderComponent />
                </>
              ) : (
                <>
                  <table className="w-full">
                    <tbody>
                      {(datas?.data?.length ?? 0) > 0 ? (
                        datas?.data?.map(
                          (item: GetRecentlyJoinedUserRequestData, index: number) => (
                            <tr
                              key={index}
                              className="text-secondary whitespace-normal font-light text-sm"
                            >
                              <td
                                className="px-4 py-3.5 cursor-pointer"
                                onClick={() => {
                                  localStorage.setItem('userId', JSON.stringify(item));
                                  localStorage.setItem('nav', 'dashboard');
                                  navigate('/retailers/details');
                                }}
                              >
                                {item?.displayId}
                              </td>

                              <td
                                className="px-4 py-3.5 cursor-pointer"
                                onClick={() => {
                                  localStorage.setItem('userId', JSON.stringify(item));
                                  localStorage.setItem('nav', 'dashboard');
                                  navigate('/retailers/details');
                                }}
                              >
                                {item?.companyName}
                              </td>
                              {item?.status === 1 ? (
                                <td className="px-4 py-3.5 text-right">
                                  <span className="bg-green-100 px-3 py-[6px] text-success rounded-lg font-medium">
                                    Active
                                  </span>
                                </td>
                              ) : (
                                <td className="px-4 py-3.5 text-right">
                                  <span className="bg-green-100 px-3 py-[6px] text-success rounded-lg font-medium">
                                    InActive
                                  </span>
                                </td>
                              )}
                            </tr>
                          ),
                        )
                      ) : (
                        <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm h-[320px]">
                          <h3 className="text-lg font-semibold">No Data Found</h3>
                        </div>
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="xl:col-span-6 col-span-12">
          <div className="bg-white rounded-2xl p-4 text-secondary font-medium dropshadow-xl h-full fixHeight flex flex-col">
            <p className="font-medium mb-4">{name}</p>
            <div className="overflow-x-auto flex flex-col grow">
              {isLoading ? (
                <>
                  <LoaderComponent />
                </>
              ) : (
                <>
                  {' '}
                  <table className="w-full">
                    <tbody>
                      {(datas?.data?.length ?? 0) > 0 ? (
                        datas?.data?.map(
                          (item: GetRecentlyJoinedUserRequestData, index: number) => (
                            <tr
                              key={index}
                              className="text-secondary whitespace-normal font-light text-sm"
                            >
                              <td
                                className="px-4 py-3.5 cursor-pointer"
                                onClick={() => {
                                  localStorage.setItem('userId', JSON.stringify(item));
                                  localStorage.setItem('nav', 'dashboard');
                                  navigate('/distributors/details');
                                }}
                              >
                                {item?.displayId}
                              </td>
                              <td
                                className="px-4 py-3.5 cursor-pointer"
                                onClick={() => {
                                  localStorage.setItem('userId', JSON.stringify(item));
                                  localStorage.setItem('nav', 'dashboard');
                                  navigate('/distributors/details');
                                }}
                              >
                                {item?.companyName}
                              </td>
                              {item?.status === 1 ? (
                                <td className="px-4 py-3.5 text-right">
                                  <span className="bg-green-100 px-3 py-[6px] text-success rounded-lg font-medium">
                                    Active
                                  </span>
                                </td>
                              ) : (
                                <td className="px-4 py-3.5 text-right">
                                  <span className="bg-green-100 px-3 py-[6px] text-success rounded-lg font-medium">
                                    InActive
                                  </span>
                                </td>
                              )}
                            </tr>
                          ),
                        )
                      ) : (
                        <div className="flex flex-col w-full justify-center items-center py-3 text-secondary whitespace-nowrap font-light text-sm h-[320px]">
                          <h3 className="text-lg font-semibold">No Data Found</h3>
                        </div>
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecentlyJoined;
