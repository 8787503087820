import type { IconProps } from './types';

export const IconSuccess = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0304 9.1369C16.3232 9.42983 16.3232 9.9047 16.0303 10.1976L11.3633 14.8636C11.0704 15.1564 10.5955 15.1564 10.3027 14.8635L7.96967 12.5305C7.67678 12.2376 7.67678 11.7627 7.96967 11.4698C8.26256 11.177 8.73744 11.177 9.03033 11.4698L10.8331 13.2726L14.9697 9.13679C15.2627 8.84393 15.7375 8.84398 16.0304 9.1369Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 11C4.25 7.27208 7.27208 4.25 11 4.25H13C16.7279 4.25 19.75 7.27208 19.75 11V13C19.75 16.7279 16.7279 19.75 13 19.75H11C7.27208 19.75 4.25 16.7279 4.25 13V11ZM11 5.75C8.10051 5.75 5.75 8.10051 5.75 11V13C5.75 15.8995 8.10051 18.25 11 18.25H13C15.8995 18.25 18.25 15.8995 18.25 13V11C18.25 8.10051 15.8995 5.75 13 5.75H11Z"
        fill="currentColor"
      />
    </svg>
  );
};
