import { flexRender, Row } from '@tanstack/react-table';
import { getCommonPinningStyles, getPinningClassName } from '../utils/getCommonPinningStyles';
import type { CustomColumnDef, TableDefinition } from '../types';
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { BasicSkeleton } from './BasicSkeleton';

type Props<T extends object> = TableDefinition<T> & {
  isLoading?: boolean;
  hasData?: boolean;
  pageItemsCount?: number;
  rowClassName?: string | ((row: Row<T>) => string);
};

export const TableBody = <T extends object>({
  table,
  isLoading,
  hasData,
  pageItemsCount,
  rowClassName,
}: Props<T>) => (
  <tbody className="overflow-y-auto">
    {isLoading && !hasData ? (
      <BasicSkeleton table={table} pageItemsCount={pageItemsCount} />
    ) : (
      table.getRowModel()?.rows.map((row) => {
        const className = clsx(
          typeof rowClassName === 'function' ? rowClassName?.(row) : rowClassName,
        );

        return (
          <tr key={row.id} className={className}>
            {row.getVisibleCells().map((cell) => {
              const columnDef = cell.column.columnDef as CustomColumnDef<T>;
              const columnClassName = clsx(
                'px-3 py-2 text-sm font-normal outline-none border-b border-grey-6',
                getPinningClassName(cell.column, hasData),
                typeof columnDef.columnClassName === 'function'
                  ? columnDef.columnClassName(cell.getContext())
                  : columnDef.columnClassName,
              );

              return !columnDef.isHidden ? (
                <td
                  key={cell.id}
                  className={columnClassName}
                  style={{ ...getCommonPinningStyles(cell.column) }}
                >
                  <div
                    className={clsx('relative', {
                      'h-12': isLoading,
                    })}
                  >
                    <div
                      className={clsx({
                        'opacity-0': isLoading,
                      })}
                    >
                      {flexRender(columnDef.cell, cell.getContext())}
                    </div>
                    {isLoading && (
                      <Skeleton className="rounded-2.5 !absolute top-0 left-0 h-full bg-grey-3" />
                    )}
                  </div>
                </td>
              ) : null;
            })}
          </tr>
        );
      })
    )}
  </tbody>
);
