import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import {
  ConnectDistributorWithRetailerRequestBody,
  ConnectDistributorWithRetailerRequestData,
  GetDistributorsTabListAdminRequestBody,
  MapDistributorWithRetailerRequestBody,
} from './types';

export const retailerDetail_distributorTabApi = createApi({
  reducerPath: 'retailerDetail_distributorTabApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getDistributorsTabList: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getMappedData',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getDistributorsTabListById: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/getMappedDataByUserId',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getDistributorsTabListAdmin: builder.mutation<
      ApiResponse<null>,
      GetDistributorsTabListAdminRequestBody
    >({
      query: (body) => ({
        url: `api/getMappedUser?userId=${body.userId}&userType=${body.userType}`,
        method: 'GET',
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          // Handle any errors that might occur during the query
        }
      },
    }),
    connectDistributorWithRetailer: builder.mutation<
      ApiResponse<ConnectDistributorWithRetailerRequestData>,
      ConnectDistributorWithRetailerRequestBody
    >({
      query: (body) => ({
        url: 'api/getConnectUsersByUserId',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    mapDistributorWithRetailer: builder.mutation<
      ApiResponse<null>,
      MapDistributorWithRetailerRequestBody
    >({
      query: (body) => ({
        url: 'api/addMappingByUserId',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetDistributorsTabListMutation,
  useGetDistributorsTabListAdminMutation,
  useConnectDistributorWithRetailerMutation,
  useMapDistributorWithRetailerMutation,
  useGetDistributorsTabListByIdMutation,
} = retailerDetail_distributorTabApi;
