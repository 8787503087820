/**
 * Removes leading zeros from a number string.
 * Retains the zero if the number is a decimal (e.g., "0.5").
 *
 * @param value - The input number string
 * @returns The number string without leading zeros
 */
export const removeLeadingZeros = (value: string): string => {
  if (value.length > 1 && value.startsWith('0') && !value.startsWith('0.')) {
    return value.substring(1);
  }
  return value;
};
