import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { GenerateKeyRequestBody, GenerateKeyRequestData } from './types';

export const generateKeyApi = createApi({
  reducerPath: 'generateKeyApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    postGenerateKey: builder.mutation<ApiResponse<GenerateKeyRequestData>, GenerateKeyRequestBody>({
      query: (body) => ({
        url: 'api/generateKey',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          // Handle any errors that might occur during the query
          console.error(error);
        }
      },
    }),
    getGenerateKey: builder.query<ApiResponse<GenerateKeyRequestData>, string>({
      query: (params) => `api/getGeneratedKey?platform=${params}`,
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const { usePostGenerateKeyMutation, useGetGenerateKeyQuery } = generateKeyApi;
