import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { DownloadDocuSignDocBody } from './types';

export const agreementApi = createApi({
  reducerPath: 'agreementApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getDocuSign: builder.mutation<ApiResponse<null>, DownloadDocuSignDocBody>({
      query: (body) => ({
        url: 'api/downloadDocuSignDoc',
        method: 'POST',
        body,
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetDocuSignMutation } = agreementApi;
