import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { reftoken } from '../../utils/common';
import { loginRequest } from '../../authConfig';
import Dashboard from '../dashboard/Dashboard';
import { LoadingScreen } from '../../components';

const Login = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    } else {
      localStorage.removeItem('ACCESS_TOKEN');
      localStorage.removeItem('REFRESH_TOKEN');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest).catch(() => {});
    } else {
      setIsLoading(false);
    }
  }, []);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        localStorage.setItem('ACCESS_TOKEN', response.idToken);
        reftoken();
      })
      .finally(() => {
        setIsLoading(false); // Authentication is complete
      });
  }

  return (
    <div className="flex flex-col gap-3">
      {isLoading && <LoadingScreen />}
      {!isLoading && (
        <div className="flex gap-8">
          {isAuthenticated ? (
            <Dashboard isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
          ) : (
            <LoadingScreen />
          )}
        </div>
      )}
    </div>
  );
};

export default Login;
