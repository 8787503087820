import React, { createContext, useContext, useState, useEffect } from 'react';

type IdTokenContextType = {
  idToken: string | null;
  setIdToken: React.Dispatch<React.SetStateAction<string | null>>;
};

const IdTokenContext = createContext<IdTokenContextType | undefined>(undefined);

export const IdTokenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [idToken, setIdToken] = useState<string | null>(null);

  useEffect(() => {
    try {
      const token = localStorage.getItem('ACCESS_TOKEN');
      if (token) {
        setIdToken(token);
      }
    } catch (error) {
      console.error('Error retrieving or parsing token from localStorage:', error);
    }
  }, []);

  return (
    <IdTokenContext.Provider value={{ idToken, setIdToken }}>{children}</IdTokenContext.Provider>
  );
};

export const useIdToken = (): IdTokenContextType => {
  const context = useContext(IdTokenContext);
  if (!context) {
    throw new Error('useIdToken must be used within an IdTokenProvider');
  }
  return context;
};
