import React, { ChangeEvent } from 'react';
import { useSlider } from '@react-aria/slider';
import { SliderState, useSliderState } from '@react-stately/slider';
import { SliderThumb } from './components';
import { useDebounce } from 'react-use';

type SliderProps = {
  minValue: number;
  maxValue: number;
  step: number;
  value: number[];
  onChange: (value: number[]) => void;
  onChangeEnd: (value: number[]) => void;
  prefix: React.ReactNode;
};

export const Slider: React.FC<SliderProps> = ({
  minValue,
  maxValue,
  step,
  value,
  onChange,
  onChangeEnd,
  prefix,
}) => {
  const trackRef = React.useRef(null);

  const state: SliderState = useSliderState({
    minValue,
    maxValue,
    step,
    value,
    onChange,
    onChangeEnd,
    numberFormatter: new Intl.NumberFormat('en-US'),
  });

  useDebounce(
    () => {
      const range = [...value];

      if (range[0] >= maxValue) {
        range[0] = maxValue - 1;
      }
      if (range[1] > maxValue) {
        range[1] = maxValue;
      }
      if (range[1] <= range[0]) {
        range[1] = range[0] + 1 > maxValue ? maxValue : range[0] + 1;
      }

      onChangeEnd(range);
      onChange(range);
    },
    500,
    [value],
  );

  const { groupProps, trackProps } = useSlider({ label: 'Slider' }, state, trackRef);

  const onRangeChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const newRange = [...value];
    newRange[index] = Number(e.target.value);

    onChange(newRange);
  };

  return (
    <div {...groupProps} className="w-full p-4">
      <div className="flex justify-between mb-4">
        {state.values.map((_, index) => (
          <div key={index} className="flex gap-1">
            <span className="text-xs text-grey-3 font-normal leading-4 content-center">
              {prefix}
            </span>
            <input
              type="number"
              value={value[index]}
              onChange={(e) => onRangeChange(e, index)}
              className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-xs font-normal text-grey-1 leading-4 px-3 py-1 border border-grey-4 rounded-2.5 w-[60px]"
            />
          </div>
        ))}
      </div>
      <div {...trackProps} ref={trackRef} className="relative h-1 bg-grey-6">
        <div
          className="absolute bg-primary-brand-dark h-1"
          style={{
            left: `${state.getThumbPercent(0) * 100}%`,
            right: `${100 - state.getThumbPercent(state.values.length - 1) * 100}%`,
          }}
        />
        {state.values.map((value, index) => (
          <SliderThumb key={index} index={index} state={state} trackRef={trackRef} />
        ))}
      </div>
    </div>
  );
};
