import { ADMIN } from '../../../constants';
import React from 'react';

type CustomTooltipProps = {
  active?: boolean;
  payload?: { payload: { region: string; displaySales: string; duration: string } }[];
  userType?: string;
};

export const CustomTooltip = ({ active, payload, userType }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const dataItem = payload[0].payload;
    return userType === ADMIN ? (
      <>
        {' '}
        <div className="bg-transparent p-[5px] text-[12px]">
          {`${dataItem.region}`} <br />
          {`${dataItem.displaySales}`}
        </div>
      </>
    ) : (
      <>
        {' '}
        <div className="bg-transparent p-[5px] text-[12px]">
          {`${dataItem.displaySales}`} <br />
          {`${dataItem.duration}`}
        </div>
      </>
    );
  }
  return null;
};
