import type { SideNavEntry } from '../../../../types';
import {
  IconDashboard,
  IconInvoice,
  IconOrder,
  IconProduct,
  IconReport,
  IconTruck,
} from '../../../../assets/icons';

export const retailerSidenavConfig: SideNavEntry[] = [
  {
    id: 1,
    label: 'Dashboard',
    icon: <IconDashboard />,
    url: '/',
  },
  {
    id: 9,
    label: 'Buy Products',
    icon: <IconProduct />,
    url: '/retailers/buy-products',
  },
  {
    id: 5,
    label: 'Orders',
    icon: <IconOrder />,
    url: '/orders/customer',
    activeUrls: ['/orders/retailer'],
  },
  {
    id: 2,
    label: 'Distributors',
    icon: <IconTruck />,
    url: '/distributors/list',
  },
  {
    id: 3,
    label: 'Invoices',
    icon: <IconInvoice />,
    url: '/invoices',
  },
  {
    id: 4,
    label: 'Reports',
    icon: <IconReport />,
    url: '/',
    children: [
      {
        id: 6,
        label: 'Order Summary',
        url: '/reports/order-summary',
      },
      {
        id: 7,
        label: 'Distributor Reports',
        url: '/reports/distributor-report',
      },
      {
        id: 8,
        label: 'Top Purchased Products',
        url: '/reports/purchased-products',
      },
    ],
  },
];
