import { createApi } from '@reduxjs/toolkit/query/react';
import { setUser } from '../../features/userSlice';
import customFetchBase from '../customFetchBase';
import {
  ChangePasswordBody,
  DocSignRequestBody,
  DocSignRequestData,
  DocSignTokenRequestData,
  MasterDataRequestData,
  SetPasswordBody,
  UserDataType,
} from './types';
import { ApiResponse } from '../types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    loginUser: builder.mutation<ApiResponse<UserDataType>, void>({
      query: () => ({
        url: 'api/auth',
        method: 'GET',
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data.data));
        } catch (error) {}
      },
    }),
    setPasswordUser: builder.mutation<ApiResponse<null>, SetPasswordBody>({
      query: (body) => ({
        url: 'api/setPassword',
        method: 'POST',
        body,
      }),
    }),
    changePasswordUser: builder.mutation<ApiResponse<null>, ChangePasswordBody>({
      query: (body) => ({
        url: 'api/changePassword',
        method: 'POST',
        body,
      }),
    }),
    masterData: builder.mutation<ApiResponse<MasterDataRequestData[]>, string>({
      query: (data) => ({
        url: `api/getMasterData?type=${data}`,
        method: 'GET',
      }),
    }),
    docSignStatus: builder.mutation<ApiResponse<DocSignRequestData>, void>({
      query: () => ({
        url: 'api/getDocusignStatus',
        method: 'GET',
      }),
    }),
    updateDocSignStatus: builder.mutation<ApiResponse<null>, null>({
      query: (body) => ({
        url: 'api/updateDocusign',
        method: 'PUT',
        body,
      }),
    }),

    docSignToken: builder.mutation<ApiResponse<DocSignTokenRequestData>, DocSignRequestBody>({
      query: () => ({
        url: 'api/getDocuSignToken',
        method: 'GET',
      }),
    }),
    getdocSignUrl: builder.mutation<ApiResponse<string>, DocSignRequestBody>({
      query: (body) => ({
        url: 'api/getDocuSignUrl',
        method: 'POST',
        body,
      }),
    }),
    getdocSignStatus: builder.mutation<ApiResponse<string>, DocSignRequestBody>({
      query: (body) => ({
        url: 'api/fetchDocuSignStatus',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useMasterDataMutation,
  useSetPasswordUserMutation,
  useChangePasswordUserMutation,
  useDocSignStatusMutation,
  useUpdateDocSignStatusMutation,
  useDocSignTokenMutation,
  useGetdocSignUrlMutation,
  useGetdocSignStatusMutation,
} = authApi;
