import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { ApiResponse } from '../types';
import { RevenueReportRequestBody, RevenueReportRequestData } from './types';

export const revenueReportApi = createApi({
  reducerPath: 'revenueReportApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getRevenueReport: builder.mutation<
      ApiResponse<RevenueReportRequestData>,
      RevenueReportRequestBody
    >({
      query: (body) => ({
        url: 'api/getRevenueReport',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetRevenueReportMutation } = revenueReportApi;
