import React, { useEffect } from 'react';
import { Icon } from '@iconify/react';
import Header from '../../layout/dashboard/Header';
import logo from '../../assets/images/logo.webp';

import { RecentlyJoined, TopPerforming, Graph } from '../../components';
import {
  useGetMetricsMutation,
  useGetRecentlyJoinedUserMutation,
  useGetTopPerformingUserMutation,
} from '../../redux/api/dashboardApi/dashboardApi';
import { DISTRIBUTOR, RETAILER } from '../../constants';
import moment from 'moment';
import { SidebarProps } from '../../types';

const Admin: React.FC<SidebarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const toggleSidebar = () => {
    setIsSidebarOpen?.(!isSidebarOpen);
  };

  const [getMetrics, { data, isLoading }] = useGetMetricsMutation();
  const date = new Date();
  const offset = moment.parseZone(date).utcOffset();
  useEffect(() => {
    getMetrics(offset);
  }, [getMetrics]);

  return (
    <>
      <div className="Banner">
        <div
          className={`inline-flex items-center justify-between w-full p-3  text-sm text-gray-500 rounded-lg lg:hidden ${
            isSidebarOpen ? 'hidden' : ''
          }`}
          onClick={toggleSidebar}
        >
          <img src={logo} alt="logo" className="w-48" />
          <Icon icon="ri:menu-3-line" color="#B20036" width="24" height="24" />
        </div>
        <div className="lg:px-8 px-4 py-6 lg:ml-[16rem] h-screen flex flex-col">
          <Header title="Dashboard" />
          <div className="relative grow overflow-y-auto pb-10">
            <div className="grid grid-cols-12 gap-4 mt-2 h-fit mb-4">
              <div className="xl:col-span-7 col-span-12">
                <div className="grid lg:grid-cols-2 gap-4 h-full">
                  <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
                    <div className="flex justify-center items-center w-22 h-22 bg-amber-100 rounded-full">
                      <Icon
                        icon="bi:calendar2-week-fill"
                        className="text-warning"
                        width="24"
                        height="24"
                      />
                    </div>
                    <div>
                      <p className="font-medium mb-1.5">Total</p>
                      <p className="font-semibold flex mb-1.5 lg:text-lg items-center">
                        {isLoading ? (
                          <div className="animate-pulse flex space-x-2 items-center">
                            <div className="h-2 bg-slate-100 rounded w-16"></div>
                          </div>
                        ) : (
                          <>{data?.data?.total?.totalPrice}</>
                        )}
                      </p>

                      {isLoading ? (
                        <div className="animate-pulse flex space-x-2 items-center">
                          <div className="h-2 bg-slate-100 rounded w-16"></div>
                        </div>
                      ) : (
                        <span>
                          {' '}
                          {data?.data?.total?.quantity}
                          {data?.data?.total?.quantity && data?.data?.total?.quantity > 1
                            ? 'Bottles'
                            : 'Bottle'}{' '}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
                    <div className="flex justify-center items-center w-22 h-22 bg-green-400 rounded-full">
                      <Icon
                        icon="fluent:calendar-today-24-filled"
                        className="text-success"
                        width="32"
                        height="32"
                      />
                    </div>
                    <div>
                      <p className="font-medium mb-1.5">Today</p>
                      <p className="font-semibold flex mb-1.5 lg:text-lg items-center">
                        {isLoading ? (
                          <div className="animate-pulse flex space-x-2 items-center">
                            <div className="h-2 bg-slate-100 rounded w-16"></div>
                            <div className="h-2 bg-slate-100 rounded w-8"></div>
                          </div>
                        ) : (
                          <>
                            {data?.data?.today?.totalPrice}
                            {data?.data?.today?.isGain === 0 &&
                            data?.data?.today?.percentage === '0.00' ? (
                              <Icon
                                icon="ion:arrow-up"
                                className="text-secondary ml-1.5 text-gray-500"
                                width="22"
                                height="22"
                              />
                            ) : data?.data?.today?.isGain === 0 ? (
                              <Icon
                                icon="ion:arrow-down"
                                className="text-danger ml-1.5"
                                width="22"
                                height="22"
                              />
                            ) : (
                              <Icon
                                icon="ion:arrow-up"
                                className="text-green-900 ml-1.5"
                                width="22"
                                height="22"
                              />
                            )}
                            <span
                              className={
                                data?.data?.today?.isGain === 0 &&
                                data?.data?.today?.percentage === '0.00'
                                  ? 'text-gray font-normal text-sm'
                                  : data?.data?.today?.isGain === 0
                                    ? 'text-danger font-normal text-sm'
                                    : 'text-green-900 font-normal text-sm'
                              }
                            >
                              {data?.data?.today?.percentage}%
                            </span>
                          </>
                        )}
                      </p>
                      {isLoading ? (
                        <div className="animate-pulse flex space-x-2 items-center">
                          <div className="h-2 bg-slate-100 rounded w-16"></div>
                        </div>
                      ) : (
                        <span>{data?.data?.today?.quantity} Bottles</span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
                    <div className="flex justify-center items-center w-22 h-22 bg-indigo-200 rounded-full">
                      <Icon
                        icon="bi:calendar2-week-fill"
                        className="text-indigo-800"
                        width="24"
                        height="24"
                      />
                    </div>
                    <div>
                      <p className="font-medium mb-1.5">This Week</p>
                      <p className="font-semibold flex mb-1.5 lg:text-lg items-center">
                        {isLoading ? (
                          <div className="animate-pulse flex space-x-2 items-center">
                            <div className="h-2 bg-slate-100 rounded w-16"></div>
                          </div>
                        ) : (
                          <>{data?.data?.thisWeek?.totalPrice}</>
                        )}

                        {isLoading ? (
                          <div className="animate-pulse flex space-x-2 items-center">
                            <div className="h-2 bg-slate-100 rounded w-16"></div>
                          </div>
                        ) : (
                          <>
                            {data?.data?.thisWeek?.isGain === 0 &&
                            data?.data?.thisWeek?.percentage === '0.00' ? (
                              <Icon
                                icon="ion:arrow-up"
                                className="text-secondary ml-1.5 text-gray-500"
                                width="22"
                                height="22"
                              />
                            ) : data?.data?.thisWeek?.isGain === 0 ? (
                              <Icon
                                icon="ion:arrow-down"
                                className="text-danger ml-1.5"
                                width="22"
                                height="22"
                              />
                            ) : (
                              <Icon
                                icon="ion:arrow-up"
                                className="text-green-900 ml-1.5"
                                width="22"
                                height="22"
                              />
                            )}

                            <span
                              className={
                                data?.data?.thisWeek?.isGain === 0 &&
                                data?.data?.thisWeek?.percentage === '0.00'
                                  ? 'text-gray font-normal text-sm'
                                  : data?.data?.thisWeek?.isGain === 0
                                    ? 'text-danger font-normal text-sm'
                                    : 'text-green-900 font-normal text-sm'
                              }
                            >
                              {data?.data?.thisWeek?.percentage}%
                            </span>
                          </>
                        )}
                      </p>

                      {isLoading ? (
                        <div className="animate-pulse flex space-x-2 items-center">
                          <div className="h-2 bg-slate-100 rounded w-16"></div>
                        </div>
                      ) : (
                        <span>{data?.data?.thisWeek?.quantity} Bottles</span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center gap-4 bg-white rounded-2xl p-4 text-secondary dropshadow-xl">
                    <div className="flex justify-center items-center w-22 h-22 bg-red-200 rounded-full">
                      <Icon
                        icon="material-symbols:calendar-month-rounded"
                        className="text-danger"
                        width="32"
                        height="32"
                      />
                    </div>
                    <div>
                      <p className="font-medium mb-1.5">This Month</p>
                      <p className="font-semibold flex mb-1.5 lg:text-lg items-center">
                        {isLoading ? (
                          <div className="animate-pulse flex space-x-2 items-center">
                            <div className="h-2 bg-slate-100 rounded w-16"></div>
                          </div>
                        ) : (
                          <>{data?.data?.thisMonth?.totalPrice}</>
                        )}

                        {isLoading ? (
                          <div className="animate-pulse flex space-x-2 items-center">
                            <div className="h-2 bg-slate-100 rounded w-16"></div>
                          </div>
                        ) : (
                          <>
                            {' '}
                            {data?.data?.thisMonth?.isGain === 0 &&
                            data?.data?.thisMonth?.percentage === '0.00' ? (
                              <Icon
                                icon="ion:arrow-up"
                                className="text-secondary ml-1.5 text-gray-500"
                                width="22"
                                height="22"
                              />
                            ) : data?.data?.thisMonth?.isGain === 0 ? (
                              <Icon
                                icon="ion:arrow-down"
                                className="text-danger ml-1.5"
                                width="22"
                                height="22"
                              />
                            ) : (
                              <Icon
                                icon="ion:arrow-up"
                                className="text-green-900 ml-1.5"
                                width="22"
                                height="22"
                              />
                            )}
                            <span
                              className={
                                data?.data?.thisMonth?.isGain === 0 &&
                                data?.data?.thisMonth?.percentage === '0.00'
                                  ? 'text-gray font-normal text-sm'
                                  : data?.data?.thisMonth?.isGain === 0
                                    ? 'text-danger font-normal text-sm'
                                    : 'text-green-900 font-normal text-sm'
                              }
                            >
                              {data?.data?.thisMonth?.percentage}%
                            </span>
                          </>
                        )}
                      </p>
                      {isLoading ? (
                        <div className="animate-pulse flex space-x-2 items-center">
                          <div className="h-2 bg-slate-100 rounded w-16"></div>
                        </div>
                      ) : (
                        <span> {data?.data?.thisMonth?.quantity} Bottles</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-5 col-span-12">
                <div className="bg-white rounded-2xl p-4 text-secondary font-medium dropshadow-xl">
                  <p className="font-medium mb-6">Sales by Region</p>
                  <div className="h-54">
                    <Graph />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4 h-fit my-4">
              <TopPerforming
                name={'Top Performing Distributors'}
                useMutation={useGetTopPerformingUserMutation}
                userType={DISTRIBUTOR}
              />
              <TopPerforming
                name={'Top Performing Retailers'}
                useMutation={useGetTopPerformingUserMutation}
                userType={RETAILER}
              />
            </div>
            <div className="grid grid-cols-12 gap-4 h-fit my-4">
              <RecentlyJoined
                name={'Recently Joined Distributors'}
                useMutation={useGetRecentlyJoinedUserMutation}
                userType={DISTRIBUTOR}
              />
              <RecentlyJoined
                name={'Recently Joined Retailers'}
                useMutation={useGetRecentlyJoinedUserMutation}
                userType={RETAILER}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
