import type { SideNavEntry } from '../../../../types';
import {
  IconDashboard,
  IconExchange,
  IconInvoice,
  IconOrder,
  IconReport,
  IconTruck,
} from '../../../../assets/icons';

export const distributorSidenavConfig: SideNavEntry[] = [
  {
    id: 1,
    label: 'Dashboard',
    icon: <IconDashboard />,
    url: '/',
  },
  {
    id: 6,
    label: 'Exchange',
    icon: <IconExchange />,
    url: '/exchange',
  },
  {
    id: 2,
    label: 'Retailers',
    icon: <IconTruck />,
    url: '/retailers/mapped-retailer',
  },
  {
    id: 5,
    label: 'Orders',
    icon: <IconOrder />,
    url: '/orders/customer',
  },
  {
    id: 3,
    label: 'Invoices',
    icon: <IconInvoice />,
    url: '/invoices',
  },
  {
    id: 4,
    label: 'Reports',
    icon: <IconReport />,
    url: '/',
    children: [
      {
        id: 6,
        label: 'Order Summary',
        url: '/reports/order-summary',
      },
      {
        id: 7,
        label: 'Top Retailer Report',
        url: '/reports/retailer-report',
      },
      {
        id: 8,
        label: 'Top Purchased Products',
        url: '/reports/purchased-products',
      },
    ],
  },
];
