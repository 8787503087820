import type { CustomColumnDef, TableDefinition } from '../types';
import Skeleton from 'react-loading-skeleton';
import { getCommonPinningStyles, getPinningClassName } from '../utils/getCommonPinningStyles';
import clsx from 'clsx';

type Props<T extends object> = TableDefinition<T> & {
  pageItemsCount?: number;
};
export const BasicSkeleton = <T extends object>({ pageItemsCount, table }: Props<T>) => (
  <>
    {Array.from({ length: pageItemsCount ?? 1 }).map((_, rowIndex) => (
      <tr key={`skeleton-row-${rowIndex}`}>
        {table.getAllColumns().map(
          (column) =>
            !(column.columnDef as CustomColumnDef<T>).isHidden && (
              <td
                key={`skeleton-cell-${rowIndex}-${column.id}`}
                className={clsx(
                  'px-3 py-2 outline-none border-b border-grey-6',
                  getPinningClassName(column, false),
                )}
                style={{ ...getCommonPinningStyles(column) }}
              >
                <div className="relative h-12">
                  <Skeleton className="rounded-2.5 h-full" />
                </div>
              </td>
            ),
        )}
      </tr>
    ))}
  </>
);
