import type { SideNavEntry } from '../../../../types';
import { IconBooth, IconDashboard, IconReport, IconTruck } from '../../../../assets/icons';

export const adminSidenavConfig: SideNavEntry[] = [
  {
    id: 1,
    label: 'Dashboard',
    icon: <IconDashboard />,
    url: '/',
  },
  {
    id: 2,
    label: 'Distributors',
    icon: <IconTruck />,
    url: '/distributors/list',
  },
  {
    id: 3,
    label: 'Retailers',
    icon: <IconBooth />,
    url: '/retailers/list',
  },
  {
    id: 4,
    label: 'Reports',
    icon: <IconReport />,
    url: '/',
    children: [
      {
        id: 6,
        label: 'Monthly Report',
        url: '/reports/monthly-report',
      },
      {
        id: 7,
        label: 'Platform Report',
        url: '/reports/platform-report',
      },
      {
        id: 8,
        label: 'Revenue Report',
        url: '/reports/revenue-report',
      },
    ],
  },
];
