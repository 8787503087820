import type { FilterType } from '../types';
import clsx from 'clsx';
import { IconChevronDown, IconClose } from '../../../assets/icons';
import { Button } from '../../Button';

type FilterButtonContentProps = {
  id: string;
  label: string;
  type: FilterType;
  selectedValues: string[] | number[];
  handleClear?: (id: string) => void;
  isOpen: boolean;
};

export const FilterButtonContent = ({
  id,
  label,
  type,
  selectedValues,
  handleClear,
  isOpen,
}: FilterButtonContentProps) => (
  <>
    <span className="text-body-md font-semibold leading-5 text-gray-700">{label}</span>

    {selectedValues && selectedValues.length > 1 && type !== 'range' && (
      <span
        className={clsx(
          'text-body-sm font-normal leading-4 rounded-lg h-5 w-5 p-0.5 flex items-center justify-center transition-colors',
          {
            'bg-grey-5': isOpen,
            'bg-grey-6': !isOpen,
          },
        )}
      >
        {selectedValues.length}
      </span>
    )}

    {selectedValues && selectedValues.length === 1 && (
      <span className="text-body-md text-grey-3 items-center">{selectedValues[0]}</span>
    )}

    {type === 'range' &&
      selectedValues &&
      selectedValues[0] !== undefined &&
      selectedValues[1] !== undefined && (
        <span className="text-body-md text-grey-3 items-center min-w-[60px]">{`${selectedValues[0]}-${selectedValues[1]}`}</span>
      )}

    {(selectedValues && selectedValues.length > 0) ||
    (handleClear &&
      selectedValues &&
      selectedValues[0] !== undefined &&
      selectedValues[1] !== undefined) ? (
      <Button
        stopPropogation
        variant="grey"
        size="small"
        icon={<IconClose className="w-4 h-4" />}
        onClick={() => {
          handleClear?.(id);
        }}
      />
    ) : null}
    <IconChevronDown
      className={clsx('w-4 h-4 transition-transform text-grey-1', {
        'transform rotate-180': isOpen,
        'transform rotate-0': !isOpen,
      })}
    />
  </>
);
