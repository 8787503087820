import type { CloseButtonProps } from 'react-toastify/dist/components';
import { IconClose } from '../../assets/icons';

export const ToastifyCloseButton = ({ closeToast }: CloseButtonProps) => (
  <button
    className="text-grey-1 hover:text-primary-brand-dark absolute top-3 right-3"
    onClick={closeToast}
  >
    <IconClose className="w-4 h-4" />
  </button>
);
