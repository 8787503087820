import React from 'react';
import Logo from '../assets/images/logo-horizontal.webp';
import { useNavigate } from 'react-router-dom';
import warning from '../assets/images/warning.webp';

const SessionExpired: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="Banner">
      <div className="lg:px-40 px-4 py-10 w-full h-full flex flex-col justify-center overflow-y-auto">
        <div className="self-center">
          <img src={Logo} alt="logo" className="mx-auto" />
        </div>
        <div className="w-2/5 h-fit bg-gray-700 bg-opacity-75 rounded-2xl my-8 self-center p-6 flex flex-col items-center">
          <img src={warning} alt="Warning Icon" className="max-w-[100px] max-h-[100px]" />
          <div className="text-center text-white">
            <h1 className="font-semibold text-xl mb-2">Session Expired</h1>
            <p className="mb-3">
              The invitation link has expired. Please request a new link from an administrator or
              login.
            </p>
          </div>
          <div className="mt-6">
            <button
              className="bg-white text-primary font-medium w-full px-4 py-2 rounded-xl"
              onClick={() => navigate('/distributer')}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SessionExpired;
