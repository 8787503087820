import type { IconProps } from '../types';

export const IconReport = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 7.24598V4H15.75V7.24598C15.75 7.38405 15.8619 7.49598 16 7.49598H18.5V8.99598H16C15.0335 8.99598 14.25 8.21248 14.25 7.24598Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 15C7.25 14.5858 7.58579 14.25 8 14.25H15C15.4142 14.25 15.75 14.5858 15.75 15C15.75 15.4142 15.4142 15.75 15 15.75H8C7.58579 15.75 7.25 15.4142 7.25 15Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H11C11.4142 8.25 11.75 8.58579 11.75 9C11.75 9.41421 11.4142 9.75 11 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9ZM7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H14C14.4142 11.25 14.75 11.5858 14.75 12C14.75 12.4142 14.4142 12.75 14 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4.5H9C7.067 4.5 5.5 6.067 5.5 8V15C5.5 16.933 7.067 18.5 9 18.5H15C16.933 18.5 18.5 16.933 18.5 15V8C18.5 6.067 16.933 4.5 15 4.5ZM9 3C6.23858 3 4 5.23858 4 8V15C4 17.7614 6.23858 20 9 20H15C17.7614 20 20 17.7614 20 15V8C20 5.23858 17.7614 3 15 3H9Z"
        fill="currentColor"
      />
    </svg>
  );
};
