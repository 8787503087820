import { IconProps } from './types';

export const IconEdit = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9019 5.04654C16.9334 5.00526 16.9874 4.99016 17.0346 5.00668L18.4971 6.17121C18.4744 6.19236 18.453 6.2149 18.4332 6.2387L17.4067 7.46698C17.0355 7.48293 16.7037 7.32983 16.397 7.06302C16.1558 6.85313 15.9566 6.59231 15.8107 6.35106L16.8807 5.07313C16.888 5.06443 16.8951 5.05556 16.9019 5.04654ZM14.7925 7.52134L11.5987 11.1323V12.7975L13.252 12.4228L16.3204 8.76321C15.974 8.62124 15.6695 8.41829 15.4124 8.19463C15.174 7.98717 14.9672 7.75522 14.7925 7.52134ZM18.3419 8.68328L14.3444 13.4545L14.3346 13.4659C14.1611 13.6647 13.9297 13.8043 13.673 13.8653L13.6655 13.8671L11.5655 14.3431C11.5233 14.3526 11.4803 14.3585 11.4371 14.3607C10.771 14.394 10.1911 13.9099 10.1049 13.2485C10.1008 13.2164 10.0987 13.184 10.0987 13.1516V10.9936C10.0987 10.9765 10.0992 10.9593 10.1004 10.9422C10.119 10.6721 10.2272 10.4159 10.4079 10.2144L14.3731 5.73135L15.7214 4.12122C16.2269 3.47469 17.1381 3.30852 17.84 3.73782C17.8663 3.75394 17.8917 3.77168 17.9159 3.79093L19.4819 5.03793C19.498 5.05079 19.5136 5.06432 19.5287 5.07847C19.8315 5.36352 20.0023 5.76165 20 6.17755C19.9978 6.57428 19.8384 6.95311 19.5581 7.23171L18.3971 8.6175C18.38 8.64042 18.3616 8.66239 18.3419 8.68328Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0783 16C19.0783 18.4853 17.0636 20.5 14.5783 20.5H8.5C6.01472 20.5 4 18.4853 4 16V9.78106C4 7.2837 6.0336 5.26402 8.53089 5.28116L11.5987 5.30004C12.0129 5.30004 12.3487 5.63583 12.3487 6.05004C12.3487 6.46425 12.0129 6.80004 11.5987 6.80004H8.48119C6.82946 6.80447 5.49369 8.14659 5.49723 9.79844L5.49723 9.80004L5.49723 15.976L5.49723 15.9776C5.49369 17.6295 6.8295 18.9716 8.48128 18.976H14.5942C16.246 18.9716 17.5818 17.6295 17.5783 15.9776L17.5783 15.976V11.675C17.5783 11.2608 17.9141 10.925 18.3283 10.925C18.7425 10.925 19.0783 11.2608 19.0783 11.675V16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79721 17.001C6.79721 16.5868 7.13299 16.251 7.54721 16.251H15.5293C15.9435 16.251 16.2793 16.5868 16.2793 17.001C16.2793 17.4152 15.9435 17.751 15.5293 17.751H7.54721C7.13299 17.751 6.79721 17.4152 6.79721 17.001Z"
        fill="currentColor"
      />
    </svg>
  );
};
