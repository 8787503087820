import type { CartStorage, StoredProduct } from './context';
import { PriceBottlesTotal } from '../../types';

export const convertMapToObject = (map: Map<string, Map<string, StoredProduct>>) => {
  const obj: CartStorage = {};
  map.forEach((productsMap, location) => {
    obj[location] = { data: Array.from(productsMap.values()) };
  });
  return obj;
};

export const convertObjectToMap = (storage?: CartStorage) => {
  return new Map<string, Map<string, StoredProduct>>(
    Object.entries(storage ?? {}).map(([location, productsObj]) => [
      location,
      new Map(productsObj.data.map((item) => [item.product.productId, item])),
    ]),
  );
};

export const getPriceBottlesTotal = (products: StoredProduct[] | undefined): PriceBottlesTotal => {
  if (!products) return { price: 0, bottlesCount: 0, productKindCount: 0 };

  return products.reduce(
    (acc: PriceBottlesTotal, item) => {
      acc.price += item.selectedCount * item.product.unitPrice;
      acc.bottlesCount += item.selectedCount;
      acc.productKindCount += 1;
      return acc;
    },
    { price: 0, bottlesCount: 0, productKindCount: 0 },
  );
};
