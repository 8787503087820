import { Checkbox as AriaCheckbox } from 'react-aria-components';
import clsx from 'clsx';
import { IconCheckboxSelected, IconCheckboxIndeterminate } from '../assets/icons';

type CheckboxProps = {
  id?: string;
  label?: string;
  isSelected?: boolean;
  isIndeterminate?: boolean;
  isDisabled?: boolean;
  onChange?: (isSelected: boolean) => void;
  className?: string;
};

const createCheckboxStyles = (props: CheckboxProps) => {
  const { isSelected, isIndeterminate, isDisabled } = props;

  const baseClasses = 'w-5 h-5 rounded border transition duration-100';
  const stateClasses = clsx({
    'bg-white border-grey-1 focus:bg-grey-6 focus:border-grey-1 active:bg-grey-6':
      !isSelected && !isIndeterminate && !isDisabled,
    'bg-primary-brand border-primary-brand hover:bg-primary-brand active:primary-brand':
      (isSelected || isIndeterminate) && !isDisabled,
    'bg-white border-grey-4': !(isSelected || isIndeterminate) && isDisabled,
    'bg-primary-brand-light border-primary-brand-light':
      (isSelected || isIndeterminate) && isDisabled,
  });

  return clsx(baseClasses, stateClasses);
};

export const Checkbox = (props: CheckboxProps) => {
  const { label, isSelected, isIndeterminate, isDisabled, onChange, className } = props;

  return (
    <label className="flex gap-2 items-center content-center">
      <AriaCheckbox
        isSelected={isSelected}
        isIndeterminate={isIndeterminate}
        isDisabled={isDisabled}
        onChange={onChange}
        className={clsx(createCheckboxStyles(props), className)}
      >
        {({ isSelected, isIndeterminate }) => (
          <div className="flex items-center justify-center content-center w-full h-full text-white">
            {isSelected && <IconCheckboxSelected />}
            {isIndeterminate && <IconCheckboxIndeterminate />}
          </div>
        )}
      </AriaCheckbox>
      {label && (
        <span
          className={clsx('text-body-md', {
            'text-grey-1': !isDisabled,
            'text-grey-4': isDisabled,
          })}
        >
          {label}
        </span>
      )}
    </label>
  );
};
