import { IconProps } from './types';

export const IconCheckboxSelected = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3976 1.36281C10.5653 1.53674 10.5603 1.81371 10.3864 1.98143L4.94212 7.23143C4.77265 7.39486 4.50424 7.39486 4.33476 7.23145L1.61234 4.60645C1.4384 4.43873 1.43336 4.16177 1.60107 3.98783C1.76879 3.81389 2.04575 3.80885 2.21969 3.97656L4.63842 6.30874L9.77899 1.35158C9.95292 1.18385 10.2299 1.18889 10.3976 1.36281Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
