import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const LoadingScreen: React.FC = () => {
  return (
    <>
      <div className="flex justify-center items-center fixed top-0 left-0 h-full w-full z-[9999]">
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#b20036"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
      <div className="flex justify-center items-center fixed top-0 left-0 h-full w-full z-[9999] mt-[60px]"></div>
    </>
  );
};

export default LoadingScreen;
